import { consultantConstants } from '@actions/types';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function consultantAuth(state = initialState, action) {
  switch (action.type) {
    case consultantConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case consultantConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        consultant: action.consultant,
      };
    case consultantConstants.LOGIN_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultantConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        signUp: true,
      };
    case consultantConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        consultant: action.consultant,
      };
    case consultantConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfile: true,
      };
    case consultantConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        consultant: action.consultant,
      };
    case consultantConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.UPDATE_PROFILE_PICTURE_REQUEST:
      return {
        ...state,
        updateProfilePicture: true,
      };
    case consultantConstants.UPDATE_PROFILE_PICTURE_SUCCESS:
      return {
        ...state,
        consultant: action.consultant,
      };
    case consultantConstants.UPDATE_PROFILE_PICTURE_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.RESEND_CODE_REQUEST:
      return {
        ...state,
        resendCode: true,
      };
    case consultantConstants.RESEND_CODE_SUCCESS:
      return {
        ...state,
        resendCode: 'success',
      };
    case consultantConstants.RESEND_CODE_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.GET_CONSULTATION_TYPE_REQUEST:
      return {
        ...state,
        consultationTypesRequest: true,
      };
    case consultantConstants.GET_CONSULTATION_TYPE_SUCCESS:
      return {
        ...state,
        consultationTypes: action.consultationTypes,
      };
    case consultantConstants.GET_CONSULTATION_TYPE_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.GET_PATIENT_TYPE_REQUEST:
      return {
        ...state,
        patientTypesRequest: true,
      };
    case consultantConstants.GET_PATIENT_TYPE_SUCCESS:
      return {
        ...state,
        patientTypes: action.patientTypes,
      };
    case consultantConstants.GET_PATIENT_TYPE_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.GET_MY_ACTIVITY_REQUEST:
      return {
        ...state,
        activityRequest: true,
      };
    case consultantConstants.GET_MY_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: action.activity,
      };
    case consultantConstants.GET_MY_ACTIVITY_FAILURE:
      return {
        ...state,
        errors: action.error,
      };
    case consultantConstants.VERIFY_CONSULTANT_FAILURE:
    case consultantConstants.PASSWORD_RESET_CONSULTANT_FAILURE:
    case consultantConstants.PASSWORD_CHANGE_CONSULTANT_FAILURE:
      return { ...state };
    case consultantConstants.LOGOUT:
      return {};
    case consultantConstants.CLEAR_FORM:
      return { ...state, resetForm: true };
    default:
      return state;
  }
}
