import moment from 'moment';

const CalculateTimes = (endTime, startTime = new Date()) => {
  const diff = moment.unix(endTime).diff(moment(startTime));
  const duration = moment.duration(diff);
  const hours = duration._data.days * 24 + duration._data.hours;
  const minutes = duration._data.minutes;
  return `${hours < 10 && hours > 0 ? `0${hours}` : hours}:${
    minutes < 10 && minutes > 0 ? `0${minutes}` : minutes
  }`;
};

export default CalculateTimes;
