import { patientAllergiesConstants } from '@actions/types';

const initialState = {};

export function allergies(state = initialState, action) {
  switch (action.type) {
    case patientAllergiesConstants.GET_ALLERGIES_REQUEST:
      return {
        ...state,
        fetchingAllergies: true,
      };
    case patientAllergiesConstants.GET_ALLERGIES_SUCCESS:
      return {
        fetchedAllergies: true,
        allergies: action.allergies,
      };
    case patientAllergiesConstants.GET_ALLERGIES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientAllergiesConstants.GET_ALLERGY_REQUEST:
      return {
        ...state,
        fetchingAllergy: true,
      };
    case patientAllergiesConstants.GET_ALLERGY_SUCCESS:
      return {
        fetchedAllergy: true,
        ...state,
        allergy: action.allergy,
      };
    case patientAllergiesConstants.GET_ALLERGY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientAllergiesConstants.GET_PATIENT_ALLERGY_REQUEST:
      return {
        ...state,
        fetchingPatientAllergy: true,
      };
    case patientAllergiesConstants.GET_PATIENT_ALLERGY_SUCCESS:
      return {
        fetchedPatientAllergy: true,
        ...state,
        allergy: action.allergy,
      };
    case patientAllergiesConstants.GET_PATIENT_ALLERGY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientAllergiesConstants.GET_SHARED_ALLERGIES_REQUEST:
      return {
        ...state,
        fetchingAllergies: true,
      };
    case patientAllergiesConstants.GET_SHARED_ALLERGIES_SUCCESS:
      return {
        fetchedAllergies: true,
        allergies: action.allergies,
      };
    case patientAllergiesConstants.GET_SHARED_ALLERGIES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientAllergiesConstants.GET_SHARED_ALLERGY_REQUEST:
      return {
        ...state,
        fetchingSharedAllergy: true,
      };
    case patientAllergiesConstants.GET_SHARED_ALLERGY_SUCCESS:
      return {
        fetchedSharedAllergy: true,
        ...state,
        allergy: action.allergy,
      };
    case patientAllergiesConstants.GET_SHARED_ALLERGY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientAllergiesConstants.ADD_ALLERGIES_REQUEST:
      return {
        ...state,
        addAllergy: true,
      };
    case patientAllergiesConstants.ADD_ALLERGIES_SUCCESS:
      return {
        ...state,
        addedAllergy: true,
        allergy: action.allergy,
      };
    case patientAllergiesConstants.ADD_ALLERGIES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientAllergiesConstants.UPDATE_ALLERGIES_REQUEST:
      return {
        ...state,
        updateingAllergy: true,
      };
    case patientAllergiesConstants.UPDATE_ALLERGIES_SUCCESS:
      return {
        ...state,
        updateedAllergy: true,
        allergy: action.allergy,
      };
    case patientAllergiesConstants.UPDATE_ALLERGIES_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
