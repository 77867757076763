import React, { Component } from 'react';
import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import 'antd/lib/form/style/css';
import 'antd/lib/select/style/css';

import { FormWrapper } from '@components';
import { EgyptIcon, SaudiArabiaIcon, UAEIcon } from '@images';
import { Colors } from '@utils';

const FormItem = Form.Item;
const { Option } = Select;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

class MobileFrom extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      addMobile,
    } = this.props;

    const user = JSON.parse(localStorage.getItem('user'));

    validateFields((err, values) => {
      if (!err && user) {
        const phone = values.code + values.phone;
        addMobile({ mobile: phone, token: user.token });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      width,
      edit,
    } = this.props;

    const countryCodeSelector = getFieldDecorator('code', {
      initialValue: '+20',
    })(
      <Select>
        <Option value="+20">
          <img className="countryIcons" src={EgyptIcon} alt="Egypt Icon" /> +20
        </Option>
        <Option value="+971">
          <img
            className="countryIcons"
            src={SaudiArabiaIcon}
            alt="Saudi Arabia Icon"
          />{' '}
          +971
        </Option>
        <Option value="+965">
          <img className="countryIcons" src={UAEIcon} alt="UAE Icon" /> +965
        </Option>
      </Select>
    );

    return (
      <FormWrapper width={edit ? width : null}>
        <>
          <Form onSubmit={this.handleSubmit}>
            <legend>
              {edit ? 'Edit' : 'Enter'} Mobile Number
              <span>Please Enter Your Mobile Number</span>
            </legend>

            <FormItem label="Phone Number">
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Mobile number!',
                    len: 10,
                  },
                ],
              })(<Input addonBefore={countryCodeSelector} />)}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button">
                {edit ? 'Send' : 'Continue'}
              </SubmitButton>
            </FormItem>
          </Form>
        </>
      </FormWrapper>
    );
  }
}

MobileFrom.propTypes = {
  form: PropTypes.object.isRequired,
  addMobile: PropTypes.func.isRequired,
  width: PropTypes.number,
  edit: PropTypes.bool,
};
export default Form.create({})(MobileFrom);
