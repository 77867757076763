import { patientEcgsConstants } from '@actions/types';

const initialState = {};

export function ecgs(state = initialState, action) {
  switch (action.type) {
    case patientEcgsConstants.GET_ECGS_REQUEST:
      return {
        ...state,
        fetchingecgs: true,
      };
    case patientEcgsConstants.GET_ECGS_SUCCESS:
      return {
        fetchedecgs: true,
        ecgs: action.ecgs,
      };
    case patientEcgsConstants.GET_ECGS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientEcgsConstants.GET_ECG_REQUEST:
      return {
        ...state,
        fetchingecg: true,
      };
    case patientEcgsConstants.GET_ECG_SUCCESS:
      return {
        ...state,
        fetchedecg: true,
        ecg: action.ecg,
      };
    case patientEcgsConstants.GET_ECG_FAILURE:
      return {
        error: action.error,
      };
    case patientEcgsConstants.GET_PATIENT_ECG_REQUEST:
      return {
        ...state,
        fetchingPatientecg: true,
      };
    case patientEcgsConstants.GET_PATIENT_ECG_SUCCESS:
      return {
        ...state,
        fetchedPatientecg: true,
        ecg: action.ecg,
      };
    case patientEcgsConstants.GET_PATIENT_ECG_FAILURE:
      return {
        error: action.error,
      };
    case patientEcgsConstants.GET_SHARED_ECGS_REQUEST:
      return {
        ...state,
        fetchingecgs: true,
      };
    case patientEcgsConstants.GET_SHARED_ECGS_SUCCESS:
      return {
        ...state,
        fetchedecgs: true,
        ecgs: action.ecgs,
      };
    case patientEcgsConstants.GET_SHARED_ECGS_FAILURE:
      return {
        error: action.error,
      };
    case patientEcgsConstants.GET_SHARED_ECG_REQUEST:
      return {
        ...state,
        fetchingSharedecg: true,
      };
    case patientEcgsConstants.GET_SHARED_ECG_SUCCESS:
      return {
        ...state,
        fetchedSharedecg: true,
        ecg: action.ecg,
      };
    case patientEcgsConstants.GET_SHARED_ECG_FAILURE:
      return {
        error: action.error,
      };
    case patientEcgsConstants.ADD_ECGS_REQUEST:
      return {
        ...state,
        addecgs: true,
      };
    case patientEcgsConstants.ADD_ECGS_SUCCESS:
      return {
        ...state,
        addedecgs: true,
        ecg: action.ecg,
      };
    case patientEcgsConstants.ADD_ECGS_FAILURE:
      return {
        error: action.error,
      };
    case patientEcgsConstants.UPDATE_ECGS_REQUEST:
      return {
        ...state,
        updateecgs: true,
      };
    case patientEcgsConstants.UPDATE_ECGS_SUCCESS:
      return {
        ...state,
        updateecgs: true,
        ecg: action.ecg,
      };
    case patientEcgsConstants.UPDATE_ECGS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
