const getNewConsultation = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request`, requestOptions)
    .then(consultations => {
      return { error: false, consultations: consultations };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getAcceptedConsultation = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/my-requests`, requestOptions)
    .then(consultations => {
      return { error: false, consultations: consultations };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const rejectConsultation = id => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/${id}/reject`, requestOptions)
    .then(consultation => {
      return { error: false, consultation: consultation };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const acceptConsultation = id => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/${id}/accept`, requestOptions)
    .then(consultation => {
      return { error: false, consultation: consultation };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getConsultation = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/single/${id}`, requestOptions)
    .then(consultation => {
      return { error: false, consultation: consultation };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const requestScan = ({ scanType, id, title, body }) => {
  const formdata = new FormData();
  formdata.append('consultationId', id);
  formdata.append('scan_type', scanType);
  formdata.append('title', title);
  formdata.append('body', body);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };
  return fetch(`/consultant/consultant-actions/request-scan`, requestOptions)
    .then(scan => {
      return { error: false, scan: scan };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const sendReport = ({ title, note, consultationId }) => {
  const formdata = new FormData();
  formdata.append('consultationId', consultationId);
  formdata.append('note', note);
  formdata.append('title', title);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };
  return fetch(`/consultant/medical-reports`, requestOptions)
    .then(report => {
      return { error: false, report: report };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getFinishedConsultations = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/consultant/consultation-request/done`, requestOptions)
    .then(FinishedConsultations => {
      return { error: false, FinishedConsultations };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getNewConsultation,
  getConsultation,
  getAcceptedConsultation,
  rejectConsultation,
  acceptConsultation,
  requestScan,
  sendReport,
  getFinishedConsultations,
};
