import React from 'react';

import { MainLayout } from '@components';
import NewECGs from './components/NewECGs';

function NewECGsPage(props) {
  return <MainLayout footer="main" children={<NewECGs {...props} />} />;
}

export default NewECGsPage;
