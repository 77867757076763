import React, { Component } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import 'antd/lib/table/style/css';

import {
  ProfilePic,
  convertDateToFullStringDate,
  CapitalizeFirstChart,
} from '@utils';
import { Medical, Medical2X } from '@images';

const ReportAction = styled.div`
  img {
    width: 40px;
    height: 30px;
  }
`;
const ResultTableWrapper = styled.div`
  margin-top: 15px;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background-color: #ffffff;
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 15px;
  }
  .ant-pagination {
    margin-right: 10px;
  }
  .ant-table-thead > tr {
    background: #f9fafc;
  }
`;
const PatientName = styled.div`
  span {
    margin-left: 10px;
  }
`;
const columns = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Patient name',
    dataIndex: 'patient',
    key: 'name',
    render: text => (
      <PatientName>
        <ProfilePic
          user={text}
          width="36"
          height="36"
          style={{ borderRadius: '50%' }}
        />
        <span>{text.name}</span>
      </PatientName>
    ),
  },
  {
    title: 'Date/time',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },

  {
    title: 'Action',
    key: 'action',
    render: record => (
      <ReportAction>
        <Link to={`/consultant/consultation/${record.id}`}>
          <picture>
            <source srcSet={`${Medical2X} 2x`} />
            <img src={Medical} alt="Report" />
          </picture>
        </Link>
      </ReportAction>
    ),
  },
];

export default class ResultTable extends Component {
  render() {
    const { FinishedConsultations } = this.props;
    const data = FinishedConsultations.data.map((consultation, index) => ({
      key: index + 1,
      patient: consultation.patient,
      date: convertDateToFullStringDate(consultation.createdAt),
      status: CapitalizeFirstChart(consultation.status),
      id: consultation.id,
    }));
    return (
      <ResultTableWrapper>
        <Table columns={columns} dataSource={data} />
      </ResultTableWrapper>
    );
  }
}

ResultTable.propTypes = {
  FinishedConsultations: PropTypes.object.isRequired,
};
