const login = (email, password) => {
  const raw = JSON.stringify({ email: email, password: password });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/consultant/login`, requestOptions)
    .then(consultant => {
      // login successful if there's a jwt token in the response
      if (consultant.token) {
        // store consultant details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(consultant));
        localStorage.setItem('userType', 'consultant');
      }
      return { error: false, consultant: consultant };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const register = consultant => {
  const raw = JSON.stringify(consultant);
  const picture = consultant.picture;
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`/consultant`, requestOptions)
    .then(consultant => {
      localStorage.setItem('user', JSON.stringify(consultant));
      localStorage.setItem('userType', 'consultant');
      if (picture) {
        updateProfilePicture(picture);
      }
      return { error: false, consultant: consultant };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateProfile = consultant => {
  const picture = consultant.picture;

  if (picture) {
    updateProfilePicture(picture);
  }
  const raw = JSON.stringify(consultant);
  const requestOptions = {
    method: 'PATCH',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`/consultant/profile`, requestOptions)
    .then(consultant => {
      localStorage.setItem('user', JSON.stringify(consultant));
      localStorage.setItem('userType', 'consultant');
      return { error: false, consultant: consultant };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateProfilePicture = picture => {
  const formdata = new FormData();
  formdata.append('picture', picture, picture.name);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  return fetch(`/consultant/profile-picture`, requestOptions)
    .then(consultant => {
      localStorage.setItem('user', JSON.stringify(consultant));
      localStorage.setItem('userType', 'consultant');
      return { error: false, consultant: consultant };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const resendCode = () => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };

  return fetch(`/consultant/resend-mobile-verification-code`, requestOptions)
    .then(res => {
      return { error: false, res };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const verify = ({ code }) => {
  const raw = JSON.stringify({ verificationCode: code });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`/consultant/verify-mobile`, requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'consultant');
      return { error: false, user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const resetPassword = email => {
  const raw = JSON.stringify({ email: email });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`/consultant/forgot-password`, requestOptions).then(response => {
    return { success: response.message };
  });
};

const changePassword = ({ oldPassword, password }) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      oldPassword: oldPassword,
      password: password,
    }),
  };

  return fetch(`/consultant/change-password/`, requestOptions)
    .then(res => {
      return { error: false, user: res };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getConsultationType = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultation-type`, requestOptions)
    .then(res => {
      return { error: false, consultationTypes: res };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getPatientType = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient-type`, requestOptions)
    .then(res => {
      return { error: false, patientTypes: res };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getMyActivity = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/myactivity`, requestOptions)
    .then(activity => {
      return { error: false, activity: activity };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('userType');
};

export default {
  register,
  login,
  logout,
  resendCode,
  verify,
  resetPassword,
  changePassword,
  getConsultationType,
  getPatientType,
  updateProfile,
  updateProfilePicture,
  getMyActivity,
};
