const login = (email, password) => {
  const raw = JSON.stringify({ email: email, password: password });

  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/login`, requestOptions)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('userType', 'patient');
      }
      return { error: false, user: user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const facebook = token => {
  const raw = JSON.stringify({ facebookUserAccessToken: `${token}` });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/facebook`, requestOptions)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('userType', 'patient');
      }
      return { error: false, user: user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const register = user => {
  const { firstName, email, password, dateOfBirth, gender } = user;

  const raw = JSON.stringify({
    firstName: firstName,
    middleName: 'Mostafa',
    lastName: 'Salama',
    email: email,
    password: password,
    dateOfBirth: dateOfBirth,
    gender: gender,
  });
  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };
  return fetch(`/patient`, requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user: user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addMobile = ({ mobile }) => {
  const raw = JSON.stringify({ mobile: mobile });

  const requestOptions = {
    method: 'PATCH',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/profile`, requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user: user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateProfile = userInfo => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { email, mobile, gender, dateOfBirth, picture } = userInfo;
  const raw = JSON.stringify({ email, mobile, gender, dateOfBirth });

  const requestOptions = {
    method: 'PATCH',
    body: raw,
    redirect: 'follow',
  };

  if (picture) {
    updateProfilePicture(picture, user.token);
  }

  return fetch(`/patient/profile`, requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user: user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateProfilePicture = picture => {
  const formdata = new FormData();
  formdata.append('picture', picture, picture.name);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  return fetch(`/patient/profile-picture`, requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user: user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const resendCode = () => {
  const requestOptions = {
    method: 'POST',

    redirect: 'follow',
  };

  return fetch(`/patient/resend-mobile-verification-code`, requestOptions)
    .then(res => {
      return { error: false, res };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const verify = ({ code }) => {
  const raw = JSON.stringify({ verificationCode: code });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`/patient/verify-mobile`, requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const resendVerificationEmail = ({ userId }) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({}),
  };

  return fetch(`/patient/${userId}/verification-email`, requestOptions).then(
    response => {
      return { verified: response.ok };
    }
  );
};

const resetPassword = email => {
  const raw = JSON.stringify({ email: email });
  const requestOptions = {
    method: 'POST',
    body: raw,
  };

  return fetch(`/patient/forgot-password`, requestOptions).then(response => {
    return { success: response.message };
  });
};

const changePassword = ({ oldPassword, password }) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify({
      oldPassword: oldPassword,
      password: password,
    }),
  };

  return fetch(`/patient/change-password`, requestOptions)
    .then(res => {
      return { error: false, user: res };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const generateInformationShareToken = () => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch('/patient/generate-information-share-token', requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const stopInformationShareToken = () => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };
  return fetch('/patient/stop-information-share-token', requestOptions)
    .then(user => {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('userType', 'patient');
      return { error: false, user };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('userType');
};

export default {
  register,
  login,
  resendVerificationEmail,
  verify,
  logout,
  resetPassword,
  changePassword,
  addMobile,
  resendCode,
  facebook,
  updateProfile,
  generateInformationShareToken,
  stopInformationShareToken,
};
