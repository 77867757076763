import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import moment from 'moment';
import { Icon, Form, Input, Row, Col, Select, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import 'antd/lib/icon/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/upload/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';

import { UploadProfilePicture } from '@components';

const FormItem = Form.Item;
const { Option } = Select;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5cb3db;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5cb3db;
    line-height: 24px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
`;

const UserInfoContentWrapper = styled.div`
  .img-upload-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    text-align: center;
    margin: 0;
    * {
      max-width: 100%;
      max-height: 100%;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .ant-upload {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: relative;
      padding: 0;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 50%;
        opacity: 0.7;
        box-shadow: 0 50px 50px 0 rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.5),
          #5cb3db
        );
      }
    }
  }
`;

const UserInfoContent = styled.div`
  width: 100%;
  margin-top: 25px;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
    &:after {
      content: none;
    }
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #000;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
  .ant-input-group-addon {
    width: 100px;
    padding: 0;
    .ant-select.ant-select-enabled {
      width: 100% !important;
    }
  }
  .countryIcons {
    width: 24px;
    height: 24px;
  }
`;

const MyInfoWrapper = styled.div`
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
`;

export default class MyInfoForm extends Component {
  state = {
    confirmDirty: false,
    countries: null,
  };
  componentDidMount() {
    this.getCountries();
  }
  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };
  getCountries = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };
    return fetch('https://restcountries.eu/rest/v2/all', requestOptions)
      .then(result => this.setState({ countries: result }))
      .catch(error => console.log('error', error));
  };
  render() {
    const {
      user,
      form,
      form: { getFieldDecorator },
      editFormIsOpen,
      handleEditFormToggle,
      handleCancel,
      consultant,
      setProfilePic,
    } = this.props;
    const { countries } = this.state;
    const countryCodeSelector = getFieldDecorator('code', {
      initialValue: '+20',
    })(
      <Select>
        {countries &&
          countries.map(country => {
            return (
              <Option
                key={JSON.stringify(country)}
                value={`+${country.callingCodes[0]}`}
                className="phone-option"
              >
                <img
                  className="countryIcons"
                  src={country.flag}
                  alt={`${country.name} Icon`}
                />
                {`+${country.callingCodes[0]}`}
              </Option>
            );
          })}
      </Select>
    );

    const editAccountForm = (
      <FormItem className="img-upload-item">
        <UploadProfilePicture
          form={form}
          user={user}
          setProfilePic={setProfilePic}
        />
      </FormItem>
    );

    return (
      <MyInfoWrapper>
        <UserInfoWrapper>
          <h2>My Info</h2>
          {handleEditFormToggle ? (
            editFormIsOpen ? (
              <div>
                <FormItem>
                  <button htmltype="submit" className="save">
                    Save
                  </button>
                </FormItem>
                <FormItem>
                  <button
                    htmltype="button"
                    className="close"
                    onClick={handleCancel}
                  >
                    &times;
                  </button>
                </FormItem>
              </div>
            ) : (
              <button
                className="ant-dropdown-link"
                onClick={ev => {
                  ev.preventDefault();
                  handleEditFormToggle();
                }}
              >
                <Icon type="edit" theme="filled" />
              </button>
            )
          ) : (
            <></>
          )}
        </UserInfoWrapper>
        <UserInfoContentWrapper>
          {editFormIsOpen && editAccountForm}
          <UserInfoContent>
            <Row>
              {consultant && (
                <>
                  <Col span={12}>
                    <FormItem label={'First Name'}>
                      {getFieldDecorator('firstName', {
                        initialValue: user && user.name,
                      })(<Input disabled={!editFormIsOpen && true} />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label={'Last Name'}>
                      {getFieldDecorator('lastName', {
                        initialValue: user && user.name,
                      })(<Input disabled={!editFormIsOpen && true} />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Password" hasFeedback>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            message: 'Please input your password!',
                          },
                          {
                            validator: this.validateToNextPassword,
                          },
                        ],
                      })(<Input.Password />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Confirm Password" hasFeedback>
                      {getFieldDecorator('confirm', {
                        rules: [
                          {
                            message: 'Please confirm your password!',
                          },
                          {
                            validator: this.compareToFirstPassword,
                          },
                        ],
                      })(<Input.Password onBlur={this.handleConfirmBlur} />)}
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={12}>
                <FormItem label={'Email'}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                      },
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ],
                    initialValue: user && user.email,
                  })(<Input disabled={!editFormIsOpen && true} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                {countries ? (
                  <FormItem label={'Phone Number'}>
                    {getFieldDecorator('mobile', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Mobile Number!',
                        },
                      ],
                      initialValue: user && user.mobile,
                    })(
                      <Input
                        addonBefore={editFormIsOpen && countryCodeSelector}
                        disabled={!editFormIsOpen && true}
                      />
                    )}
                  </FormItem>
                ) : (
                  <FormItem label={'Phone Number'}>
                    {getFieldDecorator('mobile', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Mobile Number!',
                        },
                      ],
                      initialValue: user && user.mobile,
                    })(<Input disabled={!editFormIsOpen && true} />)}
                  </FormItem>
                )}
              </Col>
              <Col span={12}>
                <FormItem label="Gender">
                  {getFieldDecorator('gender', {
                    initialValue: user && user.gender,
                  })(
                    <Select
                      placeholder="Gender"
                      disabled={!editFormIsOpen && true}
                    >
                      <Option value="male">male</Option>
                      <Option value="female">female</Option>
                    </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Date of Birth">
                  {getFieldDecorator('dateOfBirth', {
                    initialValue:
                      user && user.dateOfBirth && moment.unix(user.dateOfBirth),
                  })(
                    <DatePicker
                      format="Do MMM YY"
                      disabled={!editFormIsOpen && true}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </UserInfoContent>
        </UserInfoContentWrapper>
      </MyInfoWrapper>
    );
  }
}

MyInfoForm.propTypes = {
  user: PropTypes.object,
  form: PropTypes.object.isRequired,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleEditFormToggle: PropTypes.func,
  handleCancel: PropTypes.func,
  consultant: PropTypes.bool.isRequired,
  setProfilePic: PropTypes.func,
};
