import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientLabResultsActions } from '@actions';
import { Container, MedicalReport } from '@components';
class LabResult extends Component {
  componentDidMount() {
    const {
      getLabResult,
      getPatientLabResult,
      getSharedLabResult,
      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (consultationId && id) {
      getLabResult(id);
    } else if (id && !consultationId && !sharedToken) {
      getPatientLabResult(id);
    } else if (id && sharedToken && patientId) {
      getSharedLabResult(patientId, id, sharedToken);
    }
  }
  render() {
    const {
      labResult,
      match: {
        params: { consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
          {
            item: 'Lab Results',
            url: `/consultation/${consultationId}/lab-results/${patientId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
          {
            item: 'Lab Results',
            url: `/lab-results/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : [
          { item: 'Medical Record ', url: '/#MedicalRecord' },
          {
            item: 'Lab Results',
            url: `/lab-results`,
          },
        ];

    return (
      <Container>
        {labResult && (
          <MedicalReport
            title={labResult.title}
            updatedAt={labResult.updatedAt}
            description={labResult.note}
            image={{
              fileLocation: labResult.fileLocation,
              fileName: labResult.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { labResult } = state.labResults;
  return {
    labResult,
  };
};

const mapDispatchToProps = dispatch => ({
  getLabResult: id => dispatch(patientLabResultsActions.getLabResult(id)),
  getPatientLabResult: id =>
    dispatch(patientLabResultsActions.getPatientLabResult(id)),
  getSharedLabResult: (patientId, id, informationShareToken) =>
    dispatch(
      patientLabResultsActions.getSharedLabResult(
        patientId,
        id,
        informationShareToken
      )
    ),
});

LabResult.propTypes = {
  getLabResult: PropTypes.func.isRequired,
  labResult: PropTypes.object,
  match: PropTypes.object.isRequired,
  getPatientLabResult: PropTypes.func.isRequired,
  getSharedLabResult: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabResult);
