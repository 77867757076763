import React from 'react';

import Home from './Home';
import { MainLayout } from '@components';

const HomePage = props => (
  <MainLayout footer="main" children={<Home {...props} />} />
);

export default HomePage;
