import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Container } from '@components';
import { patientProfileActions, patientActions } from '@actions';
import UserProfileCard from './UserProfileCard';
import UserProfileNavigation from './UserProfileNavigation';
import UserInfo from './UserInfo';
import MyAddress from './Address';
import ChangePassword from './ChangePassword';
import EmergencyInfo from './EmergencyInfo';
import Payment from './Payment';
import AddPayment from './AddPayment';
import SharedData from './SharedData';

const ProfilePageWrapper = styled.div`
  .profile-border {
    box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
    border-radius: 18px;
    border: solid 1px #e4e9f2;
    padding: 33px;
    background-color: #fff;
    &.ant-col {
      padding: 0;
      border: none;
      border-radius: 6px;
    }
  }
`;

class ProfilePage extends Component {
  state = {
    currentView: 'userInfo',
  };
  setCurrentView = show => {
    this.setState({ currentView: show });
  };
  render() {
    const { currentView } = this.state;
    const {
      addAddress,
      updateAddress,
      addEmergency,
      updateEmergency,
      updateProfile,
      changePassword,
      getAddress,
      getEmergency,
      generateInformationShareToken,
      user,
      stopInformationShareToken,
    } = this.props;

    return (
      <Container className="profile">
        <ProfilePageWrapper>
          <Row>
            <Col className="profile-border" span={6}>
              <UserProfileCard
                user={user}
                generateInformationShareToken={generateInformationShareToken}
                setCurrentView={this.setCurrentView}
              />
              <UserProfileNavigation setCurrentView={this.setCurrentView} />
            </Col>
            {currentView === 'userInfo' && (
              <Col span={17} offset={1}>
                <UserInfo user={user} updateProfile={updateProfile} />
                <MyAddress
                  addAddress={addAddress}
                  updateAddress={updateAddress}
                  getAddress={getAddress}
                />
                <EmergencyInfo
                  addEmergency={addEmergency}
                  updateEmergency={updateEmergency}
                  getEmergency={getEmergency}
                />
              </Col>
            )}
            {currentView === 'Payment' && (
              <Col span={17} offset={1}>
                <Payment setCurrentView={this.setCurrentView} />
              </Col>
            )}
            {currentView === 'changePassword' && (
              <Col span={17} offset={1}>
                <ChangePassword changePassword={changePassword} />
              </Col>
            )}
            {currentView === 'addPayment' && (
              <Col span={17} offset={1}>
                <AddPayment />
              </Col>
            )}
            {currentView === 'shareData' && (
              <Col span={17} offset={1}>
                <SharedData
                  user={user}
                  generateInformationShareToken={generateInformationShareToken}
                  stopInformationShareToken={stopInformationShareToken}
                />
              </Col>
            )}
          </Row>
        </ProfilePageWrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { address, emergency } = state.profile;
  const { user } = state.authentication;
  return {
    address,
    emergency,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  getAddress: () => dispatch(patientProfileActions.getAddress()),
  addAddress: address => dispatch(patientProfileActions.addAddress(address)),
  updateAddress: (address, id) =>
    dispatch(patientProfileActions.updateAddress(address, id)),
  getEmergency: () => dispatch(patientProfileActions.getEmergency()),
  addEmergency: emergency =>
    dispatch(patientProfileActions.addEmergency(emergency)),
  updateEmergency: (emergency, id) =>
    dispatch(patientProfileActions.updateEmergency(emergency, id)),
  updateProfile: user => dispatch(patientActions.updateProfile(user)),
  changePassword: ({ oldPassword, password }) =>
    dispatch(patientActions.changePassword({ oldPassword, password })),
  generateInformationShareToken: () =>
    dispatch(patientActions.generateInformationShareToken()),
  stopInformationShareToken: () =>
    dispatch(patientActions.stopInformationShareToken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);

ProfilePage.propTypes = {
  user: PropTypes.object,
  address: PropTypes.object,
  emergency: PropTypes.object,
  getAddress: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  getEmergency: PropTypes.func.isRequired,
  addEmergency: PropTypes.func.isRequired,
  updateEmergency: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  generateInformationShareToken: PropTypes.func.isRequired,
  stopInformationShareToken: PropTypes.func.isRequired,
};
