import React from 'react';

import { Consultation } from './components';
import { MainLayout } from '@components';

const ConsultationPage = props => (
  <MainLayout footer="main" children={<Consultation {...props} />} />
);

export default ConsultationPage;
