import { patientLabResultsConstants } from '@actions/types';

const initialState = {};

export function labResults(state = initialState, action) {
  switch (action.type) {
    case patientLabResultsConstants.GET_LAB_RESULTS_REQUEST:
      return {
        ...state,
        fetchingLabresults: true,
      };
    case patientLabResultsConstants.GET_LAB_RESULTS_SUCCESS:
      return {
        fetchedLabresults: true,
        labResults: action.labResults,
      };
    case patientLabResultsConstants.GET_LAB_RESULTS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientLabResultsConstants.GET_LAB_RESULT_REQUEST:
      return {
        ...state,
        fetchingLabresult: true,
      };
    case patientLabResultsConstants.GET_LAB_RESULT_SUCCESS:
      return {
        ...state,
        fetchedLabresult: true,
        labResult: action.labResult,
      };
    case patientLabResultsConstants.GET_LAB_RESULT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientLabResultsConstants.GET_PATIENT_LAB_RESULT_REQUEST:
      return {
        ...state,
        fetchingPatientLabresult: true,
      };
    case patientLabResultsConstants.GET_PATIENT_LAB_RESULT_SUCCESS:
      return {
        ...state,
        fetchedPatientLabresult: true,
        labResult: action.labResult,
      };
    case patientLabResultsConstants.GET_PATIENT_LAB_RESULT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientLabResultsConstants.GET_SHARED_LAB_RESULTS_REQUEST:
      return {
        ...state,
        fetchingLabresults: true,
      };
    case patientLabResultsConstants.GET_SHARED_LAB_RESULTS_SUCCESS:
      return {
        fetchedLabresults: true,
        labResults: action.labResults,
      };
    case patientLabResultsConstants.GET_SHARED_LAB_RESULTS_FAILURE:
      return {
        error: action.error,
      };
    case patientLabResultsConstants.GET_SHARED_LAB_RESULT_REQUEST:
      return {
        ...state,
        fetchingSharedLabresult: true,
      };
    case patientLabResultsConstants.GET_SHARED_LAB_RESULT_SUCCESS:
      return {
        ...state,
        fetchedSharedLabresult: true,
        labResult: action.labResult,
      };
    case patientLabResultsConstants.GET_SHARED_LAB_RESULT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientLabResultsConstants.ADD_LAB_RESULTS_REQUEST:
      return {
        ...state,
        addLabresults: true,
      };
    case patientLabResultsConstants.ADD_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        addedLabresults: true,
        labResult: action.labResult,
      };
    case patientLabResultsConstants.ADD_LAB_RESULTS_FAILURE:
      return {
        error: action.error,
      };
    case patientLabResultsConstants.UPDATE_LAB_RESULTS_REQUEST:
      return {
        ...state,
        updateLabresults: true,
      };
    case patientLabResultsConstants.UPDATE_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        updateLabresults: true,
        labResult: action.labResult,
      };
    case patientLabResultsConstants.UPDATE_LAB_RESULTS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
