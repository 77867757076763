import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { StepAction } from './';
import { H1 } from '@utils';
import { AcceptMessage, AcceptMessage2X } from '@images';

const DoneWrapper = styled.div`
  width: 650px;
  margin: auto;
  order: 1;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  img {
    width: 220px;
    text-align: center;
    margin-bottom: 30px;
  }
  h1 {
    margin-bottom: 5px;
  }
`;

export default function Done(props) {
  const { stepsLength, prev } = props;
  return (
    <div>
      <DoneWrapper>
        <picture>
          <source srcSet={`${AcceptMessage2X} 2x`} />
          <img src={AcceptMessage} alt="Thanks" />
        </picture>
        <H1>Send Sucssefuly</H1>
        <p>Your Request has been send sucssefuly</p>
      </DoneWrapper>
      <StepAction stepsLength={stepsLength} prev={prev} />
    </div>
  );
}

Done.propTypes = {
  stepsLength: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
};
