import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientAllergyActions } from '@actions';

import { Container, MedicalReport } from '@components';
class Allergy extends Component {
  componentDidMount() {
    const {
      getAllergy,
      getPatientAllergy,
      getSharedAllergy,
      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    console.log(id);
    if (consultationId && id) {
      getAllergy(id);
    } else if (id && !consultationId && !sharedToken) {
      getPatientAllergy(id);
    } else if (id && sharedToken && patientId) {
      getSharedAllergy(patientId, id, sharedToken);
    }
  }
  render() {
    const {
      allergy,
      match: {
        params: { consultationId },
      },
    } = this.props;

    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
          {
            item: 'Allergies',
            url: `/consultation/${consultationId}/allergies/${patientId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
          {
            item: 'Allergies',
            url: `/allergies/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : [
          { item: 'Medical Record ', url: '/#MedicalRecord' },
          {
            item: 'Allergies',
            url: `/allergies`,
          },
        ];

    return (
      <Container>
        {allergy && (
          <MedicalReport
            title={allergy.value}
            updatedAt={allergy.updatedAt}
            description={allergy.description}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { allergy } = state.allergies;
  return {
    allergy,
  };
};

const mapDispatchToProps = dispatch => ({
  getAllergy: id => dispatch(patientAllergyActions.getAllergy(id)),
  getPatientAllergy: id =>
    dispatch(patientAllergyActions.getPatientAllergy(id)),
  getSharedAllergy: (patientId, id, informationShareToken) =>
    dispatch(
      patientAllergyActions.getSharedAllergy(
        patientId,
        id,
        informationShareToken
      )
    ),
});

Allergy.propTypes = {
  getAllergy: PropTypes.func.isRequired,
  allergy: PropTypes.object,
  match: PropTypes.object.isRequired,
  getPatientAllergy: PropTypes.func.isRequired,
  getSharedAllergy: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allergy);
