import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from '@emotion/styled/macro';
import { Form, Input, Select, DatePicker } from 'antd';
import 'antd/lib/form/style/css';
import 'antd/lib/date-picker/style/css';
import 'antd/lib/select/style/css';

import { FormWrapper, Loading } from '@components';
import { Colors } from '@utils';

const { Option } = Select;
const FormItem = Form.Item;
const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const HaveAccount = styled.p`
  text-align: center;
  font-family: AvenirNext, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 30px auto 0;
  a {
    margin: 0;
  }
`;
class RegistrationForm extends React.Component {
  state = {
    loading: false,
  };
  componentDidUpdate(props) {
    // need to refactor and move loading to reduex
    const { loading } = this.state;
    if (loading && (props.errors || props.user)) {
      this.setState({ loading: false });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      register,
      form: { validateFields },
    } = this.props;

    validateFields((err, values) => {
      this.setState({ loading: true });
      if (!err) {
        const { name, email, password, gender, dateOfBirth } = values;
        const date = moment(dateOfBirth).unix('x');
        const user = {
          firstName: name,
          email,
          password,
          dateOfBirth: date,
          gender,
        };
        register(user);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        {loading && <Loading />}
        <FormWrapper>
          <>
            <Form onSubmit={this.handleSubmit}>
              <legend>New Account</legend>
              <FormItem label={'Full Name'}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'please fill Name field',
                    },
                  ],
                })(<Input placeholder="Full Name" />)}
              </FormItem>
              <FormItem label={'Email'}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      required: true,
                      message: 'please fill Email field',
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem label="Date Of Brith">
                {getFieldDecorator('dateOfBirth', {
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select your Brith Date!',
                      value: moment('1990/01/01', 'YYYY-MM-DD'),
                    },
                  ],
                })(
                  <DatePicker
                    format="YYYY-MM-DD"
                    placeholder="Date Of Brith!"
                  />
                )}
              </FormItem>
              <FormItem label="Gender">
                {getFieldDecorator('gender', {
                  rules: [
                    { required: true, message: 'Please select your gender!' },
                  ],
                })(
                  <Select placeholder="Gender">
                    <Option value={'male'}>male</Option>
                    <Option value={'female'}>female</Option>
                  </Select>
                )}
              </FormItem>
              <FormItem label="Password">
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      message: 'poor',
                      min: 6,
                    },
                  ],
                })(<Input.Password placeholder="Password" />)}
              </FormItem>
              <FormItem>
                <SubmitButton className="submit-button" htmlType="submit">
                  Register
                </SubmitButton>
              </FormItem>
              <HaveAccount>
                already have an account ? <Link to="/login">login!</Link>
              </HaveAccount>
            </Form>
          </>
        </FormWrapper>
      </>
    );
  }
}

export default Form.create({})(RegistrationForm);

RegistrationForm.propTypes = {
  register: PropTypes.func,
  signUp: PropTypes.func,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object,
  user: PropTypes.object,
};
