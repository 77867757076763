import { alertActions } from '@actions';
import { patientRadiologistServices } from '@services';
import patientRadiologistConstants from './types';

const getXRays = page => {
  return dispatch => {
    dispatch(getXRaysRequest());
    patientRadiologistServices.getXRays(page).then(res => {
      if (res.error) {
        dispatch(getXRaysFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getXRaysSuccess(res.xrays));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getXRay = id => {
  return dispatch => {
    dispatch(getXRayRequest());
    patientRadiologistServices.getXRay(id).then(res => {
      if (res.error) {
        dispatch(getXRayFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getXRaySuccess(res.xray));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedXRays = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedXRaysRequest());
    patientRadiologistServices
      .getSharedXRays(id, informationShareToken, page)
      .then(res => {
        console.log('RES');
        console.log(res);
        if (res.error) {
          dispatch(getSharedXRaysFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedXRaysSuccess(res.xrays));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addXRay = XRay => {
  return dispatch => {
    dispatch(addXRayRequest(XRay));
    patientRadiologistServices.addXRay(XRay).then(res => {
      if (res.error) {
        dispatch(addXRayFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        } else {
          dispatch(
            alertActions.error(
              res.errors || 'An Error occured, please try again!'
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addXRaySuccess(res.xray));
        dispatch(alertActions.success('Your XRay has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateXRay = (id, XRay) => {
  return dispatch => {
    dispatch(updateXRayRequest(XRay));
    patientRadiologistServices.updateXRay(id, XRay).then(res => {
      if (res.error) {
        dispatch(updateXRayFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        } else {
          dispatch(
            alertActions.error(
              res.errors || 'An Error occured, please try again!'
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(updateXRaySuccess(res.xray));
        dispatch(alertActions.success('Your XRay has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getXRaysRequest = () => ({
  type: patientRadiologistConstants.GET_XRAYS_REQUEST,
});
const getXRaysSuccess = xrays => ({
  type: patientRadiologistConstants.GET_XRAYS_SUCCESS,
  xrays,
});
const getXRaysFailure = error => ({
  type: patientRadiologistConstants.GET_XRAYS_FAILURE,
  error,
});

const getXRayRequest = () => ({
  type: patientRadiologistConstants.GET_XRAY_REQUEST,
});
const getXRaySuccess = xray => ({
  type: patientRadiologistConstants.GET_XRAY_SUCCESS,
  xray,
});
const getXRayFailure = error => ({
  type: patientRadiologistConstants.GET_XRAY_FAILURE,
  error,
});

const getSharedXRaysRequest = () => ({
  type: patientRadiologistConstants.GET_SHARED_XRAYS_REQUEST,
});
const getSharedXRaysSuccess = xrays => ({
  type: patientRadiologistConstants.GET_SHARED_XRAYS_SUCCESS,
  xrays,
});
const getSharedXRaysFailure = error => ({
  type: patientRadiologistConstants.GET_SHARED_XRAYS_FAILURE,
  error,
});

const addXRayRequest = xray => ({
  type: patientRadiologistConstants.ADD_XRAY_REQUEST,
  xray,
});
const addXRaySuccess = xray => ({
  type: patientRadiologistConstants.ADD_XRAY_SUCCESS,
  xray,
});
const addXRayFailure = error => ({
  type: patientRadiologistConstants.ADD_XRAY_FAILURE,
  error,
});

const updateXRayRequest = xray => ({
  type: patientRadiologistConstants.UPDATE_XRAY_REQUEST,
  xray,
});
const updateXRaySuccess = xray => ({
  type: patientRadiologistConstants.UPDATE_XRAY_SUCCESS,
  xray,
});
const updateXRayFailure = error => ({
  type: patientRadiologistConstants.UPDATE_XRAY_FAILURE,
  error,
});

const getMRIs = page => {
  return dispatch => {
    dispatch(getMRIsRequest());
    patientRadiologistServices.getMRIs(page).then(res => {
      if (res.error) {
        dispatch(getMRIsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMRIsSuccess(res.mris));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getMRI = id => {
  return dispatch => {
    dispatch(getMRIRequest());
    patientRadiologistServices.getMRI(id).then(res => {
      if (res.error) {
        dispatch(getMRIFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMRISuccess(res.mri));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedMRIs = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedMRIsRequest());
    patientRadiologistServices
      .getSharedMRIs(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedMRIsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedMRIsSuccess(res.mris));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addMRI = MRI => {
  return dispatch => {
    dispatch(addMRIRequest(MRI));
    patientRadiologistServices.addMRI(MRI).then(res => {
      if (res.error) {
        dispatch(addMRIFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        } else {
          dispatch(
            alertActions.error(
              res.errors || 'An Error occured, please try again!'
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addMRISuccess(res.mri));
        dispatch(alertActions.success('Your MRI has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateMRI = (id, MRI) => {
  return dispatch => {
    dispatch(updateMRIRequest(MRI));
    patientRadiologistServices.updateMRI(id, MRI).then(res => {
      if (res.error) {
        dispatch(updateMRIFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        } else {
          dispatch(
            alertActions.error(
              res.errors || 'An Error occured, please try again!'
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(updateMRISuccess(res.mri));
        dispatch(alertActions.success('Your MRI has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getMRIsRequest = () => ({
  type: patientRadiologistConstants.GET_MRIS_REQUEST,
});
const getMRIsSuccess = mris => ({
  type: patientRadiologistConstants.GET_MRIS_SUCCESS,
  mris,
});
const getMRIsFailure = error => ({
  type: patientRadiologistConstants.GET_MRIS_FAILURE,
  error,
});

const getMRIRequest = () => ({
  type: patientRadiologistConstants.GET_MRI_REQUEST,
});
const getMRISuccess = mri => ({
  type: patientRadiologistConstants.GET_MRI_SUCCESS,
  mri,
});
const getMRIFailure = error => ({
  type: patientRadiologistConstants.GET_MRI_FAILURE,
  error,
});

const getSharedMRIsRequest = () => ({
  type: patientRadiologistConstants.GET_SHARED_MRIS_REQUEST,
});
const getSharedMRIsSuccess = mris => ({
  type: patientRadiologistConstants.GET_SHARED_MRIS_SUCCESS,
  mris,
});
const getSharedMRIsFailure = error => ({
  type: patientRadiologistConstants.GET_SHARED_MRIS_FAILURE,
  error,
});

const addMRIRequest = mri => ({
  type: patientRadiologistConstants.ADD_MRI_REQUEST,
  mri,
});
const addMRISuccess = mri => ({
  type: patientRadiologistConstants.ADD_MRI_SUCCESS,
  mri,
});
const addMRIFailure = error => ({
  type: patientRadiologistConstants.ADD_MRI_FAILURE,
  error,
});

const updateMRIRequest = mri => ({
  type: patientRadiologistConstants.UPDATE_MRI_REQUEST,
  mri,
});
const updateMRISuccess = mri => ({
  type: patientRadiologistConstants.UPDATE_MRI_SUCCESS,
  mri,
});
const updateMRIFailure = error => ({
  type: patientRadiologistConstants.UPDATE_MRI_FAILURE,
  error,
});

const getCTs = page => {
  return dispatch => {
    dispatch(getCTsRequest());
    patientRadiologistServices.getCTs(page).then(res => {
      if (res.error) {
        dispatch(getCTsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getCTsSuccess(res.cts));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getCT = id => {
  return dispatch => {
    dispatch(getCTRequest());
    patientRadiologistServices.getCT(id).then(res => {
      if (res.error) {
        dispatch(getCTFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getCTSuccess(res.ct));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedCTs = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedCTsRequest());
    patientRadiologistServices
      .getSharedCTs(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedCTsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedCTsSuccess(res.cts));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addCT = CT => {
  return dispatch => {
    dispatch(addCTRequest(CT));
    patientRadiologistServices.addCT(CT).then(res => {
      if (res.error) {
        dispatch(addCTFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        } else {
          dispatch(
            alertActions.error(
              res.errors || 'An Error occured, please try again!'
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addCTSuccess(res.ct));
        dispatch(alertActions.success('Your CT has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateCT = (id, CT) => {
  return dispatch => {
    dispatch(updateCTRequest(CT));
    patientRadiologistServices.updateCT(id, CT).then(res => {
      if (res.error) {
        dispatch(updateCTFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        } else {
          dispatch(
            alertActions.error(
              res.errors || 'An Error occured, please try again!'
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(updateCTSuccess(res.ct));
        dispatch(alertActions.success('Your CT has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getCTsRequest = () => ({
  type: patientRadiologistConstants.GET_CTS_REQUEST,
});
const getCTsSuccess = cts => ({
  type: patientRadiologistConstants.GET_CTS_SUCCESS,
  cts,
});
const getCTsFailure = error => ({
  type: patientRadiologistConstants.GET_CTS_FAILURE,
  error,
});

const getCTRequest = () => ({
  type: patientRadiologistConstants.GET_CT_REQUEST,
});
const getCTSuccess = ct => ({
  type: patientRadiologistConstants.GET_CT_SUCCESS,
  ct,
});
const getCTFailure = error => ({
  type: patientRadiologistConstants.GET_CT_FAILURE,
  error,
});

const getSharedCTsRequest = () => ({
  type: patientRadiologistConstants.GET_SHARED_CTS_REQUEST,
});
const getSharedCTsSuccess = cts => ({
  type: patientRadiologistConstants.GET_SHARED_CTS_SUCCESS,
  cts,
});
const getSharedCTsFailure = error => ({
  type: patientRadiologistConstants.GET_SHARED_CTS_FAILURE,
  error,
});

const addCTRequest = ct => ({
  type: patientRadiologistConstants.ADD_CT_REQUEST,
  ct,
});
const addCTSuccess = ct => ({
  type: patientRadiologistConstants.ADD_CT_SUCCESS,
  ct,
});
const addCTFailure = error => ({
  type: patientRadiologistConstants.ADD_CT_FAILURE,
  error,
});

const updateCTRequest = ct => ({
  type: patientRadiologistConstants.UPDATE_CT_REQUEST,
  ct,
});
const updateCTSuccess = ct => ({
  type: patientRadiologistConstants.UPDATE_CT_SUCCESS,
  ct,
});
const updateCTFailure = error => ({
  type: patientRadiologistConstants.UPDATE_CT_FAILURE,
  error,
});

export const patientRadiologistActions = {
  getXRays,
  getXRay,
  getSharedXRays,
  addXRay,
  updateXRay,
  getMRIs,
  getMRI,
  getSharedMRIs,
  addMRI,
  updateMRI,
  getCTs,
  getCT,
  getSharedCTs,
  addCT,
  updateCT,
};
