import React from 'react';
import { connect } from 'react-redux';

import { patientActions } from '@actions';
import { BaseLayout, SetPasswordForm, FullPage, Container } from '@components';

const NewPassword = props => (
  <BaseLayout>
    <FullPage>
      <Container>
        <SetPasswordForm {...props} />
      </Container>
    </FullPage>
  </BaseLayout>
);

const mapStateToProps = state => {
  const { resetForm } = state.authentication;
  return {
    resetForm,
  };
};

const mapDispatchToProps = dispatch => ({
  changePassword: ({ token, password }) =>
    dispatch(patientActions.changePassword({ token, password })),
});

const NewPasswordPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPassword);
export default NewPasswordPageContainer;
