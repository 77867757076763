import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import styled from '@emotion/styled/macro';
import 'antd/lib/form/style/css';

import { FormWrapper } from '@components';
import { Colors } from '@utils';

const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;
class SetPasswordForm extends React.Component {
  state = {
    confirmDirty: null,
  };
  handleSubmit = e => {
    e.preventDefault();
    const {
      // changePassword,
      form: { validateFields },
    } = this.props;
    // TODO: Get form values & call the changePassword method

    validateFields((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const {
      form: { getFieldValue },
    } = this.props;
    if (value && value !== getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <FormWrapper>
        <>
          <Form onSubmit={this.handleSubmit}>
            <legend>Reset Password</legend>
            <FormItem label="Password" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password placeholder="Password" />)}
            </FormItem>
            <FormItem label="Confirm Password" hasFeedback>
              {getFieldDecorator('confirm', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(
                <Input.Password
                  onBlur={this.handleConfirmBlur}
                  placeholder="confirm Password"
                />
              )}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Save Password
              </SubmitButton>
            </FormItem>
          </Form>
        </>
      </FormWrapper>
    );
  }
}

export default Form.create({})(SetPasswordForm);

SetPasswordForm.propTypes = {
  changePassword: PropTypes.func,
  form: PropTypes.object.isRequired,
};
