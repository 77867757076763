import React, { Component } from 'react';
import { Row, Col, Card, Icon } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import 'antd/lib/card/style/css';
import 'antd/lib/modal/style/css';
import { Link } from 'react-router-dom';
import { H1, convertDateToFullStringDate, Breadcrumb, Modal } from '@utils';
import { PatientInfo, Readings, RequestMedicalRecordForm } from '@components';
import { consultationActions } from '@actions';

const { Meta } = Card;
const BackToConsolation = styled.div`
  margin-bottom: 30px;
  a {
    color: #000;
    font-size: 18px;
    span {
      margin-right: 10px;
    }
  }
`;
const MedicalReportWrapper = styled.div`
  h1 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .ant-col {
    padding: 0 10px;
  }
  .ant-card {
    display: inline-block;
    margin-bottom: 15px;
    img {
      width: 240px;
      height: 180px;
    }
    .ant-card-body {
      padding: 15px;
    }
    .ant-card-meta-title {
      font-size: 13px;
      margin-bottom: 5px;
      line-height: 1;
      color: #060c2b;
    }
    .ant-card-meta-description {
      font-size: 12px;
      color: #9298ac;
    }
  }
`;
const DescriptionWrapper = styled.div`
  background: #fff;
  padding: 20px;
  .time {
    font-size: 15px;
    color: #9298ac;
    margin-bottom: 7px;
  }
  .description {
    font-size: 16px;
    color: #060c2b;
    line-height: 2;
  }
`;

class MedicalReport extends Component {
  componentDidMount() {
    const { consultationId, getConsultation } = this.props;
    if (consultationId) {
      getConsultation(consultationId);
    }
  }
  state = {
    showImageModal: false,
    showRequestModal: false,
    modalTitle: '',
    modalImage: '',
  };

  handelShowImageModal = title => {
    this.setState({
      showImageModal: true,
      modalTitle: title,
    });
  };

  handelShowRequestModal = (title, image, scanType) => {
    this.setState({
      showRequestModal: true,
      modalTitle: title,
      modalImage: image,
      scanType,
    });
  };

  handelCloseModal = () => {
    this.setState({ showImageModal: false, showRequestModal: false });
  };

  render() {
    const {
      showImageModal,
      modalTitle,
      modalImage,
      scanType,
      showRequestModal,
    } = this.state;

    const {
      title,
      description,
      image,
      BreadcrumbLinks,
      updatedAt,
      consultationId,
      consultation,
      requestScan,
    } = this.props;

    const userType = localStorage.getItem('userType');
    return (
      <MedicalReportWrapper>
        <Row>
          <Col span={16}>
            {userType === 'consultant' && (
              <BackToConsolation>
                <Link to={`/consultant/consultation/${consultationId}`}>
                  <span>
                    <Icon type="arrow-left" />
                  </span>
                  Back to Consultation
                </Link>
              </BackToConsolation>
            )}
            <Breadcrumb className="bread-crumb" links={BreadcrumbLinks} />
            <H1>{title}</H1>
            {image && image.fileLocation && (
              <div>
                <Card
                  hoverable
                  style={{ width: 220 }}
                  cover={<img alt={image.fileName} src={image.fileLocation} />}
                  onClick={() =>
                    this.handelShowImageModal(
                      image.fileName
                        .replace('.jpg', '')
                        .replace('.jpeg', '')
                        .replace('.png', '')
                    )
                  }
                >
                  <Meta
                    title={image.fileName
                      .replace('.jpg', '')
                      .replace('.jpeg', '')
                      .replace('.png', '')}
                    description={convertDateToFullStringDate(updatedAt)}
                  />
                </Card>
              </div>
            )}
            <DescriptionWrapper>
              <p className="time">{convertDateToFullStringDate(updatedAt)}</p>
              <p className="description">{description}</p>
            </DescriptionWrapper>
          </Col>
          {userType === 'consultant' && consultation && (
            <Col span={8}>
              <PatientInfo patient={consultation.patient} />
              <Readings
                readings={consultation.readings}
                requestScan={requestScan}
                consultationId={consultationId}
                handelShowModal={this.handelShowRequestModal}
              />
            </Col>
          )}
        </Row>
        <Modal
          title={modalTitle}
          showModal={showImageModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            {image && image.fileLocation && (
              <img alt={image.fileName} src={image.fileLocation} />
            )}
          </>
        </Modal>
        <Modal
          title={modalTitle}
          image={modalImage}
          showModal={showRequestModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={requestScan}
              scanType={scanType}
              consultationId={consultation && consultation.id}
              handelCloseModal={this.handelCloseModal}
            />
          </>
        </Modal>
      </MedicalReportWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { consultation } = state.consultation;
  return {
    consultation,
  };
};

const mapDispatchToProps = dispatch => ({
  getConsultation: id => dispatch(consultationActions.getConsultation(id)),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

const WrappedViewConsultation = connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalReport);

export default WrappedViewConsultation;
