import { alertActions } from '@actions';
import { patientProfileServices } from '@services';
import patientProfileConstants from './types';

const getAddress = () => {
  return dispatch => {
    dispatch(getAddressRequest());
    patientProfileServices.getAddress().then(res => {
      if (res.error) {
        dispatch(getAddressFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAddressSuccess(res.address));
        dispatch(alertActions.clear());
      }
    });
  };
};

const addAddress = address => {
  return dispatch => {
    dispatch(addAddressRequest(address));

    patientProfileServices.addAddress(address).then(res => {
      if (res.error) {
        dispatch(addAddressFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(addAddressSuccess(res.address));
        dispatch(alertActions.success('Your Address has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateAddress = (address, id) => {
  return dispatch => {
    dispatch(updateAddressRequest(address));

    patientProfileServices.updateAddress(address, id).then(res => {
      if (res.error) {
        dispatch(updateAddressFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateAddressSuccess(res.address));
        dispatch(alertActions.success('Your Address has Updated'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getEmergency = () => {
  return dispatch => {
    dispatch(getEmergencyRequest());

    patientProfileServices.getEmergency().then(res => {
      if (res.error) {
        dispatch(getEmergencyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getEmergencySuccess(res.emergency));
        dispatch(alertActions.clear());
      }
    });
  };
};

const addEmergency = emergency => {
  return dispatch => {
    dispatch(addEmergencyRequest(emergency));

    patientProfileServices.addEmergency(emergency).then(res => {
      if (res.error) {
        dispatch(addEmergencyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(addEmergencySuccess(res.emergency));
        dispatch(alertActions.success('Your Emergency has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateEmergency = (emergency, id) => {
  return dispatch => {
    dispatch(updateEmergencyRequest(emergency));

    patientProfileServices.updateEmergency(emergency, id).then(res => {
      if (res.error) {
        dispatch(updateEmergencyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateEmergencySuccess(res.emergency));
        dispatch(alertActions.success('Your Emergency has Updated'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getMyActivity = () => {
  return dispatch => {
    dispatch(getMyActivityRequest());

    patientProfileServices.getMyActivity().then(res => {
      if (res.error) {
        dispatch(getMyActivityFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMyActivitySuccess(res.activity));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAddressRequest = () => ({
  type: patientProfileConstants.GET_ADDRESSES_REQUEST,
});
const getAddressSuccess = address => ({
  type: patientProfileConstants.GET_ADDRESSES_SUCCESS,
  address,
});
const getAddressFailure = error => ({
  type: patientProfileConstants.GET_ADDRESSES_FAILURE,
  error,
});

const addAddressRequest = address => ({
  type: patientProfileConstants.ADD_ADDRESSES_REQUEST,
  address,
});
const addAddressSuccess = address => ({
  type: patientProfileConstants.ADD_ADDRESSES_SUCCESS,
  address,
});
const addAddressFailure = error => ({
  type: patientProfileConstants.ADD_ADDRESSES_FAILURE,
  error,
});

const updateAddressRequest = address => ({
  type: patientProfileConstants.UPDATE_ADDRESSES_REQUEST,
  address,
});
const updateAddressSuccess = address => ({
  type: patientProfileConstants.UPDATE_ADDRESSES_SUCCESS,
  address,
});
const updateAddressFailure = error => ({
  type: patientProfileConstants.UPDATE_ADDRESSES_FAILURE,
  error,
});

const getEmergencyRequest = () => ({
  type: patientProfileConstants.GET_EMERGANCY_REQUEST,
});
const getEmergencySuccess = emergency => ({
  type: patientProfileConstants.GET_EMERGANCY_SUCCESS,
  emergency,
});
const getEmergencyFailure = error => ({
  type: patientProfileConstants.GET_EMERGANCY_FAILURE,
  error,
});

const addEmergencyRequest = emergency => ({
  type: patientProfileConstants.ADD_EMERGANCY_REQUEST,
  emergency,
});
const addEmergencySuccess = emergency => ({
  type: patientProfileConstants.ADD_EMERGANCY_SUCCESS,
  emergency,
});
const addEmergencyFailure = error => ({
  type: patientProfileConstants.ADD_EMERGANCY_FAILURE,
  error,
});

const updateEmergencyRequest = emergency => ({
  type: patientProfileConstants.UPDATE_EMERGANCY_REQUEST,
  emergency,
});
const updateEmergencySuccess = emergency => ({
  type: patientProfileConstants.UPDATE_EMERGANCY_SUCCESS,
  emergency,
});
const updateEmergencyFailure = error => ({
  type: patientProfileConstants.UPDATE_EMERGANCY_FAILURE,
  error,
});

const getMyActivityRequest = () => ({
  type: patientProfileConstants.GET_MY_ACTIVITY_REQUEST,
});
const getMyActivitySuccess = activity => ({
  type: patientProfileConstants.GET_MY_ACTIVITY_SUCCESS,
  activity,
});
const getMyActivityFailure = error => ({
  type: patientProfileConstants.GET_MY_ACTIVITY_FAILURE,
  error,
});

export const patientProfileActions = {
  getAddress,
  addAddress,
  updateAddress,
  getEmergency,
  addEmergency,
  updateEmergency,
  getMyActivity,
};
