const getReadings = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/readings`, requestOptions)
    .then(readings => {
      return { error: false, readings };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedReadings = (id, informationShareToken) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${id}/readings/?informationShareToken=${informationShareToken}`,
    requestOptions
  )
    .then(readings => {
      return { error: false, readings };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addReadings = reading => {
  const raw = JSON.stringify({
    type: reading.type,
    value: reading.value,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/readings`, requestOptions)
    .then(reading => {
      return { error: false, reading };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addMultiReadings = readings => {
  const raw = JSON.stringify({ readings: readings });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/readings/multi-actions`, requestOptions)
    .then(reading => {
      return { error: false, reading };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateReadings = (id, reading) => {
  const raw = JSON.stringify({
    type: reading.type,
    value: reading.value,
  });

  const requestOptions = {
    method: 'PATCH',
    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/readings/${id}`, requestOptions)
    .then(readings => {
      return { error: false, readings };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getReadings,
  getSharedReadings,
  addReadings,
  addMultiReadings,
  updateReadings,
};
