import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { ProfilePic } from '@utils';

const BookWrapper = styled.div`
  display: flex;
  padding: 20px 16px;
  background: #fff;
  border-bottom: solid 1px #e4e9f2;
`;
const BookImage = styled.div`
  margin-right: 12px;
  img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
`;
const BookDetails = styled.div`
  margin-left: 12px;
  p {
    margin-bottom: 0;
    &.book-name {
      font-size: 16px;
      font-weight: bold;
    }
    &.book-time {
      font-size: 16px;
      font-weight: normal;
      color: #9298ac;
    }
  }
`;

export default class Book extends Component {
  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    return (
      <BookWrapper className="book-wrapper">
        <BookImage>
          <ProfilePic user={user} />
        </BookImage>
        <BookDetails>
          <p className="book-name">Alan Martí</p>
          <p className="book-time">Today - 03:30PM</p>
        </BookDetails>
      </BookWrapper>
    );
  }
}
