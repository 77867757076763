import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { H2, Colors, WaitingToBeAssigned, ProfilePic } from '@utils';
import { Link } from 'react-router-dom';

const CartWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};

  background: #fff;
  a {
    width: 100%;
    height: 100%;
    padding: 16px;
    display: block;
  }
  .start-time {
    font-size: 16px;
    color: ${Colors.primaryColor};
    margin-bottom: 16px;
  }
`;
const AssignedWrapper = styled.footer`
  border-top: solid 1px ${Colors.gary};
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px 16px 0 16px;
`;

const ConsultantWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  p {
    margin-bottom: 0;
    line-height: 24px;
    vertical-align: middle;
    margin-left: 10px;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    color: #060c2b;
  }
`;

export default function ConsultantsCart(props) {
  const { id, className, title, assigned, startTime } = props;
  return (
    <CartWrapper className={className && className}>
      <Link to={`/my-consultationRequests/${id}`}>
        <H2>{title}</H2>
        <p className="start-time">Starting on: {startTime}</p>

        <AssignedWrapper>
          {assigned ? (
            <ConsultantWrapper>
              <ProfilePic user={assigned} />
              <p>
                DR.
                {assigned.name}
              </p>
            </ConsultantWrapper>
          ) : (
            <WaitingToBeAssigned />
          )}
        </AssignedWrapper>
      </Link>
    </CartWrapper>
  );
}

ConsultantsCart.propTypes = {
  title: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  assigned: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
};
