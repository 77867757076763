import fetchIntercept from 'fetch-intercept';
import { history } from '@utils';
const API_URL = process.env.REACT_APP_API_URL;

const unregister = fetchIntercept.register({
  request: (url, config) => {
    // Modify the url or config here
    const check = url.startsWith('https') || url.startsWith('http');
    const user = JSON.parse(localStorage.getItem('user'));
    if (!check) {
      const myHeaders = new Headers();

      myHeaders.append('X-Api-Key', 'du7lik773nspohl0');

      if (typeof config.body === 'string') {
        // remove this header if conig.body formdata
        myHeaders.append('Content-Type', 'application/json');
      }
      if (user) {
        myHeaders.append('Authorization', `Bearer ${user.token}`);
      }
      config.headers = myHeaders;
    }
    url = check ? url : `${API_URL}${url}`;
    return [url, config];
  },

  requestError: error => {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: response => {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        let error;
        switch (response.status) {
          case 401:
            // auto logout if 401 response returned from api
            const userType = localStorage.getItem('userType');
            const consultant =
              history.location.pathname === '/consultant/login' ||
              userType === 'consultant';
            const logIn =
              history.location.pathname === '/consultant/login' ||
              history.location.pathname === '/login';
            logout();
            consultant
              ? history.push('/consultant/login')
              : history.push('/login');
            error = `${
              logIn
                ? 'email or password is incorrect, please try again!'
                : 'Please login First'
            }`;
            break;

          case 12163:
            error = 'Please check you internet connection!';
            break;
          case 422:
            let errorMessage = '';
            Object.keys(data.errors).map(
              key => (errorMessage += `\n${data.errors[key]}`)
            );
            error = data.errors || response.statusText;
            break;
          case 403:
            error = data.message || response.statusText;
            break;
          default:
            error = data.message || response.statusText;

            break;
        }
        return Promise.reject(error);
      }
      return data || response;
    });
  },

  responseError: error => {
    console.log(error);
    if (typeof error === 'object') {
      error = 'CORS Error';
    }
    // Handle an fetch error
    return Promise.reject(error);
  },
});

export default unregister;

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
  localStorage.removeItem('userType');
};
