import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { H1, convertDateToFullStringDate } from '@utils';
import { patientReadingActions } from '@actions';
import {
  BloodPressure2X,
  BloodPressure,
  BloodSugar2X,
  BloodSugar,
  HeartRate,
  HeartRate2X,
  Height,
  Height2X,
  Temperature,
  Temperature2X,
  Weight,
  Weight2X,
} from '@images';

const ReadingsWrapper = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  h1 {
    color: #060c2b;
    margin-bottom: 20px;
  }
`;
const RecordWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const Record = styled.div`
  width: 32%;
  padding: 15px;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;

  &:first-of-type,
  &:nth-of-type(2),
  &:nth-of-type(3) {
    margin-bottom: 20px;
  }
  .img-wrapper {
    width: 103px;
    height: 73px;
    margin: auto;
    margin-bottom: 10px;
  }
  h3 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 22.5px;
    font-weight: bold;
    color: #5cb3db;
    margin-bottom: 10px;
  }

  p {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 12px;
    color: #9298ac;
    margin: 0;
    &:first-of-type {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 10px;

      color: #060c2b;
      span {
        font-weight: 600;
      }
    }
  }
`;

class Readings extends Component {
  componentDidMount() {
    const { getReadings } = this.props;
    getReadings();
  }

  switchReadings = reading => {
    const logTime = convertDateToFullStringDate(reading.updatedAt);

    switch (reading.type) {
      case 'blood-pressure':
        return (
          <Record key="blood-pressure">
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${BloodPressure2X} 2x`} />
                <img src={BloodPressure} alt="Blood Pressure" />
              </picture>
            </div>
            <h3>Blood Pressure</h3>
            <p>
              <span>{reading.value}</span> mmHg
            </p>
            <p>{logTime}</p>
          </Record>
        );
      case 'weight':
        return (
          <Record key="weight">
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${Weight2X} 2x`} />
                <img src={Weight} alt="Weight" />
              </picture>
            </div>
            <h3>Weight</h3>
            <p>
              <span>{reading.value}</span> KG
            </p>
            <p>{logTime}</p>
          </Record>
        );

      case 'blood-sugar':
        return (
          <Record key="blood-sugar">
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${BloodSugar2X} 2x`} />
                <img src={BloodSugar} alt="Blood Sugar" />
              </picture>
            </div>
            <h3>Blood Sugar</h3>
            <p>
              <span>{reading.value}</span> mg/dL
            </p>
            <p>{logTime}</p>
          </Record>
        );

      case 'heart-rate':
        return (
          <Record key="heart-rate">
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${HeartRate2X} 2x`} />
                <img src={HeartRate} alt="Heart Rate" />
              </picture>
            </div>
            <h3>Heart Rate</h3>
            <p>
              <span>{reading.value}</span> BPM
            </p>
            <p>{logTime}</p>
          </Record>
        );

      case 'temperature':
        return (
          <Record key="temperature">
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${Temperature2X} 2x`} />
                <img src={Temperature} alt="Temperature" />
              </picture>
            </div>
            <h3>Temperature</h3>
            <p>
              <span>{reading.value}</span> °C
            </p>
            <p>{logTime}</p>
          </Record>
        );

      case 'height':
        return (
          <Record key="height">
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${Height2X} 2x`} />
                <img src={Height} alt="Height" />
              </picture>
            </div>
            <h3>Height</h3>
            <p>
              <span>{reading.value}</span> CM
            </p>
            <p>{logTime}</p>
          </Record>
        );
      default:
        break;
    }
  };
  render() {
    const { readings } = this.props;
    return (
      <ReadingsWrapper>
        <H1>Readings</H1>
        {readings && (
          <RecordWrapper>
            {readings.map(reading => this.switchReadings(reading))}
          </RecordWrapper>
        )}
      </ReadingsWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { readings } = state.readings;
  return {
    readings,
  };
};

const mapDispatchToProps = dispatch => ({
  getReadings: () => dispatch(patientReadingActions.getReadings()),
});

Readings.propTypes = {
  getReadings: PropTypes.func.isRequired,
  readings: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Readings);
