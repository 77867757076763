import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from 'antd/lib/layout';
import styled from '@emotion/styled/macro';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { Header, Footer, Container, MainFooter } from '@components';
import { Logo, Logo2X, ReportIcon, HouseIcon } from '@images';
const { Content } = Layout;

const ConsultantLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    color: #9298ac;
    span {
      margin-left: 10px;
    }
    & + a {
      margin-left: 20px;
    }
  }
`;
class BaseLayout extends Component {
  state = {};
  static getDerivedStateFromProps({ alert }, state) {
    switch (alert.type) {
      case 'success':
        NotificationManager.success(alert.message);
        break;
      case 'warning':
        NotificationManager.warning(alert.message);
        break;
      case 'error':
        NotificationManager.error(alert.message);
        break;
      case 'info':
        NotificationManager.info(alert.message);
        break;
      default:
        break;
    }
    return {};
  }
  render() {
    const userType = localStorage.getItem('userType');
    const { navigation, children, footer, ReportPage } = this.props;
    return (
      <div>
        <Header>
          <Container>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Link to="/">
                <picture>
                  <source srcSet={`${Logo2X} 2x`} />
                  <img src={Logo} alt="Holoden Khight Logo" />
                </picture>
              </Link>
              {userType === 'consultant' && (
                <ConsultantLinksWrapper>
                  <Link to="/">
                    <HouseIcon color={ReportPage ? '#9298ac' : '#5cb3db'} />
                    <span
                      style={
                        ReportPage ? { color: '#9298ac' } : { color: '#5cb3db' }
                      }
                    >
                      Consultation
                    </span>
                  </Link>
                  <Link to="/reports">
                    <ReportIcon color={ReportPage ? '#5cb3db' : '#9298ac'} />
                    <span
                      style={
                        ReportPage ? { color: '#5cb3db' } : { color: '#9298ac' }
                      }
                    >
                      Report
                    </span>
                  </Link>
                </ConsultantLinksWrapper>
              )}
              {navigation}
            </div>
          </Container>
        </Header>
        <Content
          style={{
            padding: '3em 10em',
            margin: 0,
            minHeight: 280,
            backgroundColor: '#f5f5f5',
          }}
        >
          {children}
        </Content>
        {footer === 'main' ? (
          <MainFooter />
        ) : (
          <Footer>
            <p>Phantasm &copy; 2020</p>
          </Footer>
        )}

        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { alert } = state;
  return {
    alert,
  };
};

const connectedBaseLayout = connect(mapStateToProps)(BaseLayout);
export default connectedBaseLayout;

BaseLayout.propTypes = {
  navigation: PropTypes.object,
  children: PropTypes.object,
  alert: PropTypes.object,
  footer: PropTypes.string,
  ReportPage: PropTypes.bool,
};
