import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { AddressInfoForm } from '@components';

export class AddressInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFormIsOpen: false,
    };
  }

  componentDidMount() {
    const { getAddress } = this.props;
    getAddress();
  }

  handleEditFormToggle = () => {
    this.setState({ editFormIsOpen: !this.state.editFormIsOpen });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      address,
      updateAddress,
      addAddress,
      form: { validateFields },
    } = this.props;
    const { editFormIsOpen } = this.state;
    const id = address && address.id;
    validateFields((err, values) => {
      if (!err) {
        address && address.status !== 204
          ? updateAddress(values, id)
          : addAddress(values);

        this.setState({ editFormIsOpen: !editFormIsOpen });
      }
    });
  };

  handleCancel = e => {
    e.preventDefault();
    const {
      form: { setFields },
      address,
    } = this.props;
    const { editFormIsOpen } = this.state;

    setFields({
      country: {
        value: address && address.country,
      },
      city: {
        value: address && address.city,
      },
      street: {
        value: address && address.street,
      },
    });
    this.setState({ editFormIsOpen: !editFormIsOpen });
  };

  render() {
    const { form, address } = this.props;
    const { editFormIsOpen } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <AddressInfoForm
          form={form}
          address={address}
          editFormIsOpen={editFormIsOpen}
          handleEditFormToggle={this.handleEditFormToggle}
          handleCancel={this.handleCancel}
        />
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { address } = state.profile;
  return {
    address,
  };
};

const WrappedAddressInfo = Form.create({ name: 'user_info_form' })(
  connect(mapStateToProps, {})(AddressInfo)
);

export default WrappedAddressInfo;

AddressInfo.propTypes = {
  address: PropTypes.object,
  form: PropTypes.object,
  addAddress: PropTypes.func,
  updateAddress: PropTypes.func,
  getAddress: PropTypes.func,
};
