import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Colors } from './Theme';

const Title = styled.h2`
  font-family: AvenirNext, Arial, sans-serif;
  font-size: 18px;
  color: ${Colors.black};
  font-weight: ${props => (props.weight ? `${props.weight}` : 'bold')};
  margin: 0;
`;

export default function TitleWrapper(props) {
  return <Title {...props}>{props.children}</Title>;
}

TitleWrapper.propTypes = {
  children: PropTypes.string,
  weight: PropTypes.string,
};
