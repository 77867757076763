import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Icon, Form, Row, Col, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import 'antd/lib/icon/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/upload/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';

const FormItem = Form.Item;
const { Option } = Select;
const AddressInfoWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5cb3db;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5cb3db;
    line-height: 24px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
`;

const AddressInfoContentWrapper = styled.div`
  .ant-form {
    width: 100%;
  }
  .img-upload-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    img {
      display: block;
    }
  }
`;

const AddressInfoContent = styled.div`
  width: 100%;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
    &:after {
      content: none;
    }
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #000;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
`;

const AddressWrapper = styled.div`
  margin-top: 20px;
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
`;

export default class AddressInfoForm extends Component {
  state = {
    countries: null,
  };
  componentDidMount() {
    this.getCountries();
  }
  getCountries = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
    };
    return fetch('https://restcountries.eu/rest/v2/all', requestOptions)
      .then(result => this.setState({ countries: result }))
      .catch(error => console.log('error', error));
  };
  render() {
    const {
      form: { getFieldDecorator },
      address,
      editFormIsOpen,
      handleEditFormToggle,
      handleCancel,
    } = this.props;
    const { countries } = this.state;

    return (
      <div>
        <AddressWrapper className="profile-border">
          <AddressInfoWrapper>
            <h2>My Address</h2>
            {handleEditFormToggle ? (
              editFormIsOpen ? (
                <div>
                  <FormItem>
                    <button htmltype="submit" className="save">
                      Save
                    </button>
                  </FormItem>
                  <FormItem>
                    <button
                      htmltype="button"
                      className="close"
                      onClick={handleCancel}
                    >
                      &times;
                    </button>
                  </FormItem>
                </div>
              ) : (
                <button
                  className="ant-dropdown-link"
                  onClick={ev => {
                    ev.preventDefault();
                    handleEditFormToggle();
                  }}
                >
                  {address && address.status !== 204 ? (
                    <Icon type="edit" theme="filled" />
                  ) : (
                    <Icon type="plus" />
                  )}
                </button>
              )
            ) : (
              <></>
            )}
          </AddressInfoWrapper>
          {(address && address.status !== 204) || editFormIsOpen === true ? (
            <AddressInfoContentWrapper>
              <AddressInfoContent>
                <Row>
                  <Col span={12}>
                    {countries ? (
                      <FormItem label={'Country'}>
                        {getFieldDecorator('country', {
                          initialValue: address && address.country,
                        })(
                          <Select
                            placeholder="Country"
                            disabled={!editFormIsOpen && true}
                          >
                            {countries.map(country => (
                              <Option
                                key={country.numericCode}
                                value={country.name}
                              >
                                {country.name}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    ) : (
                      <FormItem label={'Country'}>
                        {getFieldDecorator('country', {
                          initialValue: address && address.country,
                        })(<Input disabled={!editFormIsOpen && true} />)}
                      </FormItem>
                    )}
                  </Col>
                  <Col span={12}>
                    <FormItem label={'City'}>
                      {getFieldDecorator('city', {
                        initialValue: address && address.city,
                      })(<Input disabled={!editFormIsOpen && true} />)}
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem label={'Street'}>
                      {getFieldDecorator('street', {
                        initialValue: address && address.street,
                      })(<Input disabled={!editFormIsOpen && true} />)}
                    </FormItem>
                  </Col>
                </Row>
              </AddressInfoContent>
            </AddressInfoContentWrapper>
          ) : (
            <div></div>
          )}
        </AddressWrapper>
      </div>
    );
  }
}

AddressInfoForm.propTypes = {
  address: PropTypes.object,
  form: PropTypes.object.isRequired,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleEditFormToggle: PropTypes.func,
  handleCancel: PropTypes.func,
  countries: PropTypes.array,
};
