import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { patientConsultationsActions } from '@actions';
import { ConsultantsCart, H1, convertDateToFullStringDate } from '@utils';

import { MainLayout, Container } from '@components';

const ConsultationWrapper = styled.div`
  max-width: 75%;
  margin-left: 0;
  .title-wrapper {
    width: 100;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .consultation-cart + .consultation-cart {
    margin-top: 15px;
  }
`;

class MyAllConsultants extends Component {
  componentDidMount() {
    const { getMyConsultationRequests } = this.props;
    getMyConsultationRequests();
  }
  render() {
    const { consultationRequests } = this.props;

    return (
      <MainLayout footer="main">
        <Container>
          <ConsultationWrapper>
            <div className="title-wrapper">
              <H1>All Consultants</H1>
            </div>
            <div>
              {consultationRequests &&
                consultationRequests.data.map(consultation => (
                  <ConsultantsCart
                    key={JSON.stringify(consultation)}
                    id={consultation.id}
                    title={consultation.symptoms}
                    assigned={consultation.consultant}
                    startTime={convertDateToFullStringDate(
                      consultation.createdAt
                    )}
                    className="consultation-cart"
                  />
                ))}
            </div>
          </ConsultationWrapper>
        </Container>
      </MainLayout>
    );
  }
}

const mapStateToProps = state => {
  const { consultationRequests } = state.consultations;

  return {
    consultationRequests,
  };
};

const mapDispatchToProps = dispatch => ({
  getMyConsultationRequests: () =>
    dispatch(patientConsultationsActions.getMyConsultationRequests()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAllConsultants);

MyAllConsultants.propTypes = {
  getMyConsultationRequests: PropTypes.func.isRequired,
  consultationRequests: PropTypes.object,
};
