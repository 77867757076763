/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const SwitchOffIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '24'}px`}
      width={`${width || '24'}px`}
      viewBox="0 0 24 24"
      css={css`
        fill: ${color || '#ec0707'};
        max-width: ${width || '24'}px;
        max-height: ${height || '24'}px;
        height: ${height || '24'}px;
        width: ${width || '24'}px;
      `}
    >
      <g id="surface1">
        <path d="M 16.398438 2.914062 C 15.894531 2.695312 15.304688 2.925781 15.082031 3.429688 C 14.863281 3.9375 15.09375 4.527344 15.597656 4.75 C 18.851562 6.167969 21 9.386719 21 13 C 21 17.96875 16.96875 22 12 22 C 7.027344 22 3 17.96875 3 13 C 3 9.386719 5.148438 6.171875 8.398438 4.75 C 8.90625 4.527344 9.136719 3.9375 8.914062 3.429688 C 8.695312 2.925781 8.105469 2.695312 7.597656 2.914062 C 3.625 4.652344 1 8.585938 1 13 C 1 19.074219 5.925781 24 12 24 C 18.074219 24 23 19.074219 23 13 C 23 8.585938 20.375 4.652344 16.398438 2.914062 Z M 16.398438 2.914062 " />
        <path d="M 12 11 C 12.550781 11 13 10.550781 13 10 L 13 1 C 13 0.449219 12.550781 0 12 0 C 11.449219 0 11 0.449219 11 1 L 11 10 C 11 10.550781 11.449219 11 12 11 Z M 12 11 " />
      </g>
    </svg>
  );
};

export default SwitchOffIcon;

SwitchOffIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
