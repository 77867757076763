import { alertActions } from '@actions';
import { patientConsultationsServices } from '@services';
import patientConsultationsConstants from './types';

const getMyConsultationRequests = () => {
  return dispatch => {
    dispatch(getMyConsultationRequestsRequest());

    patientConsultationsServices.getMyConsultationRequests().then(res => {
      if (res.error) {
        dispatch(getMyConsultationRequestsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMyConsultationRequestsSuccess(res.consultationRequests));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSingleConsultation = id => {
  return dispatch => {
    dispatch(getSingleConsultationRequest());

    patientConsultationsServices.getSingleConsultation(id).then(res => {
      if (res.error) {
        dispatch(getSingleConsultationFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getSingleConsultationSuccess(res.consultation));
        dispatch(alertActions.clear());
      }
    });
  };
};

const addConsultationRequest = consultation => {
  return dispatch => {
    dispatch(addConsultationRequestRequest());

    patientConsultationsServices
      .addConsultationRequest(consultation)
      .then(res => {
        if (res.error) {
          dispatch(addConsultationRequestFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(addConsultationRequestSuccess(res.consultation));
          dispatch(alertActions.clear());
        }
      });
  };
};
const getMyConsultationRequestsRequest = () => ({
  type: patientConsultationsConstants.GET_MY_CONSULTATION_REQUEST,
});
const getMyConsultationRequestsSuccess = consultationRequests => ({
  type: patientConsultationsConstants.GET_MY_CONSULTATION_SUCCESS,
  consultationRequests,
});
const getMyConsultationRequestsFailure = error => ({
  type: patientConsultationsConstants.GET_MY_CONSULTATION_FAILURE,
  error,
});

const getSingleConsultationRequest = () => ({
  type: patientConsultationsConstants.GET_SINGLE_CONSULTATION_REQUEST,
});
const getSingleConsultationSuccess = consultation => ({
  type: patientConsultationsConstants.GET_SINGLE_CONSULTATION_SUCCESS,
  consultation,
});
const getSingleConsultationFailure = error => ({
  type: patientConsultationsConstants.GET_SINGLE_CONSULTATION_FAILURE,
  error,
});

const addConsultationRequestRequest = () => ({
  type: patientConsultationsConstants.ADD_CONSULTATION_REQUEST,
});
const addConsultationRequestSuccess = consultation => ({
  type: patientConsultationsConstants.ADD_CONSULTATION_SUCCESS,
  consultation,
});
const addConsultationRequestFailure = error => ({
  type: patientConsultationsConstants.ADD_CONSULTATION_FAILURE,
  error,
});

export const patientConsultationsActions = {
  getMyConsultationRequests,
  getSingleConsultation,
  addConsultationRequest,
};
