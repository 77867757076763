const paymentConstants = {
  MAK_PAYMENT_REQUEST: 'MAK_PAYMENT_REQUEST',
  MAK_PAYMENT_SUCCESS: 'MAK_PAYMENT_SUCCESS',
  MAK_PAYMENT_FAILURE: 'MAK_PAYMENT_FAILURE',
  GET_CARDS_LIST_REQUEST: 'GET_CARDS_LIST_REQUEST',
  GET_CARDS_LIST_SUCCESS: 'GET_CARDS_LIST_SUCCESS',
  GET_CARDS_LIST_FAILURE: 'GET_CARDS_LIST_FAILURE',
  DELETE_CARD_REQUEST: 'DELETE_CARD_REQUEST',
  DELETE_CARD_SUCCESS: 'DELETE_CARD_SUCCESS',
  DELETE_CARD_FAILURE: 'DELETE_CARD_FAILURE',
  GET_CLIENT_SECRET_REQUEST: 'GET_CLIENT_SECRET_REQUEST',
  GET_CLIENT_SECRET_SUCCESS: 'GET_CLIENT_SECRET_SUCCESS',
  GET_CLIENT_SECRET_FAILURE: 'GET_CLIENT_SECRET_FAILURE',
  GET_FAILED_PAYMENT_REQUEST: 'GET_FAILED_PAYMENT_REQUEST',
  GET_FAILED_PAYMENT_SUCCESS: 'GET_FAILED_PAYMENT_SUCCESS',
  GET_FAILED_PAYMENT_FAILURE: 'GET_FAILED_PAYMENT_FAILURE',
};

export default paymentConstants;
