import React from 'react';
import { connect } from 'react-redux';

import { BaseLayout, RegistrationForm, FullPage, Container } from '@components';
import { patientActions } from '@actions';

const Signup = props => (
  <BaseLayout>
    <FullPage>
      <Container>
        <RegistrationForm {...props} />
      </Container>
    </FullPage>
  </BaseLayout>
);

const mapStateToProps = state => {
  const { errors, user } = state.authentication;
  return {
    errors,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  register: user => dispatch(patientActions.register(user)),
});

const connectedSignupPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
export default connectedSignupPage;
