import { patientConditionsConstants } from '@actions/types';

const initialState = {};

export function condition(state = initialState, action) {
  switch (action.type) {
    case patientConditionsConstants.GET_CONDITIONS_REQUEST:
      return {
        ...state,
        fetchingConditions: true,
      };
    case patientConditionsConstants.GET_CONDITIONS_SUCCESS:
      return {
        fetchedConditions: true,
        conditions: action.conditions,
      };
    case patientConditionsConstants.GET_CONDITIONS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientConditionsConstants.GET_CONDITION_REQUEST:
      return {
        ...state,
        fetchingCondition: true,
      };
    case patientConditionsConstants.GET_CONDITION_SUCCESS:
      return {
        fetchedCondition: true,
        condition: action.condition,
      };
    case patientConditionsConstants.GET_CONDITION_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientConditionsConstants.GET_PATIENT_CONDITION_REQUEST:
      return {
        ...state,
        fetchingpatientCondition: true,
      };
    case patientConditionsConstants.GET_PATIENT_CONDITION_SUCCESS:
      return {
        fetchedpatientCondition: true,
        condition: action.condition,
      };
    case patientConditionsConstants.GET_PATIENT_CONDITION_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientConditionsConstants.GET_SHARED_CONDITIONS_REQUEST:
      return {
        ...state,
        fetchingConditions: true,
      };
    case patientConditionsConstants.GET_SHARED_CONDITIONS_SUCCESS:
      return {
        fetchedConditions: true,
        conditions: action.conditions,
      };
    case patientConditionsConstants.GET_SHARED_CONDITIONS_FAILURE:
      return {
        error: action.error,
      };
    case patientConditionsConstants.GET_SHARED_CONDITION_REQUEST:
      return {
        ...state,
        fetchingSharedCondition: true,
      };
    case patientConditionsConstants.GET_SHARED_CONDITION_SUCCESS:
      return {
        fetchedSharedCondition: true,
        condition: action.condition,
      };
    case patientConditionsConstants.GET_SHARED_CONDITION_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientConditionsConstants.ADD_CONDITIONS_REQUEST:
      return {
        ...state,
        addConditions: true,
      };
    case patientConditionsConstants.ADD_CONDITIONS_SUCCESS:
      return {
        ...state,
        addedConditions: true,
        condition: action.condition,
      };
    case patientConditionsConstants.ADD_CONDITIONS_FAILURE:
      return {
        error: action.error,
      };
    case patientConditionsConstants.UPDATE_CONDITIONS_REQUEST:
      return {
        ...state,
        updateConditions: true,
      };
    case patientConditionsConstants.UPDATE_CONDITIONS_SUCCESS:
      return {
        ...state,
        updateConditions: true,
        condition: action.condition,
      };
    case patientConditionsConstants.UPDATE_CONDITIONS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
