import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientConditionActions } from '@actions';
import { Container, MedicalReport } from '@components';
class Condition extends Component {
  componentDidMount() {
    const {
      getCondition,
      getPatientCondition,
      getSharedCondition,
      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (consultationId && id) {
      getCondition(id);
    } else if (id && !consultationId && !sharedToken) {
      getPatientCondition(id);
    } else if (id && sharedToken && patientId) {
      getSharedCondition(patientId, id, sharedToken);
    }
  }
  render() {
    const {
      condition,
      match: {
        params: { consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
          {
            item: 'Conditions',
            url: `/consultation/${consultationId}/conditions/${patientId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
          {
            item: 'Conditions',
            url: `/conditions/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : [
          { item: 'Medical Record ', url: '/#MedicalRecord' },
          {
            item: 'Conditions',
            url: `/conditions`,
          },
        ];

    return (
      <Container>
        {condition && (
          <MedicalReport
            title={condition.value}
            updatedAt={condition.updatedAt}
            description={condition.description}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { condition } = state.condition;
  const { consultation } = state.consultation;
  return {
    condition,
    consultation,
  };
};

const mapDispatchToProps = dispatch => ({
  getCondition: id => dispatch(patientConditionActions.getCondition(id)),
  getPatientCondition: id =>
    dispatch(patientConditionActions.getPatientCondition(id)),
  getSharedCondition: (patientId, id, informationShareToken) =>
    dispatch(
      patientConditionActions.getSharedCondition(
        patientId,
        id,
        informationShareToken
      )
    ),
});

Condition.propTypes = {
  getCondition: PropTypes.func.isRequired,
  condition: PropTypes.object,
  match: PropTypes.object.isRequired,
  getPatientCondition: PropTypes.func.isRequired,
  getSharedCondition: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Condition);
