import { alertActions } from '@actions';
import { patientMedicalReportsServices } from '@services';
import patientReportsConstants from './types';
import { history } from '@utils';

const getMedicalReports = page => {
  return dispatch => {
    dispatch(getMedicalReportsRequest());
    patientMedicalReportsServices.getMedicalReports(page).then(res => {
      if (res.error) {
        dispatch(getMedicalReportsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMedicalReportsSuccess(res.medicalReports));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getMedicalReport = id => {
  return dispatch => {
    dispatch(getMedicalReportRequest());
    patientMedicalReportsServices.getMedicalReport(id).then(res => {
      if (res.error) {
        dispatch(getMedicalReportFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMedicalReportSuccess(res.medicalReport));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getPatientMedicalReport = id => {
  return dispatch => {
    dispatch(getPatientMedicalReportRequest());
    patientMedicalReportsServices.getPatientMedicalReport(id).then(res => {
      if (res.error) {
        dispatch(getPatientMedicalReportFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientMedicalReportSuccess(res.medicalReport));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedMedicalReports = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedMedicalReportsRequest());
    patientMedicalReportsServices
      .getSharedMedicalReports(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedMedicalReportsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedMedicalReportsSuccess(res.medicalReports));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getSharedMedicalReport = (patientId, id, informationShareToken) => {
  return dispatch => {
    dispatch(getSharedMedicalReportRequest());
    patientMedicalReportsServices
      .getSharedMedicalReport(patientId, id, informationShareToken)
      .then(res => {
        if (res.error) {
          dispatch(getSharedMedicalReportFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedMedicalReportSuccess(res.medicalReport));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addMedicalReports = MedicalReport => {
  return dispatch => {
    dispatch(addMedicalReportsRequest(MedicalReport));

    patientMedicalReportsServices.addMedicalReports(MedicalReport).then(res => {
      if (res.error) {
        dispatch(addMedicalReportsFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addMedicalReportsSuccess(res.condition));
        dispatch(alertActions.success('Your Medical Report has Added'));
        dispatch(alertActions.clear());
        const RequestConsultation = new URLSearchParams(
          history.location.search
        ).get('RequestConsultation');
        if (RequestConsultation) {
          history.push('/medical-reports?RequestConsultation=1');
        } else {
          history.push('/medical-reports');
        }
      }
    });
  };
};

const updateMedicalReports = (id, MedicalReport) => {
  return dispatch => {
    dispatch(updateMedicalReportsRequest(MedicalReport));

    patientMedicalReportsServices
      .updateMedicalReports(id, MedicalReport)
      .then(res => {
        if (res.error) {
          dispatch(updateMedicalReportsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(updateMedicalReportsSuccess(res));
          dispatch(alertActions.success('Your Conditions has Updateed'));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getMedicalReportsRequest = () => ({
  type: patientReportsConstants.GET_REPORTS_REQUEST,
});
const getMedicalReportsSuccess = medicalReports => ({
  type: patientReportsConstants.GET_REPORTS_SUCCESS,
  medicalReports,
});
const getMedicalReportsFailure = error => ({
  type: patientReportsConstants.GET_REPORTS_FAILURE,
  error,
});

const getMedicalReportRequest = () => ({
  type: patientReportsConstants.GET_REPORT_REQUEST,
});
const getMedicalReportSuccess = medicalReport => ({
  type: patientReportsConstants.GET_REPORT_SUCCESS,
  medicalReport,
});
const getMedicalReportFailure = error => ({
  type: patientReportsConstants.GET_REPORT_FAILURE,
  error,
});

const getPatientMedicalReportRequest = () => ({
  type: patientReportsConstants.GET_PATIENT_REPORT_REQUEST,
});
const getPatientMedicalReportSuccess = medicalReport => ({
  type: patientReportsConstants.GET_PATIENT_REPORT_SUCCESS,
  medicalReport,
});
const getPatientMedicalReportFailure = error => ({
  type: patientReportsConstants.GET_PATIENT_REPORT_FAILURE,
  error,
});

const getSharedMedicalReportsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_REPORTS_REQUEST,
});
const getSharedMedicalReportsSuccess = medicalReports => ({
  type: patientReportsConstants.GET_SHARED_REPORTS_SUCCESS,
  medicalReports,
});
const getSharedMedicalReportsFailure = error => ({
  type: patientReportsConstants.GET_SHARED_REPORTS_FAILURE,
  error,
});

const getSharedMedicalReportRequest = () => ({
  type: patientReportsConstants.GET_SHARED_REPORT_REQUEST,
});
const getSharedMedicalReportSuccess = medicalReport => ({
  type: patientReportsConstants.GET_SHARED_REPORT_SUCCESS,
  medicalReport,
});
const getSharedMedicalReportFailure = error => ({
  type: patientReportsConstants.GET_SHARED_REPORT_FAILURE,
  error,
});

const addMedicalReportsRequest = medicalReport => ({
  type: patientReportsConstants.ADD_REPORTS_REQUEST,
  medicalReport,
});
const addMedicalReportsSuccess = medicalReport => ({
  type: patientReportsConstants.ADD_REPORTS_SUCCESS,
  medicalReport,
});
const addMedicalReportsFailure = error => ({
  type: patientReportsConstants.ADD_REPORTS_FAILURE,
  error,
});

const updateMedicalReportsRequest = medicalReport => ({
  type: patientReportsConstants.UPDATE_REPORTS_REQUEST,
  medicalReport,
});
const updateMedicalReportsSuccess = medicalReport => ({
  type: patientReportsConstants.UPDATE_REPORTS_SUCCESS,
  medicalReport,
});
const updateMedicalReportsFailure = error => ({
  type: patientReportsConstants.UPDATE_REPORTS_FAILURE,
  error,
});

export const patientMedicalReportsActions = {
  getMedicalReports,
  getMedicalReport,
  getPatientMedicalReport,
  getSharedMedicalReports,
  getSharedMedicalReport,
  addMedicalReports,
  updateMedicalReports,
};
