import React, { Component } from 'react';
import { Lab2X, Lab } from '@images';
import styled from '@emotion/styled/macro';
import { convertDateToFullStringDate } from '@utils';

const ActionWrappper = styled.div`
  display: flex;
  padding: 15px;
  background: #fff;
  margin-bottom: 11px;
  border-radius: 7.2px;
  box-shadow: 1px 1px 2px 1px rgba(3, 3, 4, 0);
  border: solid 1px #f1f1f1;
  .action {
    &__image {
      margin-right: 10px;
    }
    &__title {
      font-size: 20px;
      font-weight: bold;
      color: #060c2b;
      margin-bottom: 5px;
    }
    &__des {
      font-size: 18px;
      color: #9298ac;
      margin-bottom: 15px;
    }
    &__time {
      font-size: 16px;
      color: #9298ac;
      margin-bottom: 0;
    }
  }
`;

export default class Action extends Component {
  render() {
    const { text, time } = this.props;
    return (
      <ActionWrappper>
        <div className="action__image">
          <picture>
            <source srcSet={`${Lab2X} 2x`} />
            <img src={Lab} alt="Lab" />
          </picture>
        </div>
        <div className="action__details">
          <h2 className="action__title">{text}</h2>

          <p className="action__time">{convertDateToFullStringDate(time)}</p>
        </div>
      </ActionWrappper>
    );
  }
}
