import { patientReadingsConstants } from '@actions/types';

const initialState = {};

export function readings(state = initialState, action) {
  switch (action.type) {
    case patientReadingsConstants.GET_READINGS_REQUEST:
      return {
        ...state,
        fetchingReadings: true,
      };
    case patientReadingsConstants.GET_READINGS_SUCCESS:
      return {
        fetchedReadings: true,
        readings: action.readings,
      };
    case patientReadingsConstants.GET_READINGS_FAILURE:
      return {
        error: action.error,
      };
    case patientReadingsConstants.GET_SHARED_READINGS_REQUEST:
      return {
        ...state,
        fetchingReadings: true,
      };
    case patientReadingsConstants.GET_SHARED_READINGS_SUCCESS:
      return {
        fetchedReadings: true,
        readings: action.readings,
      };
    case patientReadingsConstants.GET_SHARED_READINGS_FAILURE:
      return {
        error: action.error,
      };
    case patientReadingsConstants.ADD_READINGS_REQUEST:
      return {
        ...state,
        addReadings: true,
      };
    case patientReadingsConstants.ADD_READINGS_SUCCESS:
      return {
        ...state,
        addedReadings: true,
        reading: action.reading,
      };
    case patientReadingsConstants.ADD_READINGS_FAILURE:
      return {
        error: action.error,
      };
    case patientReadingsConstants.ADD_MULTI_READINGS_REQUEST:
      return {
        ...state,
        addMultiReadings: true,
      };
    case patientReadingsConstants.ADD_MULTI_READINGS_SUCCESS:
      return {
        ...state,
        addedMultiReadings: true,
        readings: action.readings,
      };
    case patientReadingsConstants.ADD_MULTI_READINGS_FAILURE:
      return {
        error: action.error,
      };
    case patientReadingsConstants.UPDATE_READINGS_REQUEST:
      return {
        ...state,
        updateReadings: true,
      };
    case patientReadingsConstants.UPDATE_READINGS_SUCCESS:
      return {
        ...state,
        updateReadings: true,
        reading: action.reading,
      };
    case patientReadingsConstants.UPDATE_READINGS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
