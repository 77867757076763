/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

/** icon for logout used mainly in menu */
const HomeIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="22"
      viewBox="0 0 26 22"
      css={css`
        fill: ${color || '#FFF'};
        max-width: 100%;
        height: 26px;
        width: 22px;
      `}
    >
      <text
        fill="#9298AC"
        fillRule="evenodd"
        fontFamily="Material-Design-Icons, Material Design Icons"
        fontSize="30"
        transform="translate(-11 -5)"
      >
        <tspan x="9" y="27">
          
        </tspan>
      </text>
    </svg>
  );
};

export default HomeIcon;

HomeIcon.propTypes = {
  /** color for svg */
  color: PropTypes.string,
};
