import { alertActions } from '@actions';
import { consultantSchedulesServices } from '@services';
import schedulesConstants from './types';

const getSchedules = () => {
  return dispatch => {
    dispatch(getSchedulesRequest());

    consultantSchedulesServices.getSchedules().then(res => {
      if (res.error) {
        dispatch(getSchedulesFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(getSchedulesSuccess(res.schedules));
        dispatch(alertActions.clear());
      }
    });
  };
};

const addSchedules = schedules => {
  return dispatch => {
    dispatch(addSchedulesRequest());

    consultantSchedulesServices.addSchedules(schedules).then(res => {
      if (res.error) {
        dispatch(addSchedulesFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(addSchedulesSuccess(res.schedules));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateSchedules = schedule => {
  return dispatch => {
    dispatch(updateSchedulesRequest());

    consultantSchedulesServices.updateSchedules(schedule).then(res => {
      if (res.error) {
        dispatch(updateSchedulesFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateSchedulesSuccess(res.schedule));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSchedulesRequest = () => ({
  type: schedulesConstants.GET_SCHEDULES_REQUEST,
});

const getSchedulesSuccess = schedules => {
  return {
    type: schedulesConstants.GET_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

const getSchedulesFailure = error => ({
  type: schedulesConstants.GET_SCHEDULES_FAILURE,
  error,
});

const addSchedulesRequest = () => ({
  type: schedulesConstants.ADD_SCHEDULES_REQUEST,
});

const addSchedulesSuccess = schedules => {
  return {
    type: schedulesConstants.ADD_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

const addSchedulesFailure = error => ({
  type: schedulesConstants.ADD_SCHEDULES_FAILURE,
  error,
});

const updateSchedulesRequest = () => ({
  type: schedulesConstants.UPDATE_SCHEDULES_REQUEST,
});

const updateSchedulesSuccess = schedules => {
  return {
    type: schedulesConstants.UPDATE_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

const updateSchedulesFailure = error => ({
  type: schedulesConstants.UPDATE_SCHEDULES_FAILURE,
  error,
});

export const scuedulesActions = {
  getSchedules,
  addSchedules,
  updateSchedules,
};
