import React from 'react';

import { MainLayout } from '@components';
import { Profile } from './components';

const ProfilePage = props => (
  <MainLayout footer="main" children={<Profile {...props} />} />
);

export default ProfilePage;
