import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientMedicalReportsActions } from '@actions';
import { Container, MedicalReport } from '@components';
class MedicalReportComponent extends Component {
  componentDidMount() {
    const {
      getMedicalReport,
      getPatientMedicalReport,
      getSharedMedicalReport,
      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    console.log('hgmaaa');
    if (consultationId && id) {
      getMedicalReport(id);
    } else if (id && !consultationId && !sharedToken) {
      getPatientMedicalReport(id);
    } else if (id && sharedToken && patientId) {
      getSharedMedicalReport(patientId, id, sharedToken);
    }
  }
  render() {
    const {
      medicalReport,
      match: {
        params: { consultationId },
      },
    } = this.props;
    console.log(medicalReport);
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
          {
            item: 'Medical Reports',
            url: `/consultation/${consultationId}/medical-reports/${patientId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
          {
            item: 'Medical Reports',
            url: `/medical-reports/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : [
          { item: 'Medical Record ', url: '/#MedicalRecord' },
          {
            item: 'Medical Reports',
            url: `/medical-reports`,
          },
        ];
    return (
      <Container>
        {medicalReport && (
          <MedicalReport
            title={medicalReport.title}
            updatedAt={medicalReport.updatedAt}
            description={medicalReport.note}
            image={{
              fileLocation: medicalReport.fileLocation,
              fileName: medicalReport.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { medicalReport } = state.medicalReports;
  return {
    medicalReport,
  };
};

const mapDispatchToProps = dispatch => ({
  getMedicalReport: id =>
    dispatch(patientMedicalReportsActions.getMedicalReport(id)),
  getPatientMedicalReport: id =>
    dispatch(patientMedicalReportsActions.getPatientMedicalReport(id)),
  getSharedMedicalReport: (patientId, id, informationShareToken) =>
    dispatch(
      patientMedicalReportsActions.getSharedMedicalReport(
        patientId,
        id,
        informationShareToken
      )
    ),
});

MedicalReport.propTypes = {
  getMedicalReport: PropTypes.func.isRequired,
  medicalReport: PropTypes.object,
  match: PropTypes.object.isRequired,
  getPatientMedicalReport: PropTypes.func.isRequired,
  getSharedMedicalReport: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalReportComponent);
