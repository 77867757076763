const patientRadiologistConstants = {
  GET_XRAYS_REQUEST: 'GET_XRAYS_REQUEST',
  GET_XRAYS_SUCCESS: 'GET_XRAYS_SUCCESS',
  GET_XRAYS_FAILURE: 'GET_XRAYS_FAILURE',
  GET_XRAY_REQUEST: 'GET_XRAY_REQUEST',
  GET_XRAY_SUCCESS: 'GET_XRAY_SUCCESS',
  GET_XRAY_FAILURE: 'GET_XRAY_FAILURE',
  GET_SHARED_XRAYS_REQUEST: 'GET_SHARED_XRAYS_REQUEST',
  GET_SHARED_XRAYS_SUCCESS: 'GET_SHARED_XRAYS_SUCCESS',
  GET_SHARED_XRAYS_FAILURE: 'GET_SHARED_XRAYS_FAILURE',
  ADD_XRAY_REQUEST: 'ADD_XRAY_REQUEST',
  ADD_XRAY_SUCCESS: 'ADD_XRAY_SUCCESS',
  ADD_XRAY_FAILURE: 'ADD_XRAY_FAILURE',
  UPDATE_XRAY_REQUEST: 'UPDATE_XRAY_REQUEST',
  UPDATE_XRAY_SUCCESS: 'UPDATE_XRAY_SUCCESS',
  UPDATE_XRAY_FAILURE: 'UPDATE_XRAY_FAILURE',
  GET_MRIS_REQUEST: 'GET_MRIS_REQUEST',
  GET_MRIS_SUCCESS: 'GET_MRIS_SUCCESS',
  GET_MRIS_FAILURE: 'GET_MRIS_FAILURE',
  GET_MRI_REQUEST: 'GET_MRI_REQUEST',
  GET_MRI_SUCCESS: 'GET_MRI_SUCCESS',
  GET_MRI_FAILURE: 'GET_MRI_FAILURE',
  GET_SHARED_MRIS_REQUEST: 'GET_SHARED_MRIS_REQUEST',
  GET_SHARED_MRIS_SUCCESS: 'GET_SHARED_MRIS_SUCCESS',
  GET_SHARED_MRIS_FAILURE: 'GET_SHARED_MRIS_FAILURE',
  ADD_MRI_REQUEST: 'ADD_MRI_REQUEST',
  ADD_MRI_SUCCESS: 'ADD_MRI_SUCCESS',
  ADD_MRI_FAILURE: 'ADD_MRI_FAILURE',
  UPDATE_MRI_REQUEST: 'UPDATE_MRI_REQUEST',
  UPDATE_MRI_SUCCESS: 'UPDATE_MRI_SUCCESS',
  UPDATE_MRI_FAILURE: 'UPDATE_MRI_FAILURE',
  GET_CTS_REQUEST: 'GET_CTS_REQUEST',
  GET_CTS_SUCCESS: 'GET_CTS_SUCCESS',
  GET_CTS_FAILURE: 'GET_CTS_FAILURE',
  GET_CT_REQUEST: 'GET_CT_REQUEST',
  GET_CT_SUCCESS: 'GET_CT_SUCCESS',
  GET_CT_FAILURE: 'GET_CT_FAILURE',
  GET_SHARED_CTS_REQUEST: 'GET_SHARED_CTS_REQUEST',
  GET_SHARED_CTS_SUCCESS: 'GET_SHARED_CTS_SUCCESS',
  GET_SHARED_CTS_FAILURE: 'GET_SHARED_CTS_FAILURE',
  ADD_CT_REQUEST: 'ADD_CT_REQUEST',
  ADD_CT_SUCCESS: 'ADD_CT_SUCCESS',
  ADD_CT_FAILURE: 'ADD_CT_FAILURE',
  UPDATE_CT_REQUEST: 'UPDATE_CT_REQUEST',
  UPDATE_CT_SUCCESS: 'UPDATE_CT_SUCCESS',
  UPDATE_CT_FAILURE: 'UPDATE_CT_FAILURE',
};

export default patientRadiologistConstants;
