import { schedulesConstants } from '@actions/types';

export function schedules(state = {}, action) {
  switch (action.type) {
    case schedulesConstants.GET_SCHEDULES_REQUEST:
      return {
        ...state,
        requestSchedules: true,
      };
    case schedulesConstants.GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        getSchedules: true,
        schedules: action.schedules,
      };
    case schedulesConstants.GET_SCHEDULES_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case schedulesConstants.ADD_SCHEDULES_REQUEST:
      return {
        ...state,
        addSchedules: true,
      };
    case schedulesConstants.ADD_SCHEDULES_SUCCESS:
      return {
        ...state,
        addedSchedules: true,
        schedules: action.schedules,
      };
    case schedulesConstants.ADD_SCHEDULES_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case schedulesConstants.UPDATE_SCHEDULES_REQUEST:
      return {
        ...state,
        updateSchedules: true,
      };
    case schedulesConstants.UPDATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        updatedSchedules: true,
        schedule: action.schedule,
      };
    case schedulesConstants.UPDATE_SCHEDULES_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };

    default:
      return state;
  }
}
