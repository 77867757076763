import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  BloodPressure2X,
  BloodPressure,
  BloodSugar2X,
  BloodSugar,
  HeartRate,
  HeartRate2X,
  Height,
  Height2X,
  Temperature,
  Temperature2X,
  Weight,
  Weight2X,
} from '@images';
import { H1, convertDateToFullStringDate } from '@utils';

const Reading = styled.div`
  padding: 15px 15px 15px 0;
  background: #fff;
  border-bottom: solid 1px #979797;
  &:last-child {
    border-bottom: solid 1px #fff;
    border-radius: 6px 6px 0 0;
  }
  p {
    margin-bottom: 0;
  }
  .reading {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }
    &__img {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
      }
      img {
        width: 57px;
        height: 40px;
      }
    }
    &__time {
      padding-left: 20px;
      font-family: AvenirNext;
      font-size: 12px;
      color: #9298ac;
    }
    &__title {
      font-size: 15px;
      font-weight: bold;
      color: #060c2b;
    }
    &__details {
      font-size: 15px;
      font-weight: normal;
      color: #060c2b;
      span {
        font-weight: bold;
      }
    }
  }
`;
const ReadingsHeader = styled.header`
  background-color: #f9fafc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 6px 6px 0 0;
`;
const ReadingsWrapper = styled.div`
  margin-bottom: 15px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
`;

export default class Readings extends Component {
  render() {
    const { readings, handelShowModal } = this.props;

    const weight = readings.filter(read => read.type === 'weight');
    const bloodPressure = readings.filter(
      read => read.type === 'blood-pressure'
    );
    const heartRate = readings.filter(read => read.type === 'heart-rate');
    const temperature = readings.filter(read => read.type === 'temperature');
    const bloodSugar = readings.filter(read => read.type === 'blood-sugar');
    const height = readings.filter(read => read.type === 'height');

    return (
      <ReadingsWrapper>
        <ReadingsHeader>
          <H1>Readings</H1>
        </ReadingsHeader>
        <div>
          <Reading>
            <div className="reading__wrapper">
              <div className="reading__img">
                <picture>
                  <source srcSet={`${Weight2X} 2x`} />
                  <img src={Weight} alt="Blood Weight" />
                </picture>
                <p className="reading__title">Weight</p>
              </div>
              <p className="reading__details">
                {weight.length > 0 ? (
                  <>
                    <span>{weight[0].value}</span> KG
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      handelShowModal(
                        'Request Weight ',
                        Weight,
                        'reading-weight'
                      );
                    }}
                    to="#!"
                  >
                    Request
                  </Link>
                )}
              </p>
            </div>
            <p className="reading__time">
              {weight.length > 0
                ? convertDateToFullStringDate(weight[0].updated_at)
                : 'Not Aviable'}
            </p>
          </Reading>
          <Reading>
            <div className="reading__wrapper">
              <div className="reading__img">
                <picture>
                  <source srcSet={`${BloodPressure2X} 2x`} />
                  <img src={BloodPressure} alt="Blood Pressure" />
                </picture>
                <p className="reading__title">Blood Pressure</p>
              </div>
              <p className="reading__details">
                {bloodPressure.length > 0 ? (
                  <>
                    <span>{bloodPressure[0].value}</span> mmHg
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      handelShowModal(
                        'Request Blood Pressure ',
                        BloodPressure,
                        'reading-blood-pressure'
                      );
                    }}
                    to="#!"
                  >
                    Request
                  </Link>
                )}
              </p>
            </div>
            <p className="reading__time">
              {bloodPressure.length > 0
                ? convertDateToFullStringDate(bloodPressure[0].updated_at)
                : 'Not Aviable'}
            </p>
          </Reading>
          <Reading>
            <div className="reading__wrapper">
              <div className="reading__img">
                <picture>
                  <source srcSet={`${BloodSugar2X} 2x`} />
                  <img src={BloodSugar} alt="Blood Sugar" />
                </picture>
                <p className="reading__title">Blood Sugar</p>
              </div>
              <p className="reading__details">
                {bloodSugar.length > 0 ? (
                  <>
                    <span>{bloodSugar[0].value}</span> mg/dL
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      handelShowModal(
                        'Request Blood Sugar Test ',
                        BloodSugar,
                        'reading-blood-sugar'
                      );
                    }}
                    to="#!"
                  >
                    Request
                  </Link>
                )}
              </p>
            </div>
            <p className="reading__time">
              {bloodSugar.length > 0
                ? convertDateToFullStringDate(bloodSugar[0].updated_at)
                : 'Not Aviable'}
            </p>
          </Reading>
          <Reading>
            <div className="reading__wrapper">
              <div className="reading__img">
                <picture>
                  <source srcSet={`${HeartRate2X} 2x`} />
                  <img src={HeartRate} alt="Heart Rate" />
                </picture>
                <p className="reading__title">Heart Rate</p>
              </div>
              <p className="reading__details">
                {heartRate.length > 0 ? (
                  <>
                    <span>{heartRate[0].value}</span> BPM
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      handelShowModal(
                        'Request Heart Rate ',
                        HeartRate,
                        'reading-heart-rate'
                      );
                    }}
                    to="#!"
                  >
                    Request
                  </Link>
                )}
              </p>
            </div>
            <p className="reading__time">
              {heartRate.length > 0
                ? convertDateToFullStringDate(heartRate[0].updated_at)
                : 'Not Aviable'}
            </p>
          </Reading>
          <Reading>
            <div className="reading__wrapper">
              <div className="reading__img">
                <picture>
                  <source srcSet={`${Temperature2X} 2x`} />
                  <img src={Temperature} alt="Temperature" />
                </picture>
                <p className="reading__title">Temperature</p>
              </div>
              <p className="reading__details">
                {temperature.length > 0 ? (
                  <>
                    <span>{temperature[0].value}</span> °C
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      handelShowModal(
                        'Request Temperature ',
                        HeartRate,
                        'reading-temperature'
                      );
                    }}
                    to="#!"
                  >
                    Request
                  </Link>
                )}
              </p>
            </div>
            <p className="reading__time">
              {temperature.length > 0
                ? convertDateToFullStringDate(temperature[0].updated_at)
                : 'Not Aviable'}
            </p>
          </Reading>
          <Reading>
            <div className="reading__wrapper">
              <div className="reading__img">
                <picture>
                  <source srcSet={`${Height2X} 2x`} />
                  <img src={Height} alt="Height" />
                </picture>
                <p className="reading__title">Height</p>
              </div>
              <p className="reading__details">
                {height.length > 0 ? (
                  <>
                    <span>{height[0].value}</span> CM
                  </>
                ) : (
                  <Link
                    onClick={() => {
                      handelShowModal(
                        'Request Height ',
                        HeartRate,
                        'reading-height'
                      );
                    }}
                    to="#!"
                  >
                    Request
                  </Link>
                )}
              </p>
            </div>
            <p className="reading__time">
              {height.length > 0
                ? convertDateToFullStringDate(height[0].updated_at)
                : 'Not Aviable'}
            </p>
          </Reading>
        </div>
      </ReadingsWrapper>
    );
  }
}

Readings.propTypes = {
  readings: PropTypes.array,
  handelShowModal: PropTypes.func.isRequired,
};
