import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Form, Input } from 'antd';
import 'antd/lib/form/style/css';
import PropTypes from 'prop-types';

import { FormWrapper } from '@components';
import { Colors } from '@utils';

const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const RequestMedicalRecordFormWrapper = styled.div`
  .text-area {
    margin-top: 15px;
    height: 150px;
  }
  .Form-wrapper {
    border: none;
  }
`;

class RequestMedicalRecordForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      requestScan,
      consultationId,
      scanType,
      handelCloseModal,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        requestScan({
          scanType,
          id: consultationId,
          title: values.testName,
          body: values.notes,
        });
        handelCloseModal();
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <RequestMedicalRecordFormWrapper>
        <FormWrapper>
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('testName', {
                rules: [
                  {
                    required: true,
                    message: 'please fill Test Name field',
                  },
                ],
              })(<Input placeholder="Add Test name" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('notes', {
                rules: [],
              })(<Input.TextArea placeholder="Notes" className="text-area" />)}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Request
              </SubmitButton>
            </FormItem>
          </Form>
        </FormWrapper>
      </RequestMedicalRecordFormWrapper>
    );
  }
}

export default Form.create({})(RequestMedicalRecordForm);
RequestMedicalRecordForm.propTypes = {
  form: PropTypes.object.isRequired,
  requestScan: PropTypes.func.isRequired,
  consultationId: PropTypes.number.isRequired,
  scanType: PropTypes.string.isRequired,
  handelCloseModal: PropTypes.func.isRequired,
};
