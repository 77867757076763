const patientReportsConstants = {
  GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
  GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
  GET_REPORTS_FAILURE: 'GET_REPORTS_FAILURE',
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
  GET_PATIENT_REPORT_REQUEST: 'GET_PATIENT_REPORT_REQUEST',
  GET_PATIENT_REPORT_SUCCESS: 'GET_PATIENT_REPORT_SUCCESS',
  GET_PATIENT_REPORT_FAILURE: 'GET_PATIENT_REPORT_FAILURE',
  GET_SHARED_REPORT_REQUEST: 'GET_SHARED_REPORT_REQUEST',
  GET_SHARED_REPORT_SUCCESS: 'GET_SHARED_REPORT_SUCCESS',
  GET_SHARED_REPORT_FAILURE: 'GET_SHARED_REPORT_FAILURE',
  GET_SHARED_REPORTS_REQUEST: 'GET_SHARED_REPORTS_REQUEST',
  GET_SHARED_REPORTS_SUCCESS: 'GET_SHARED_REPORTS_SUCCESS',
  GET_SHARED_REPORTS_FAILURE: 'GET_SHARED_REPORTS_FAILURE',
  ADD_REPORTS_REQUEST: 'ADD_REPORTS_REQUEST',
  ADD_REPORTS_SUCCESS: 'ADD_REPORTS_SUCCESS',
  ADD_REPORTS_FAILURE: 'ADD_REPORTS_FAILURE',
  UPDATE_REPORTS_REQUEST: 'UPDATE_REPORTS_REQUEST',
  UPDATE_REPORTS_SUCCESS: 'UPDATE_REPORTS_SUCCESS',
  UPDATE_REPORTS_FAILURE: 'UPDATE_REPORTS_FAILURE',
};

export default patientReportsConstants;
