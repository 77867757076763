import React from 'react';
import { connect } from 'react-redux';

import { BaseLayout, MobileForm, FullPage, Container } from '@components';
import { patientActions } from '@actions';

const MobileFormPage = props => (
  <BaseLayout>
    <FullPage>
      <Container>
        <MobileForm {...props} />
      </Container>
    </FullPage>
  </BaseLayout>
);

const mapStateToProps = state => {
  const { errors, user } = state.authentication;
  return {
    errors,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  addMobile: mobUser => dispatch(patientActions.addMobile(mobUser)),
});

const connectedMobileFormPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileFormPage);
export default connectedMobileFormPage;
