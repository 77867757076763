import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { H1 } from '@utils';
import { consultationActions } from '@actions';
import { SearchForm, ResultTable } from './';
import { connect } from 'react-redux';

const ReportsWrapper = styled.div``;
const ReportsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    line-height: 60px;
    vertical-align: middle;
  }
  form {
    display: flex;
    & > div {
      margin-bottom: 10px;
      margin-right: 30px;
      input {
        width: 440px;
        height: 50px;
      }
    }
    button {
      max-height: 48px;
    }
  }
`;
class Reports extends Component {
  componentDidMount() {
    const { getFinishedConsultations } = this.props;
    getFinishedConsultations();
  }
  render() {
    const { FinishedConsultations } = this.props;

    return (
      <ReportsWrapper>
        <ReportsHeader>
          <H1>Reports</H1>
          <SearchForm />
        </ReportsHeader>
        {FinishedConsultations && (
          <ResultTable FinishedConsultations={FinishedConsultations} />
        )}
      </ReportsWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { FinishedConsultations } = state.consultation;

  return {
    FinishedConsultations,
  };
};

const mapDispatchToProps = dispatch => ({
  getFinishedConsultations: id =>
    dispatch(consultationActions.getFinishedConsultations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

Reports.propTypes = {
  FinishedConsultations: PropTypes.object,
  getFinishedConsultations: PropTypes.func.isRequired,
};
