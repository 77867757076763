import React from 'react';

import { MainLayout } from '@components';
import { RequestConsultation } from './components';

const RequestConsultationPage = props => (
  <MainLayout footer="main" children={<RequestConsultation {...props} />} />
);

export default RequestConsultationPage;
