import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import ActiveConsultant from '../ActiveConsultant';
import { Message, Message2X } from '@images';
import PropTypes from 'prop-types';
import { H1 } from '@utils';

const HeaderWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  margin-bottom: 15px;
`;
const ImgWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 20px;
  img {
    width: 49px;
    height: 49px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 420px;
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #5cb3db;
    margin: 0;
  }
  p {
    margin: 0;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #060c2b;
  }
`;
export default class ActiveConsultants extends Component {
  render() {
    const { acceptedConsultations } = this.props;

    return (
      <div>
        <H1>Active Consultations</H1>
        {(!acceptedConsultations ||
          (acceptedConsultations && acceptedConsultations.data.length < 1)) && (
          <HeaderWrapper>
            <ImgWrapper>
              <picture>
                <source srcSet={`${Message2X} 2x`} />
                <img src={Message} alt="Message" />
              </picture>
            </ImgWrapper>
            <ContentWrapper>
              <h2>You do not accept consultation yet</h2>
              <p>
                Once you accept the consultation, you will be ready to review it
              </p>
            </ContentWrapper>
          </HeaderWrapper>
        )}
        {acceptedConsultations &&
          acceptedConsultations.data.map(consultation => (
            <ActiveConsultant
              key={consultation.id}
              consultation={consultation}
            />
          ))}
      </div>
    );
  }
}

ActiveConsultants.propTypes = {
  acceptedConsultations: PropTypes.object.isRequired,
};
