import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Form,
  // Input
} from 'antd';
import 'antd/lib/form/style/css';

import {
  // Colors,
  H1,
} from '@utils';
import { CancelMessage, CancelMessage2X } from '@images';

// const FormItem = Form.Item;

// const SubmitButton = styled.button`
//   width: 100%;
//   background: ${Colors.secondaryColor};
//   color: ${Colors.white};
//   border-radius: 6px;
//   box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
//   border: solid 1px ${Colors.gary};
//   font-family: Heebo;
//   font-size: 16px;
//   font-weight: 500;
//   margin-top: 30px;
//   padding: 10px 0;
//   @media (max-width: 767px) {
//     margin-top: 15px;
//     margin-bottom: 0;
//   }
// `;

const CancelConsultantsWrapper = styled.div`
  text-align: center;
  padding: 20px;
  min-width: 450px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;
const CancelConsultantsImage = styled.div`
  margin: 10px auto;
  img {
    width: 216px;
    height: 120px;
  }
`;
const CancelConsultantsContent = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 600;
    color: #ec0707;
  }
  .content {
    font-size: 16.8px;
    font-weight: normal;
    max-width: 280px;
    margin: 10px auto;
  }
  .time {
    font-size: 18px;
    font-weight: bold;
  }
`;

class CancelConsultants extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };
  render() {
    // const {
    //   // form: { getFieldDecorator },
    // } = this.props;

    return (
      <div>
        <CancelConsultantsWrapper>
          <CancelConsultantsImage>
            <picture>
              <source srcSet={`${CancelMessage2X} 2x`} />
              <img src={CancelMessage} alt="Thanks" />
            </picture>
          </CancelConsultantsImage>
          <CancelConsultantsContent>
            <H1>Request Canceld</H1>
            <p className="content">
              Please let us Know why you canceld the request
            </p>
          </CancelConsultantsContent>
          {/* <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('explain', {
                rules: [
                  {
                    required: true,
                    message: 'please fill Explain field',
                  },
                ],
              })(
                <Input.TextArea placeholder="Explain" className="text-area" />
              )}
            </FormItem>
            <SubmitButton className="submit-button" htmlType="submit">
              Submit
            </SubmitButton>
          </Form> */}
        </CancelConsultantsWrapper>
      </div>
    );
  }
}
export default Form.create({})(CancelConsultants);
CancelConsultants.propTypes = {
  form: PropTypes.object.isRequired,
};
