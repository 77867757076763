const makePayment = payConsultant => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(payConsultant),
  };

  return fetch(`/consultant/pay-consultant`, requestOptions)
    .then(payDetails => {
      return { error: false, payDetails };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getClientSecret = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/pre-saving-card`, requestOptions)
    .then(result => {
      return { error: false, result };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getCardsList = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/list-cards`, requestOptions)
    .then(cards => {
      return { error: false, cards };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const deleteCard = paymentMethodId => {
  const requestOptions = {
    method: 'DELETE',
    redirect: 'follow',
    body: JSON.stringify({ paymentMethodId: paymentMethodId }),
  };

  return fetch(`/patient/delete-card`, requestOptions)
    .then(card => {
      return { error: false, card };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getAuthRequiredFaildPayment = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/auth-failed-payment`, requestOptions)
    .then(failedPayment => {
      return { error: false, failedPayment };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  makePayment,
  getClientSecret,
  getCardsList,
  deleteCard,
  getAuthRequiredFaildPayment,
};
