import { alertActions } from '@actions';
import { patientAllergiesServices } from '@services';
import patientAllergiesConstants from './types';
import { history } from '@utils';

const getAllergies = page => {
  return dispatch => {
    dispatch(getAllergiesRequest());
    patientAllergiesServices.getAllergies(page).then(res => {
      if (res.error) {
        dispatch(getAllergiesFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAllergiesSuccess(res.allergies));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAllergy = id => {
  return dispatch => {
    dispatch(getAllergyRequest());
    patientAllergiesServices.getAllergy(id).then(res => {
      if (res.error) {
        dispatch(getAllergyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAllergySuccess(res.allergy));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getPatientAllergy = id => {
  return dispatch => {
    dispatch(getPatientAllergyRequest());
    patientAllergiesServices.getPatientAllergy(id).then(res => {
      if (res.error) {
        dispatch(getPatientAllergyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientAllergySuccess(res.allergy));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedAllergies = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedAllergiesRequest());
    patientAllergiesServices
      .getSharedAllergies(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedAllergiesFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedAllergiesSuccess(res.allergies));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getSharedAllergy = (patientId, id, informationShareToken) => {
  return dispatch => {
    dispatch(getSharedAllergyRequest());
    patientAllergiesServices
      .getSharedAllergy(patientId, id, informationShareToken)
      .then(res => {
        if (res.error) {
          dispatch(getSharedAllergyFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedAllergySuccess(res.allergy));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addAllergy = allergy => {
  return dispatch => {
    dispatch(addAllergyRequest(allergy));

    patientAllergiesServices.addAllergy(allergy).then(res => {
      if (res.error) {
        dispatch(addAllergyFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addAllergySuccess(res.allergy));
        dispatch(alertActions.success('Your Allergies has Added'));
        dispatch(alertActions.clear());

        const RequestConsultation = new URLSearchParams(
          history.location.search
        ).get('RequestConsultation');
        if (RequestConsultation) {
          history.push('/allergies?RequestConsultation=1');
        } else {
          history.push('/allergies');
        }
      }
    });
  };
};

const updateAllergy = (id, allergy) => {
  return dispatch => {
    dispatch(updateAllergyRequest(allergy));

    patientAllergiesServices.updateAllergy(id, allergy).then(res => {
      if (res.error) {
        dispatch(updateAllergyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateAllergySuccess(res.allergy));
        dispatch(alertActions.success('Your Allergies has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAllergiesRequest = () => ({
  type: patientAllergiesConstants.GET_ALLERGIES_REQUEST,
});
const getAllergiesSuccess = allergies => ({
  type: patientAllergiesConstants.GET_ALLERGIES_SUCCESS,
  allergies,
});
const getAllergiesFailure = error => ({
  type: patientAllergiesConstants.GET_ALLERGIES_FAILURE,
  error,
});

const getAllergyRequest = () => ({
  type: patientAllergiesConstants.GET_ALLERGY_REQUEST,
});
const getAllergySuccess = allergy => ({
  type: patientAllergiesConstants.GET_ALLERGY_SUCCESS,
  allergy,
});
const getAllergyFailure = error => ({
  type: patientAllergiesConstants.GET_ALLERGY_FAILURE,
  error,
});

const getPatientAllergyRequest = () => ({
  type: patientAllergiesConstants.GET_PATIENT_ALLERGY_REQUEST,
});
const getPatientAllergySuccess = allergy => ({
  type: patientAllergiesConstants.GET_PATIENT_ALLERGY_SUCCESS,
  allergy,
});
const getPatientAllergyFailure = error => ({
  type: patientAllergiesConstants.GET_PATIENT_ALLERGY_FAILURE,
  error,
});

const getSharedAllergiesRequest = () => ({
  type: patientAllergiesConstants.GET_SHARED_ALLERGIES_REQUEST,
});
const getSharedAllergiesSuccess = allergies => ({
  type: patientAllergiesConstants.GET_SHARED_ALLERGIES_SUCCESS,
  allergies,
});
const getSharedAllergiesFailure = error => ({
  type: patientAllergiesConstants.GET_SHARED_ALLERGIES_FAILURE,
  error,
});

const getSharedAllergyRequest = () => ({
  type: patientAllergiesConstants.GET_SHARED_ALLERGY_REQUEST,
});
const getSharedAllergySuccess = allergy => ({
  type: patientAllergiesConstants.GET_SHARED_ALLERGY_SUCCESS,
  allergy,
});
const getSharedAllergyFailure = error => ({
  type: patientAllergiesConstants.GET_SHARED_ALLERGY_FAILURE,
  error,
});

const addAllergyRequest = allergy => ({
  type: patientAllergiesConstants.ADD_ALLERGIES_REQUEST,
  allergy,
});
const addAllergySuccess = allergy => ({
  type: patientAllergiesConstants.ADD_ALLERGIES_SUCCESS,
  allergy,
});
const addAllergyFailure = error => ({
  type: patientAllergiesConstants.ADD_ALLERGIES_FAILURE,
  error,
});

const updateAllergyRequest = allergy => ({
  type: patientAllergiesConstants.UPDATE_ALLERGIES_REQUEST,
  allergy,
});
const updateAllergySuccess = allergy => ({
  type: patientAllergiesConstants.UPDATE_ALLERGIES_SUCCESS,
  allergy,
});
const updateAllergyFailure = error => ({
  type: patientAllergiesConstants.UPDATE_ALLERGIES_FAILURE,
  error,
});

export const patientAllergyActions = {
  getAllergies,
  getAllergy,
  getPatientAllergy,
  getSharedAllergies,
  getSharedAllergy,
  addAllergy,
  updateAllergy,
};
