import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PlusIcon } from '@images';
import styled from '@emotion/styled/macro';

const AddWrapper = styled.div`
  position: relative;
  padding-left: 15px;
  &:before {
    content: ' ';
    width: 48px;
    height: 48px;
    background: rgba(92, 179, 219, 0.05);
    display: block;
    position: absolute;
    top: 0%;
    left: 3px;
    border-radius: 50%;
  }
  img {
    width: 25px;
    height: 25px;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
    top: -11px;
  }
  a {
    line-height: 48px;
    font-family: SFProText;
    font-size: 14px;
    font-weight: 500;
    color: #5cb3db;
  }
`;

export default function Add(props) {
  return (
    <AddWrapper className="add">
      <Link to={props.to}>
        <img src={PlusIcon} alt="Plus" />
        Add
      </Link>
    </AddWrapper>
  );
}

Add.propTypes = {
  to: PropTypes.string.isRequired,
};
