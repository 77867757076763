import React, { Component } from 'react';
import styled from '@emotion/styled/macro';

import Action from '../Action';

const ActivityWrapper = styled.div`
  .activity-title {
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
`;
export default class Activity extends Component {
  render() {
    return (
      <ActivityWrapper>
        <h1 className="activity-title">Activity</h1>
        <Action />
        <Action />
      </ActivityWrapper>
    );
  }
}
