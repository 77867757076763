import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const ContentWrapper = styled.div`
  background: #fff;
  padding: 24px;
`;

export default function Content(props) {
  return <ContentWrapper>{props.children}</ContentWrapper>;
}

Content.propTypes = {
  children: PropTypes.element,
};
