import React from 'react';

import { MainLayout } from '@components';
import { NewConditions } from './components';

function NewConditionsPage(props) {
  return <MainLayout footer="main" children={<NewConditions {...props} />} />;
}

export default NewConditionsPage;
