import React from 'react';
import { connect } from 'react-redux';

import { BaseLayout, LoginForm, FullPage, Container } from '@components';
import { consultantActions, alertActions } from '@actions';

const Login = props => (
  <BaseLayout>
    <FullPage>
      <Container>
        <LoginForm {...props} registerLink="/consultant/register" />
      </Container>
    </FullPage>
  </BaseLayout>
);

const mapStateToProps = state => {
  const { loggingIn } = state.authentication;
  return {
    loggingIn,
  };
};

const mapDispatchToProps = dispatch => ({
  verifyConsultant: ({ userId, token }) =>
    dispatch(consultantActions.verify({ userId, token })),
  errorAlert: err => dispatch(alertActions.error(err)),
  login: (email, password) =>
    dispatch(consultantActions.login(email, password)),
  resetPassword: email => dispatch(consultantActions.resetPassword(email)),
});

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);
export default connectedLoginPage;
