import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patientAllergyActions } from '@actions';

import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';

import { Container } from '@components';
import { ConditionsIcon } from '@images';
import { H1, PrimaryButton, Breadcrumb } from '@utils';

const NewAllergiesWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 45px;
  margin-left: -30px;
  .svg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #f5f5f5;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-family: AvenirNext;
      font-size: 24px;
      font-weight: 500;
      color: #5cb3db;
      margin-bottom: 5px;
      line-height: 1;
    }
    p {
      font-family: AvenirNext;
      font-size: 16.8px;
      color: #060c2b;
      margin-bottom: 0;
    }
  }
`;

const FormWrapper = styled.div`
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .ant-row.ant-form-item {
    max-width: 780px;
  }
  .ant-form-item-label {
    display: none;
  }
  .ant-input {
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    padding: 15px;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 500;
    min-height: 43px;
  }
  .has-error {
    .ant-input {
      border: 1px solid red;
    }
  }
  .text-area {
    min-height: 219px;
    font-family: Heebo;
    font-size: 17px;
    font-weight: 500;
  }
  .primary-button {
    width: 780px;
    button {
      padding: 5px;
    }
  }
`;

class NewAllergies extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      addAllergy,
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        addAllergy(values);
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');

    return (
      <Container>
        <NewAllergiesWrapper>
          <Breadcrumb
            links={
              RequestConsultation
                ? [
                    {
                      item: 'Request Consultation ',
                      url: '/request-consultation',
                    },
                    { item: 'Allergies', url: '/allergies' },
                  ]
                : [
                    { item: 'Medical Record ', url: '/#MedicalRecord' },
                    { item: 'Allergies', url: '/allergies' },
                  ]
            }
          />
          <H1>Add Allergies</H1>
          <TitleWrapper>
            <div className="svg">
              <ConditionsIcon width={60} height={80} />
            </div>
            <div className="content">
              <h2>Allergies</h2>
              <p>Add your Allergies</p>
            </div>
          </TitleWrapper>
          <FormWrapper>
            <Form onSubmit={this.handleSubmit}>
              <div>
                <Form.Item label="Title" hasFeedback>
                  {getFieldDecorator('value', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Allergies Title!',
                      },
                    ],
                  })(<Input placeholder="Title" />)}
                </Form.Item>
                <Form.Item label="Allergies" hasFeedback>
                  {getFieldDecorator('description', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your new Allergies!',
                      },
                    ],
                  })(
                    <Input.TextArea
                      placeholder="Allergies"
                      className="text-area"
                    />
                  )}
                </Form.Item>

                <Form.Item>
                  <PrimaryButton>
                    <button htmltype="submit" className="submit">
                      Add
                    </button>
                  </PrimaryButton>
                </Form.Item>
              </div>
            </Form>
          </FormWrapper>
        </NewAllergiesWrapper>
      </Container>
    );
  }
}

NewAllergies.propTypes = {
  form: PropTypes.object.isRequired,
  addAllergy: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  addAllergy: allergy => dispatch(patientAllergyActions.addAllergy(allergy)),
});

const WrappedNewAllergies = Form.create({ name: 'new_Allergies' })(
  connect(mapStateToProps, mapDispatchToProps)(NewAllergies)
);

export default WrappedNewAllergies;
