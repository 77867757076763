/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const AlertIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '24'}px`}
      width={`${width || '24'}px`}
      viewBox="0 0 24 24"
      css={css`
        fill: ${color || '#f0ba11'};
        max-width: ${width || '24'}px;
        max-height: ${height || '24'}px;
        height: ${height || '24'}px;
        width: ${width || '24'}px;
      `}
    >
      <g id="surface1">
        <path d="M 20.488281 3.511719 C 15.808594 -1.171875 8.191406 -1.171875 3.511719 3.511719 C -1.171875 8.191406 -1.171875 15.808594 3.511719 20.488281 C 5.851562 22.832031 8.925781 24 12 24 C 15.074219 24 18.148438 22.832031 20.488281 20.488281 C 25.171875 15.808594 25.171875 8.191406 20.488281 3.511719 Z M 12 20.003906 C 11.449219 20.003906 11 19.554688 11 19.003906 C 11 18.453125 11.449219 18.003906 12 18.003906 C 12.550781 18.003906 13 18.453125 13 19.003906 C 13 19.554688 12.550781 20.003906 12 20.003906 Z M 13 15 C 13 15.554688 12.554688 16.003906 12 16.003906 C 11.445312 16.003906 11 15.554688 11 15 L 11 4.996094 C 11 4.445312 11.445312 3.996094 12 3.996094 C 12.554688 3.996094 13 4.445312 13 4.996094 Z M 13 15 " />
      </g>
    </svg>
  );
};

export default AlertIcon;

AlertIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
