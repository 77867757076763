const convertDateToFullStringDate = timeSpan => {
  let date = new Date(timeSpan * 1000);
  const time = () => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    if (hours > 12) {
      return `${hours - 12 < 10 ? `0${hours - 12}` : hours - 12}:${
        minutes < 10 ? `0${minutes}` : minutes
      } PM`;
    } else {
      return `${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      } AM`;
    }
  };
  date = `${date.toDateString()}, ${time()}`;
  return date;
};

export default convertDateToFullStringDate;
