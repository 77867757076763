import { alertActions } from '@actions';
import { patientEcgsServices } from '@services';
import patientEcgsConstants from './types';
import { history } from '@utils';

const getECGs = page => {
  return dispatch => {
    dispatch(getECGsRequest());
    patientEcgsServices.getECGs(page).then(res => {
      if (res.error) {
        dispatch(getECGsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getECGsSuccess(res.ecgs));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getECG = id => {
  return dispatch => {
    dispatch(getECGRequest());
    patientEcgsServices.getECG(id).then(res => {
      if (res.error) {
        dispatch(getECGFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getECGSuccess(res.ecg));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getPatientECG = id => {
  return dispatch => {
    dispatch(getPatientECGRequest());
    patientEcgsServices.getPatientECG(id).then(res => {
      if (res.error) {
        dispatch(getPatientECGFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientECGSuccess(res.ecg));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedECGs = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedECGsRequest());
    patientEcgsServices
      .getSharedECGs(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedECGsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedECGsSuccess(res.ecgs));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getSharedECG = (patientId, id, informationShareToken) => {
  return dispatch => {
    dispatch(getSharedECGRequest());
    patientEcgsServices
      .getSharedECG(patientId, id, informationShareToken)
      .then(res => {
        if (res.error) {
          dispatch(getSharedECGFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedECGSuccess(res.ecg));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addECG = ECG => {
  return dispatch => {
    dispatch(addECGRequest(ECG));

    patientEcgsServices.addECG(ECG).then(res => {
      if (res.error) {
        dispatch(addECGFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addECGSuccess(res.condition));
        dispatch(alertActions.success('Your ECG has Added'));
        dispatch(alertActions.clear());
        const RequestConsultation = new URLSearchParams(
          history.location.search
        ).get('RequestConsultation');
        if (RequestConsultation) {
          history.push('/ecgs?RequestConsultation=1');
        } else {
          history.push('/ecgs');
        }
      }
    });
  };
};

const updateECG = (id, ECG) => {
  return dispatch => {
    dispatch(updateECGRequest(ECG));

    patientEcgsServices.updateECG(id, ECG).then(res => {
      if (res.error) {
        dispatch(updateECGFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateECGSuccess(res));
        dispatch(alertActions.success('Your Conditions has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getECGsRequest = () => ({
  type: patientEcgsConstants.GET_ECGS_REQUEST,
});

const getECGsSuccess = ecgs => ({
  type: patientEcgsConstants.GET_ECGS_SUCCESS,
  ecgs,
});

const getECGsFailure = error => ({
  type: patientEcgsConstants.GET_ECGS_FAILURE,
  error,
});

const getECGRequest = () => ({
  type: patientEcgsConstants.GET_ECG_REQUEST,
});

const getECGSuccess = ecg => ({
  type: patientEcgsConstants.GET_ECG_SUCCESS,
  ecg,
});

const getECGFailure = error => ({
  type: patientEcgsConstants.GET_ECG_FAILURE,
  error,
});

const getPatientECGRequest = () => ({
  type: patientEcgsConstants.GET_PATIENT_ECG_REQUEST,
});

const getPatientECGSuccess = ecg => ({
  type: patientEcgsConstants.GET_PATIENT_ECG_SUCCESS,
  ecg,
});

const getPatientECGFailure = error => ({
  type: patientEcgsConstants.GET_PATIENT_ECG_FAILURE,
  error,
});

const getSharedECGsRequest = () => ({
  type: patientEcgsConstants.GET_SHARED_ECGS_REQUEST,
});

const getSharedECGsSuccess = ecgs => ({
  type: patientEcgsConstants.GET_SHARED_ECGS_SUCCESS,
  ecgs,
});

const getSharedECGsFailure = error => ({
  type: patientEcgsConstants.GET_SHARED_ECGS_FAILURE,
  error,
});

const getSharedECGRequest = () => ({
  type: patientEcgsConstants.GET_SHARED_ECG_REQUEST,
});

const getSharedECGSuccess = ecg => ({
  type: patientEcgsConstants.GET_SHARED_ECG_SUCCESS,
  ecg,
});

const getSharedECGFailure = error => ({
  type: patientEcgsConstants.GET_SHARED_ECG_FAILURE,
  error,
});
const addECGRequest = ecg => ({
  type: patientEcgsConstants.ADD_ECGS_REQUEST,
  ecg,
});

const addECGSuccess = ecg => ({
  type: patientEcgsConstants.ADD_ECGS_SUCCESS,
  ecg,
});

const addECGFailure = error => ({
  type: patientEcgsConstants.ADD_ECGS_FAILURE,
  error,
});

const updateECGRequest = ecg => ({
  type: patientEcgsConstants.UPDATE_ECGS_REQUEST,
  ecg,
});

const updateECGSuccess = ecg => ({
  type: patientEcgsConstants.UPDATE_ECGS_SUCCESS,
  ecg,
});

const updateECGFailure = error => ({
  type: patientEcgsConstants.UPDATE_ECGS_FAILURE,
  error,
});

export const patientEcgsActions = {
  getECGs,
  getECG,
  getPatientECG,
  getSharedECGs,
  getSharedECG,
  addECG,
  updateECG,
};
