import React from 'react';
import { MainLayout } from '@components';
import MedicalReportsComponents from './MedicalReports';

export default function MedicalReportsPage(props) {
  return (
    <MainLayout
      footer="main"
      children={<MedicalReportsComponents {...props} />}
    />
  );
}
