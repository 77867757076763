import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import EmptyConsultants from './EmptyConsultants.js';
import { ConsultantsCart, H1, More, convertDateToFullStringDate } from '@utils';
import { patientConsultationsActions } from '@actions';

const ConsultantsWrapper = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  .title-wrapper {
    width: 100;
    display: flex;
    justify-content: space-between;
  }
  .consultants-cart + .consultants-cart {
    margin-top: 15px;
  }
`;

class Consultants extends Component {
  componentDidMount() {
    const { getMyConsultationRequests } = this.props;
    getMyConsultationRequests();
  }
  render() {
    const { consultationRequests } = this.props;
    const newConsultationRequests =
      consultationRequests &&
      consultationRequests.data.filter(
        consultation =>
          consultation.status === 'new' || consultation.status === 'assigned'
      );

    return (
      <ConsultantsWrapper>
        {newConsultationRequests && newConsultationRequests.length > 0 ? (
          <>
            <div className="title-wrapper">
              <H1>Consultants</H1>
              <div>
                <More to="/my-consultationRequests" />
              </div>
            </div>
            <ConsultantsCart
              id={
                newConsultationRequests[newConsultationRequests.length - 1].id
              }
              title={
                newConsultationRequests[newConsultationRequests.length - 1]
                  .symptoms
              }
              assigned={
                newConsultationRequests[newConsultationRequests.length - 1]
                  .consultant
              }
              startTime={convertDateToFullStringDate(
                newConsultationRequests[newConsultationRequests.length - 1]
                  .createdAt
              )}
              className="consultants-cart"
            />
          </>
        ) : (
          <EmptyConsultants />
        )}
      </ConsultantsWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { consultationRequests } = state.consultations;

  return {
    consultationRequests,
  };
};

const mapDispatchToProps = dispatch => ({
  getMyConsultationRequests: () =>
    dispatch(patientConsultationsActions.getMyConsultationRequests()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consultants);

Consultants.propTypes = {
  getMyConsultationRequests: PropTypes.func.isRequired,
  consultationRequests: PropTypes.object,
};
