import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientEcgsActions } from '@actions';
import { Container, MedicalReport } from '@components';
class ECG extends Component {
  componentDidMount() {
    const {
      getECG,
      getPatientECG,
      getSharedECG,
      match: {
        params: { id, consultationId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (consultationId && id) {
      getECG(id);
    } else if (id && !consultationId && !sharedToken) {
      getPatientECG(id);
    } else if (id && sharedToken && patientId) {
      getSharedECG(patientId, id, sharedToken);
    }
  }
  render() {
    const {
      ecg,
      match: {
        params: { consultationId },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
          {
            item: 'ECGS',
            url: `/consultation/${consultationId}/ecgs/${patientId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
          {
            item: 'ECGS',
            url: `/ecgs/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : [
          { item: 'Medical Record ', url: '/#MedicalRecord' },
          {
            item: 'ECGS',
            url: `/ecgs`,
          },
        ];
    return (
      <Container>
        {ecg && (
          <MedicalReport
            title={ecg.value}
            updatedAt={ecg.updatedAt}
            description={ecg.note}
            image={{
              fileLocation: ecg.fileLocation,
              fileName: ecg.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={consultationId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { ecg } = state.ecgs;
  return {
    ecg,
  };
};

const mapDispatchToProps = dispatch => ({
  getECG: id => dispatch(patientEcgsActions.getECG(id)),
  getPatientECG: id => dispatch(patientEcgsActions.getPatientECG(id)),
  getSharedECG: (patientId, id, informationShareToken) =>
    dispatch(
      patientEcgsActions.getSharedECG(patientId, id, informationShareToken)
    ),
});

ECG.propTypes = {
  getECG: PropTypes.func.isRequired,
  ecg: PropTypes.object,
  match: PropTypes.object.isRequired,
  getPatientECG: PropTypes.func.isRequired,
  getSharedECG: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECG);
