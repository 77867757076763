import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SendReportForm from '../sendReportForm';
// import { Editor } from '@components';
import { CloseIcon, ConcernIcon, WhiteCheck, WhiteCheck2X } from '@images';
import {
  H1,
  PrimaryButton,
  convertDateToFullStringDate,
  CalculateTimes,
} from '@utils';

const PatientConcernWrapper = styled.div`
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background: #fff;
`;

const PatientConcernHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 0;

  .concern-header {
    &__title {
      display: flex;
      align-items: center;
    }
    &__time {
      p {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
    }
  }
`;
const PatientConcernContent = styled.div`
  padding: 20px;
  .concern-content {
    &__title {
      font-size: 20px;
      font-weight: bold;
      color: #060c2b;
      margin-bottom: 7px;
    }
    &__time {
      font-size: 14.4px;
      font-weight: normal;
      color: #9298ac;
    }
    &__body {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #060c2b;
      p + p {
        color: #5cb3db;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
`;
const PatientConcernFooter = styled.footer`
  text-align: center;
  background-color: #f6f6f6;
  padding: 24px 0;
  .concern-editor {
    margin-bottom: 15px;
  }
  .primary-button {
    width: 80%;
    margin: auto;
  }
  .actions {
    display: flex;
    justify-content: center;
    .primary-button {
      width: 48%;
      a {
        display: flex;
        justify-content: center;
        svg,
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
      & + .primary-button {
        margin-left: 15px;
        a {
          background: #fff;
          border-color: #ec0707;
          color: #ec0707;
        }
      }
    }
  }
`;
export default class PatientConcern extends Component {
  state = {
    viewEditor: false,
  };
  setViewEditor = viewEditor => {
    this.setState({ viewEditor });
  };
  render() {
    const { viewEditor } = this.state;
    const {
      consultation,
      sendReport,
      rejectConsultation,
      acceptConsultation,
      handelShowModal,
      setAcceptedConsultantsid,
      setCancelConsultantsid,
      getConsultation,
    } = this.props;
    return (
      <PatientConcernWrapper>
        <PatientConcernHeader>
          <div className="concern-header__title">
            <ConcernIcon />
            <H1>Patient Concern</H1>
          </div>
          <div className="concern-header__time">
            {consultation.inactiveConsultantAt && (
              <p>{CalculateTimes(consultation.inactiveConsultantAt)} Hours </p>
            )}
          </div>
        </PatientConcernHeader>
        <PatientConcernContent>
          <h2 className="concern-content__title">{consultation.symptoms}</h2>
          <p className="concern-content__time">
            {convertDateToFullStringDate(consultation.updatedAt)}
          </p>
          <div className="concern-content__body">
            <p>{consultation.symptomsExplained}</p>
            <p>{consultation.status}</p>
          </div>
        </PatientConcernContent>
        {consultation.status !== 'done' && (
          <PatientConcernFooter>
            {/* <div className="concern-editor">{viewEditor && <Editor />}</div> */}
            {consultation.consultantId ? (
              <>
                <div className="concern-editor">
                  {viewEditor && (
                    <SendReportForm
                      consultationId={consultation.id}
                      sendReport={sendReport}
                      setViewEditor={this.setViewEditor}
                    />
                  )}
                </div>
                {!viewEditor && (
                  <PrimaryButton>
                    <button
                      onClick={() =>
                        this.setState({ viewEditor: !this.state.viewEditor })
                      }
                    >
                      Add Medical Report
                    </button>
                  </PrimaryButton>
                )}
              </>
            ) : (
              <div className="actions">
                <PrimaryButton>
                  <Link
                    to={`/consultant/consultation/${consultation.id}`}
                    onClick={() => {
                      setAcceptedConsultantsid(consultation.id);
                      acceptConsultation(consultation.id);
                      getConsultation(consultation.id);
                    }}
                  >
                    <picture>
                      <source srcSet={`${WhiteCheck2X} 2x`} />
                      <img src={WhiteCheck} alt="Accept" />
                    </picture>
                    Accept
                  </Link>
                </PrimaryButton>
                <PrimaryButton>
                  <Link
                    to="/"
                    onClick={() => {
                      rejectConsultation(consultation.id);
                      handelShowModal('Cancel');
                      setCancelConsultantsid(consultation.id);
                    }}
                  >
                    <CloseIcon />
                    <span>Cancel</span>
                  </Link>
                </PrimaryButton>
              </div>
            )}
          </PatientConcernFooter>
        )}
      </PatientConcernWrapper>
    );
  }
}

PatientConcern.propTypes = {
  consultation: PropTypes.object,
  sendReport: PropTypes.func.isRequired,
  rejectConsultation: PropTypes.func.isRequired,
  acceptConsultation: PropTypes.func.isRequired,
  handelShowModal: PropTypes.func.isRequired,
  setAcceptedConsultantsid: PropTypes.func.isRequired,
  setCancelConsultantsid: PropTypes.func.isRequired,
  getConsultation: PropTypes.func.isRequired,
};
