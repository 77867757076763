import { history } from '@utils';
import { alertActions } from '@actions';
import { patientServices } from '@services';
import patientConstants from './types';

const login = (email, password) => {
  return dispatch => {
    dispatch(loginRequest({ email }));

    patientServices.login(email, password).then(res => {
      if (res.error) {
        dispatch(loginFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(loginSuccess(res));
        history.push('/');
      }
    });
  };
};

const loginWithFacebook = token => {
  return dispatch => {
    dispatch(facebookRequest(token));
    patientServices.facebook(token).then(res => {
      if (res.error) {
        dispatch(facebookFailure(res.errors));
        dispatch(
          alertActions.error(res.errors.message || 'there is some thing wrong')
        );
        dispatch(alertActions.clear());
      } else {
        dispatch(facebookSuccess(res));
        history.push('/');
      }
    });
  };
};

const register = user => {
  return dispatch => {
    dispatch(signupRequest(user));

    patientServices.register(user).then(res => {
      if (res.error) {
        dispatch(signupFailure(res.errors));
        dispatch(
          alertActions.error(
            (res.errors && res.errors.email) ||
              'An Error occured, please try again!'
          )
        );
        dispatch(alertActions.clear());
      } else {
        dispatch(signupSuccess(res.user));
        dispatch(clearForm());
        dispatch(
          alertActions.success(
            'A verification link has been sent to your email!'
          )
        );
        dispatch(alertActions.clear());
        history.push('/verify-mobile');
      }
    });
  };
};

const addMobile = ({ mobile, token }) => {
  return dispatch => {
    dispatch(addMobileRequest({ mobile, token }));

    patientServices.addMobile({ mobile, token }).then(res => {
      if (res.error) {
        dispatch(addMobileFailure(res.errors));
        dispatch(
          alertActions.error(
            (res.errors && res.errors.mobile) ||
              'An Error occured, please try again!'
          )
        );
        dispatch(alertActions.clear());
      } else {
        dispatch(addMobileSuccess(res.user));
        dispatch(clearForm());
        dispatch(alertActions.success('your mobile added'));
        dispatch(alertActions.clear());
        history.push(`/verify-mobile/${mobile}`);
      }
    });
  };
};

const updateProfile = userInfo => {
  return dispatch => {
    dispatch(updateProfileRequest(userInfo));

    patientServices.updateProfile(userInfo).then(res => {
      if (res.error) {
        dispatch(updateProfileFailure(res.errors));
        dispatch(
          alertActions.error(
            (res.errors && res.errors.mobile) ||
              'An Error occured, please try again!'
          )
        );
        dispatch(alertActions.clear());
      } else {
        dispatch(updateProfileSuccess(res.user));
        dispatch(clearForm());
        dispatch(alertActions.success('Your Profile Info Updated'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const resendCode = token => {
  return dispatch => {
    dispatch(resendCodeRequest(token));

    patientServices.resendCode(token).then(res => {
      if (res.error) {
        dispatch(resendCodeFailure(res.errors));
        dispatch(
          alertActions.error('Whoops, looks like something went wrong.')
        );
        dispatch(alertActions.clear());
      } else {
        dispatch(resendCodeSuccess(res.res));
        dispatch(clearForm());
        dispatch(alertActions.success('Send success'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const verify = ({ code }) => {
  return dispatch => {
    dispatch(verificationRequest());

    patientServices.verify({ code }).then(
      res => {
        if (res.error) {
          dispatch(
            alertActions.error(
              res.errors.message || 'verification error occured!'
            )
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(verificationSuccess(res));
          dispatch(alertActions.success('verification success, login now!'));
          dispatch(alertActions.clear());
          history.push('/');
        }
      },
      error => {
        dispatch(verificationFailure(error));
        dispatch(
          alertActions.error(error || 'An Error occured, please try again!')
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const logout = () => {
  patientServices.logout();
  return { type: patientConstants.LOGOUT };
};

const resetPassword = email => {
  return dispatch => {
    dispatch(passwordResetRequest());
    patientServices.resetPassword(email).then(
      ({ success }) => {
        dispatch(passwordResetSuccess());
        dispatch(alertActions.success('password reset email sent!'));
        dispatch(alertActions.clear());
        dispatch(clearForm());
      },
      error => {
        dispatch(passwordResetFailure(error));
        dispatch(
          alertActions.error(
            error.email || 'An Error occured, please try again!'
          )
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const changePassword = ({ oldPassword, password }) => {
  return dispatch => {
    dispatch(passwordChangeRequest());
    patientServices.changePassword({ oldPassword, password }).then(
      res => {
        if (res.error) {
          dispatch(alertActions.error('password change error occured!'));
          dispatch(alertActions.clear());
        } else {
          dispatch(passwordChangeSuccess());
          dispatch(alertActions.success('password successfully changed!'));
          dispatch(alertActions.clear());
          dispatch(clearForm());
        }
      },
      error => {
        dispatch(passwordChangeFailure(error));
        dispatch(
          alertActions.error(error || 'An Error occured, please try again!')
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const generateInformationShareToken = () => {
  return dispatch => {
    dispatch(generateInformationShareTokenRequest());
    patientServices.generateInformationShareToken().then(res => {
      if (res.error) {
        dispatch(generateInformationShareTokenFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(generateInformationShareTokenSuccess(res.user));
        dispatch(alertActions.clear());
      }
    });
  };
};

const stopInformationShareToken = () => {
  return dispatch => {
    dispatch(stopInformationShareTokenRequest());
    patientServices.stopInformationShareToken().then(res => {
      if (res.error) {
        dispatch(stopInformationShareTokenFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(stopInformationShareTokenSuccess(res.user));
        dispatch(alertActions.clear());
      }
    });
  };
};

const loginRequest = user => ({ type: patientConstants.LOGIN_REQUEST, user });
const loginSuccess = user => ({ type: patientConstants.LOGIN_SUCCESS, user });
const loginFailure = error => ({ type: patientConstants.LOGIN_FAILURE, error });

const resendCodeRequest = user => ({
  type: patientConstants.RESEND_CODE_REQUEST,
  user,
});
const resendCodeSuccess = user => ({
  type: patientConstants.RESEND_CODE_SUCCESS,
  user,
});
const resendCodeFailure = error => ({
  type: patientConstants.RESEND_CODE_FAILURE,
  error,
});

const facebookRequest = user => ({
  type: patientConstants.FACEBOOK_LOGIN_REQUEST,
  user,
});
const facebookSuccess = user => ({
  type: patientConstants.FACEBOOK_LOGIN_SUCCESS,
  user,
});
const facebookFailure = error => ({
  type: patientConstants.FACEBOOK_LOGIN_FAILURE,
  error,
});

const verificationRequest = user => ({
  type: patientConstants.VERIFY_USER_REQUEST,
  user,
});
const verificationSuccess = user => ({
  type: patientConstants.VERIFY_USER_SUCCESS,
  user,
});
const verificationFailure = error => ({
  type: patientConstants.VERIFY_USER_FAILURE,
  error,
});

const passwordResetRequest = user => ({
  type: patientConstants.PASSWORD_RESET_USER_REQUEST,
  user,
});
const passwordResetSuccess = user => ({
  type: patientConstants.PASSWORD_RESET_USER_SUCCESS,
  user,
});
const passwordResetFailure = error => ({
  type: patientConstants.PASSWORD_RESET_USER_FAILURE,
  error,
});

const passwordChangeRequest = () => ({
  type: patientConstants.PASSWORD_CHANGE_USER_REQUEST,
});

const passwordChangeSuccess = user => ({
  type: patientConstants.PASSWORD_CHANGE_USER_SUCCESS,
  user,
});
const passwordChangeFailure = error => ({
  type: patientConstants.PASSWORD_CHANGE_USER_FAILURE,
  error,
});

const signupRequest = user => ({
  type: patientConstants.SIGN_UP_REQUEST,
  user,
});
const signupSuccess = user => ({
  type: patientConstants.SIGN_UP_SUCCESS,
  user,
});
const signupFailure = error => ({
  type: patientConstants.SIGN_UP_FAILURE,
  error,
});

const addMobileRequest = mobile => ({
  type: patientConstants.ADD_MOBILE_REQUEST,
  mobile,
});
const addMobileSuccess = mobile => ({
  type: patientConstants.ADD_MOBILE_SUCCESS,
  mobile,
});
const addMobileFailure = error => ({
  type: patientConstants.ADD_MOBILE_FAILURE,
  error,
});

const updateProfileRequest = () => ({
  type: patientConstants.UPDATE_PROFILE_REQUEST,
});
const updateProfileSuccess = userInfo => ({
  type: patientConstants.UPDATE_PROFILE_SUCCESS,
  user: userInfo,
});
const updateProfileFailure = error => ({
  type: patientConstants.UPDATE_PROFILE_FAILURE,
  error,
});
const generateInformationShareTokenRequest = () => ({
  type: patientConstants.GENERATE_SHARE_TOKEN_REQUEST,
});
const generateInformationShareTokenSuccess = user => ({
  type: patientConstants.GENERATE_SHARE_TOKEN_SUCCESS,
  user,
});
const generateInformationShareTokenFailure = error => ({
  type: patientConstants.GENERATE_SHARE_TOKEN_FAILURE,
  error,
});
const stopInformationShareTokenRequest = () => ({
  type: patientConstants.STOP_SHARE_TOKEN_REQUEST,
});
const stopInformationShareTokenSuccess = user => ({
  type: patientConstants.STOP_SHARE_TOKEN_SUCCESS,
  user,
});
const stopInformationShareTokenFailure = error => ({
  type: patientConstants.STOP_SHARE_TOKEN_FAILURE,
  error,
});
const clearForm = () => ({ type: patientConstants.CLEAR_FORM });

export const patientActions = {
  register,
  verify,
  login,
  logout,
  clearForm,
  resetPassword,
  changePassword,
  addMobile,
  resendCode,
  loginWithFacebook,
  updateProfile,
  generateInformationShareToken,
  stopInformationShareToken,
};
