const getMyConsultationRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/patient/consultation-request`, requestOptions)
    .then(consultationRequests => {
      return { error: false, consultationRequests };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSingleConsultation = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/patient/consultation-request/single/${id}`, requestOptions)
    .then(consultation => {
      return { error: false, consultation };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addConsultationRequest = consultation => {
  const raw = JSON.stringify(consultation);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };
  return fetch(`/patient/consultation-request?test=1`, requestOptions)
    .then(consultation => {
      return { error: false, consultation };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getMyConsultationRequests,
  getSingleConsultation,
  addConsultationRequest,
};
