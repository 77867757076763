import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Lab, PlusIcon } from '@images';
import { patientConditionActions, consultationActions } from '@actions';
import { Breadcrumb, H1, Add, Pagination, StateCard, Modal } from '@utils';
import { Container, RequestMedicalRecordForm } from '@components';
import styled from '@emotion/styled/macro';

const ConditionsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #5cb3db;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class Conditions extends Component {
  componentDidMount() {
    const {
      getConditions,
      getSharedConditions,
      match: {
        params: { patientId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedConditions(patientId);
    } else if (patientId && sharedToken) {
      getSharedConditions(patientId, sharedToken);
    } else {
      getConditions();
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = page => {
    const {
      getConditions,
      match: {
        params: { patientId },
      },
      getSharedConditions,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedConditions(patientId, null, page);
    } else if (patientId && sharedToken) {
      getSharedConditions(patientId, sharedToken, page);
    } else {
      getConditions(page);
    }
  };

  render() {
    const {
      conditions,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
    } = this.props;
    const { showModal } = this.state;
    const userType = localStorage.getItem('userType');
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : RequestConsultation
      ? [{ item: 'Request Consultation ', url: '/request-consultation' }]
      : [{ item: 'Medical Record ', url: '/#MedicalRecord' }];

    return (
      <Container>
        <ConditionsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Conditions</H1>

          {userType === 'consultant' && !sharedToken && (
            <Link
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request Conditions
            </Link>
          )}
          {userType === 'patient' && !sharedToken && (
            <Add
              to={`/condition/new${
                RequestConsultation ? '?RequestConsultation=1' : ''
              }`}
            />
          )}
          {conditions &&
            conditions.data.map(condition => (
              <StateCard
                to={
                  userType === 'consultant'
                    ? `/consultation/${consultationId}/condition/${condition.id}?patientId=${patientId}`
                    : sharedToken
                    ? `/conditions/${condition.id}?patientId=${patientId}&sharedToken=${sharedToken}`
                    : `/conditions/${condition.id}`
                }
                key={condition.createdAt}
                title={condition.value}
                time={condition.createdAt}
              />
            ))}

          {conditions && conditions.meta.total > conditions.meta.per_page && (
            <Pagination
              pageSize={conditions.meta.per_page}
              total={conditions.meta.total}
              setPage={this.setPage}
            />
          )}
          <Modal
            title={'Request Condition'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'condition'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </ConditionsWrapper>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  const { conditions } = state.condition;
  return {
    conditions,
  };
};

const mapDispatchToProps = dispatch => ({
  getConditions: (page = null) =>
    dispatch(patientConditionActions.getConditions(page)),
  getSharedConditions: (id, sharedToken = null, page = null) =>
    dispatch(
      patientConditionActions.getSharedConditions(id, sharedToken, page)
    ),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

Conditions.propTypes = {
  getConditions: PropTypes.func.isRequired,
  conditions: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedConditions: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
