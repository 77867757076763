import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Lab, PlusIcon } from '@images';
import { patientRadiologistActions, consultationActions } from '@actions';
import { Breadcrumb, H1, Add, Pagination, StateCard, Modal } from '@utils';
import { Container, RequestMedicalRecordForm } from '@components';
import styled from '@emotion/styled/macro';

const CTsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #5cb3db;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class CTs extends Component {
  componentDidMount() {
    const {
      getCTs,
      match: {
        params: { patientId },
      },
      getSharedCTs,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedCTs(patientId);
    } else if (patientId && sharedToken) {
      getSharedCTs(patientId, sharedToken);
    } else {
      getCTs();
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = page => {
    const {
      getCTs,
      match: {
        params: { patientId },
      },
      getSharedCTs,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedCTs(patientId, null, page);
    } else if (patientId && sharedToken) {
      getSharedCTs(patientId, sharedToken, page);
    } else {
      getCTs(page);
    }
  };

  render() {
    const {
      cts,
      match: {
        params: { consiltionId, patientId },
      },
      requestScan,
    } = this.props;
    const { showModal } = this.state;
    const userType = localStorage.getItem('userType');
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');
    return (
      <Container>
        <CTsWrapper>
          <Breadcrumb
            links={[{ item: 'Medical Record ', url: '/#MedicalRecord' }]}
          />
          <H1>CT</H1>
          {userType === 'consultant' && !sharedToken && (
            <Link
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request CT
            </Link>
          )}
          {userType === 'patient' && !sharedToken && (
            <Add
              to={`/cts/new${
                RequestConsultation ? '?RequestConsultation=1' : ''
              }`}
            />
          )}

          {cts &&
            cts.data.map(report => (
              <StateCard
                location={`http://209.97.130.101/u-dicom-viewer?fileUrl=https://api.hk.phantasm.biz/storage/${report.fileLocation}`}
                key={report.createdAt}
                title={report.title}
                time={report.createdAt}
                patientId={patientId}
              />
            ))}
          {cts && cts.meta.total > cts.meta.per_page && (
            <Pagination
              pageSize={cts.meta.per_page}
              total={cts.meta.total}
              setPage={this.setPage}
            />
          )}
          <Modal
            title={'Request CT'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'radiology-ct'}
                consultationId={Number(consiltionId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </CTsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const {
    ct: { cts },
  } = state.radiologist;
  return {
    cts,
  };
};

const mapDispatchToProps = dispatch => ({
  getCTs: (page = null) => dispatch(patientRadiologistActions.getCTs(page)),
  getSharedCTs: (patientId, sharedToken, page = null) =>
    dispatch(
      patientRadiologistActions.getSharedCTs(patientId, sharedToken, page)
    ),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

CTs.propTypes = {
  getCTs: PropTypes.func.isRequired,
  cts: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedCTs: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CTs);
