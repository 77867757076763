import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { Colors } from '@utils';

const Wrapper = styled.div`
  background: ${Colors.white};
  width: ${props => (props.width ? `${props.width}px` : '550px')};
  margin: 0 auto;
  padding: 30px 70px;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  @media (max-width: 767px) {
    width: 100%;
    padding: 15px 35px;
  }
  legend {
    color: #474747;
    font-family: DINNextLTW23;
    font-size: 30px;
    font-weight: bold;
    border: none;
    margin-bottom: 10px;
    span {
      display: block;
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: normal;
      margin-top: 15px;
      button {
        background: none;
        border: none;
        color: #878787;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .ant-select-selection,
  input {
    height: 50px;
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #bfbfbf;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.black};
  }
  .ant-input-password {
    width: 100%;
    display: inline-block;
    span {
      position: absolute;
      top: 50%;
      right: 5%;
      line-height: 1;
      transform: translateY(-50%);
    }
  }
  a {
    color: ${Colors.black};
    margin: 15px 0;
    display: inline-block;
  }
  .register-button {
    text-align: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
    &.submit-button {
      margin: 30px 0 0 0;
    }
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-input-group-addon {
    width: 110px;
    padding: 0;
    .ant-select.ant-select-enabled {
      width: 100% !important;
    }
  }
  .countryIcons {
    width: 24px;
    height: 24px;
  }
`;

export default function FormWrapper(props) {
  return (
    <Wrapper className="Form-wrapper" width={props.width ? props.width : null}>
      {props.children}
    </Wrapper>
  );
}

FormWrapper.propTypes = {
  children: PropTypes.element,
  width: PropTypes.number,
};
