const consultantConstants = {
  LOGIN_REQUEST: 'CONSULTANT_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'CONSULTANT_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'CONSULTANT_LOGIN_FAILURE',
  SIGN_UP_REQUEST: 'CONSULTANT_SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'CONSULTANT_SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'CONSULTANT_SIGN_UP_FAILURE',
  UPDATE_PROFILE_REQUEST: 'CONSULTANT_UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'CONSULTANT_UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'CONSULTANT_UPDATE_PROFILE_FAILURE',
  UPDATE_PROFILE_PICTURE_REQUEST: 'CONSULTANT_UPDATE_PROFILE_PICTURE_REQUEST',
  UPDATE_PROFILE_PICTURE_SUCCESS: 'CONSULTANT_UPDATE_PROFILE_PICTURE_SUCCESS',
  UPDATE_PROFILE_PICTURE_FAILURE: 'CONSULTANT_UPDATE_PROFILE_PICTURE_FAILURE',
  VERIFY_CONSULTANT_REQUEST: 'VERIFY_CONSULTANT_REQUEST',
  VERIFY_CONSULTANT_SUCCESS: 'VERIFY_CONSULTANT_SUCCESS',
  VERIFY_CONSULTANT_FAILURE: 'VERIFY_CONSULTANT_FAILURE',
  RESEND_CODE_REQUEST: 'CONSULTANT_RESEND_CODE_REQUEST',
  RESEND_CODE_SUCCESS: 'CONSULTANT_RESEND_CODE_SUCCESS',
  RESEND_CODE_FAILURE: 'CONSULTANT_RESEND_CODE_FAILURE',
  PASSWORD_RESET_CONSULTANT_REQUEST: 'PASSWORD_RESET_CONSULTANT_REQUEST',
  PASSWORD_RESET_CONSULTANT_SUCCESS: 'PASSWORD_RESET_CONSULTANT_SUCCESS',
  PASSWORD_RESET_CONSULTANT_FAILURE: 'PASSWORD_RESET_CONSULTANT_FAILURE',
  PASSWORD_CHANGE_CONSULTANT_REQUEST: 'PASSWORD_CHANGE_CONSULTANT_REQUEST',
  PASSWORD_CHANGE_CONSULTANT_SUCCESS: 'PASSWORD_CHANGE_CONSULTANT_SUCCESS',
  PASSWORD_CHANGE_CONSULTANT_FAILURE: 'PASSWORD_CHANGE_CONSULTANT_FAILURE',
  GET_CONSULTATION_TYPE_REQUEST: 'GET_CONSULTATION_TYPE_REQUEST',
  GET_CONSULTATION_TYPE_SUCCESS: 'GET_CONSULTATION_TYPE_SUCCESS',
  GET_CONSULTATION_TYPE_FAILURE: 'GET_CONSULTATION_TYPE_FAILURE',
  GET_PATIENT_TYPE_REQUEST: 'GET_PATIENT_TYPE_REQUEST',
  GET_PATIENT_TYPE_SUCCESS: 'GET_PATIENT_TYPE_SUCCESS',
  GET_PATIENT_TYPE_FAILURE: 'GET_PATIENT_TYPE_FAILURE',
  GET_MY_ACTIVITY_REQUEST: 'GET_MY_ACTIVITY_REQUEST',
  GET_MY_ACTIVITY_SUCCESS: 'GET_MY_ACTIVITY_SUCCESS',
  GET_MY_ACTIVITY_FAILURE: 'GET_MY_ACTIVITY_FAILURE',
  LOGOUT: 'CONSULTANT_LOGOUT',
  // messages
  CONSULTANT_UNVERIFIED_MESSAGE:
    'Please verify your email address to access the appliction',
  EXPIRED_TOKEN: 'Unauthorized: Expired token',
  CLEAR_FORM: 'CLEAR_FORM',
};

export default consultantConstants;
