const patientAllergiesConstants = {
  GET_ALLERGIES_REQUEST: 'GET_ALLERGIES_REQUEST',
  GET_ALLERGIES_SUCCESS: 'GET_ALLERGIES_SUCCESS',
  GET_ALLERGIES_FAILURE: 'GET_ALLERGIES_FAILURE',
  GET_ALLERGY_REQUEST: 'GET_ALLERGY_REQUEST',
  GET_ALLERGY_SUCCESS: 'GET_ALLERGY_SUCCESS',
  GET_ALLERGY_FAILURE: 'GET_ALLERGY_FAILURE',
  GET_PATIENT_ALLERGY_REQUEST: 'GET_PATIENT_ALLERGY_REQUEST',
  GET_PATIENT_ALLERGY_SUCCESS: 'GET_PATIENT_ALLERGY_SUCCESS',
  GET_PATIENT_ALLERGY_FAILURE: 'GET_PATIENT_ALLERGY_FAILURE',
  GET_SHARED_ALLERGIES_REQUEST: 'GET_SHARED_ALLERGIES_REQUEST',
  GET_SHARED_ALLERGIES_SUCCESS: 'GET_SHARED_ALLERGIES_SUCCESS',
  GET_SHARED_ALLERGIES_FAILURE: 'GET_SHARED_ALLERGIES_FAILURE',
  GET_SHARED_ALLERGY_REQUEST: 'GET_SHARED_ALLERGY_REQUEST',
  GET_SHARED_ALLERGY_SUCCESS: 'GET_SHARED_ALLERGY_SUCCESS',
  GET_SHARED_ALLERGY_FAILURE: 'GET_SHARED_ALLERGY_FAILURE',
  ADD_ALLERGIES_REQUEST: 'ADD_ALLERGIES_REQUEST',
  ADD_ALLERGIES_SUCCESS: 'ADD_ALLERGIES_SUCCESS',
  ADD_ALLERGIES_FAILURE: 'ADD_ALLERGIES_FAILURE',
  UPDATE_ALLERGIES_REQUEST: 'UPDATE_ALLERGIES_REQUEST',
  UPDATE_ALLERGIES_SUCCESS: 'UPDATE_ALLERGIES_SUCCESS',
  UPDATE_ALLERGIES_FAILURE: 'UPDATE_ALLERGIES_FAILURE',
};

export default patientAllergiesConstants;
