import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import { Form, Input, Row, Col } from 'antd';

import { H1, getItemFromLocalStorge, removeItemFromLocalStorge } from '@utils';
import { StepAction } from './';
import { patientReadingActions, patientConsultationsActions } from '@actions';
import {
  BloodPressure2X,
  BloodPressure,
  BloodSugar2X,
  BloodSugar,
  HeartRate,
  HeartRate2X,
  Height,
  Height2X,
  Temperature,
  Temperature2X,
  Weight,
  Weight2X,
  ReadingIcon,
  ReadingIcon2X,
} from '@images';

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  .header {
    &-image {
      margin-right: 35px;
    }
    &-content {
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1;
        text-align: left;
      }
      p {
        font-size: 16.8px;
        font-weight: normal;
        margin: 0;
        line-height: 1;
        text-align: left;
      }
    }
  }
`;

const ReadingsWrapper = styled.div`
  width: 650px;
  margin: auto;
  order: 1;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormWrapper = styled.div`
  .ant-col-12 {
    padding-right: 5px;
    & + .ant-col-12 {
      padding-right: 0;
      padding-left: 5px;
    }
  }
  .ant-form-item {
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    padding: 10px;
    * {
      line-height: 1;
    }
    label::after {
      content: none;
    }
    .ant-form-item-control-wrapper {
      float: right;
      width: 65%;
    }
    .ant-form-item-label {
      float: left;
      width: 35%;
      label {
        display: flex;
        align-items: center;
        img,
        picture {
          width: 40px;
          height: 34px;
        }
      }
    }
  }
  .ant-form-item-with-help {
    border-color: red;
    position: relative;
    margin-bottom: 30px;
    .has-error,
    .ant-col,
    .ant-form-item-control,
    .ant-form-item-children {
      position: static;
    }

    .ant-form-explain {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      bottom: -27px;
    }
  }
  .ant-form-item-children {
    input,
    .ant-input-group-addon {
      border: 0;
      background: #fff;

      &:focus {
        box-shadow: none;
      }
    }
    input {
      text-align: right;
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .ant-input-group-addon {
      font-family: Heebo;
      font-size: 15px;
      font-weight: 500;
      color: #9298ac;
      padding-left: 0;
    }
    p {
      margin: 0;
    }
    label,
    .ant-form-item-label label {
      display: flex;
      align-items: center;
      img,
      picture {
        width: 40px;
        height: 34px;
      }
    }
  }
`;

export class Readings extends Component {
  componentDidMount() {
    const { readings, getReadings } = this.props;
    if (!readings) {
      getReadings();
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      next,
      addMultiReadings,
      readings,
      addConsultationRequest,
    } = this.props;
    const readingObject = this.generateReadingObject(readings);
    validateFields((err, values) => {
      if (!err) {
        const readingsValues = [
          {
            id: (readingObject.height && readingObject.height.id) || null,
            type: 'height',
            value: values.height,
          },
          {
            id: (readingObject.weight && readingObject.weight.id) || null,
            type: 'weight',
            value: values.weight,
          },
          {
            id:
              (readingObject.bloodPressure && readingObject.bloodPressure.id) ||
              null,
            type: 'blood-pressure',
            value: values.bloodPressure,
          },
          {
            id: (readingObject.heartRate && readingObject.heartRate.id) || null,
            type: 'heart-rate',
            value: values.heartRate,
          },
          {
            id:
              (readingObject.bloodSugar && readingObject.bloodSugar.id) || null,
            type: 'blood-sugar',
            value: values.bloodSugar,
          },
          {
            id:
              (readingObject.temperature && readingObject.temperature.id) ||
              null,
            type: 'temperature',
            value: values.temperature,
          },
        ];
        const consultation = JSON.parse(getItemFromLocalStorge('consultation'));
        addMultiReadings(readingsValues);
        addConsultationRequest(consultation);
        removeItemFromLocalStorge('consultation');
        next();
      }
    });
  };
  generateReadingObject = readings => {
    const readingObject = {
      bloodPressure: null,
      weight: null,
      heartRate: null,
      temperature: null,
      bloodSugar: null,
      height: null,
    };
    readings.map(read => {
      switch (read.type) {
        case 'blood-pressure':
          readingObject.bloodPressure = {
            value: read.value,
            id: read.id,
          };

          break;
        case 'weight':
          readingObject.weight = {
            value: read.value,
            id: read.id,
          };

          break;
        case 'heart-rate':
          readingObject.heartRate = {
            value: read.value,
            id: read.id,
          };

          break;
        case 'temperature':
          readingObject.temperature = {
            value: read.value,
            id: read.id,
          };

          break;
        case 'blood-sugar':
          readingObject.bloodSugar = {
            value: read.value,
            id: read.id,
          };

          break;
        case 'height':
          readingObject.height = {
            value: read.value,
            id: read.id,
          };

          break;
        default:
          break;
      }
      return readingObject;
    });
    return readingObject;
  };
  render() {
    const {
      stepsLength,
      prev,
      form: { getFieldDecorator },
      readings,
    } = this.props;
    let readingObject;
    if (readings) {
      readingObject = this.generateReadingObject(readings);
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <ReadingsWrapper>
          <HeaderWrapper>
            <div className="header-image">
              <picture>
                <source srcSet={`${ReadingIcon2X} 2x`} />
                <img src={ReadingIcon} alt={'Reading Icon'} />
              </picture>
            </div>
            <div className="header-content">
              <H1>Readings</H1>
              <p>Add your Readings</p>
            </div>
          </HeaderWrapper>
          <FormWrapper>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      <picture>
                        <source srcSet={`${Weight2X} 2x`} />
                        <img src={Weight} alt={'Weight'} />
                      </picture>
                      <span>Weight</span>
                    </>
                  }
                >
                  {getFieldDecorator('weight', {
                    initialValue:
                      readingObject &&
                      readingObject.weight &&
                      readingObject.weight.value,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Weight !',
                      },
                    ],
                  })(<Input type="number" addonAfter={<p>KG</p>} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      <picture>
                        <source srcSet={`${Height2X} 2x`} />
                        <img src={Height} alt={'Height'} />
                      </picture>
                      <span>Height</span>
                    </>
                  }
                >
                  {getFieldDecorator('height', {
                    initialValue:
                      readingObject &&
                      readingObject.height &&
                      readingObject.height.value,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Height !',
                      },
                    ],
                  })(<Input type="number" addonAfter={<p>CM</p>} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      <picture>
                        <source srcSet={`${BloodPressure2X} 2x`} />
                        <img src={BloodPressure} alt={'Blood Pressure'} />
                      </picture>
                      <span>Blood Pressure</span>
                    </>
                  }
                >
                  {getFieldDecorator('bloodPressure', {
                    initialValue:
                      readingObject &&
                      readingObject.bloodPressure &&
                      readingObject.bloodPressure.value,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Blood Pressure !',
                      },
                      {
                        message: 'Please input Correct Blood Pressure !',
                        pattern: new RegExp('([0-9])/([0-9])'),
                      },
                    ],
                  })(<Input addonAfter={<p>mmHg</p>} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      <picture>
                        <source srcSet={`${HeartRate2X} 2x`} />
                        <img src={HeartRate} alt={'Heart Rate'} />
                      </picture>
                      <span>Heart Rate</span>
                    </>
                  }
                >
                  {getFieldDecorator('heartRate', {
                    initialValue:
                      readingObject &&
                      readingObject.heartRate &&
                      readingObject.heartRate.value,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Heart Rate !',
                      },
                    ],
                  })(<Input type="number" addonAfter={<p>BPM</p>} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      <picture>
                        <source srcSet={`${Temperature2X} 2x`} />
                        <img src={Temperature} alt={'Temperature'} />
                      </picture>
                      <span>Temperature</span>
                    </>
                  }
                >
                  {getFieldDecorator('temperature', {
                    initialValue:
                      readingObject &&
                      readingObject.temperature &&
                      readingObject.temperature.value,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Temperature !',
                      },
                    ],
                  })(<Input type="number" addonAfter={<p>°C</p>} />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <>
                      <picture>
                        <source srcSet={`${BloodSugar2X} 2x`} />
                        <img src={BloodSugar} alt={'Blood Sugar'} />
                      </picture>
                      <span>Blood Sugar</span>
                    </>
                  }
                >
                  {getFieldDecorator('bloodSugar', {
                    initialValue:
                      readingObject &&
                      readingObject.bloodSugar &&
                      readingObject.bloodSugar.value,
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Blood Sugar !',
                      },
                    ],
                  })(<Input type="number" addonAfter={<p>mg/dL</p>} />)}
                </Form.Item>
              </Col>
            </Row>
          </FormWrapper>
        </ReadingsWrapper>
        <StepAction stepsLength={stepsLength} prev={prev} />
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { readings } = state.readings;
  return {
    readings,
  };
};

const mapDispatchToProps = dispatch => ({
  getReadings: () => dispatch(patientReadingActions.getReadings()),
  addMultiReadings: readings =>
    dispatch(patientReadingActions.addMultiReadings(readings)),
  addConsultationRequest: consultation =>
    dispatch(patientConsultationsActions.addConsultationRequest(consultation)),
});

export default Form.create({ name: 'Reading_form' })(
  connect(mapStateToProps, mapDispatchToProps)(Readings)
);

Readings.propTypes = {
  form: PropTypes.object.isRequired,
  readings: PropTypes.array,
  getReadings: PropTypes.func.isRequired,
  addConsultationRequest: PropTypes.func.isRequired,
  stepsLength: PropTypes.number.isRequired,
  addMultiReadings: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
