import { paymentConstants } from '@actions/types';

const initialState = {};

export function payment(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.MAK_PAYMENT_REQUEST:
      return {
        ...state,
        makePaymentRequest: true,
      };
    case paymentConstants.MAK_PAYMENT_SUCCESS:
      return {
        makePayment: true,
        paymentDetails: action.payDetails,
      };
    case paymentConstants.MAK_PAYMENT_FAILURE:
      return {
        error: action.error,
      };
    case paymentConstants.GET_CLIENT_SECRET_REQUEST:
      return {
        ...state,
        getClientSecret: true,
      };
    case paymentConstants.GET_CLIENT_SECRET_SUCCESS:
      return {
        clientSecret: action.clientSecret,
      };
    case paymentConstants.GET_CLIENT_SECRET_FAILURE:
      return {
        error: action.error,
      };
    case paymentConstants.GET_CARDS_LIST_REQUEST:
      return {
        ...state,
        getCardsList: true,
      };
    case paymentConstants.GET_CARDS_LIST_SUCCESS:
      return {
        cards: action.cards,
      };
    case paymentConstants.GET_CARDS_LIST_FAILURE:
      return {
        error: action.error,
      };
    case paymentConstants.DELETE_CARD_REQUEST:
      return {
        ...state,
        deleteCard: true,
      };
    case paymentConstants.DELETE_CARD_SUCCESS:
      return {
        card: action.card,
      };
    case paymentConstants.DELETE_CARD_FAILURE:
      return {
        error: action.error,
      };
    case paymentConstants.GET_FAILED_PAYMENT_REQUEST:
      return {
        ...state,
        failedPayment: true,
      };
    case paymentConstants.GET_FAILED_PAYMENT_SUCCESS:
      return {
        card: action.auth,
      };
    case paymentConstants.GET_FAILED_PAYMENT_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
