import React from 'react';

import { MainLayout } from '@components';
import NewAllergies from './NewAllergies.js';

function NewAllergiesPage(props) {
  return <MainLayout footer="main" children={<NewAllergies {...props} />} />;
}

export default NewAllergiesPage;
