import { patientRadiologistConstants } from '@actions/types';

const initialState = {
  ct: {},
  mri: {},
  xray: {},
};

export function radiologist(state = initialState, action) {
  switch (action.type) {
    // XRay
    case patientRadiologistConstants.GET_XRAYS_REQUEST:
      return {
        ...state,
        fetchingeXrays: true,
      };
    case patientRadiologistConstants.GET_XRAYS_SUCCESS:
      return {
        ...state,
        fetchedXrays: true,
        xray: {
          ...state.xray,
          xrays: action.xrays,
        },
      };
    case patientRadiologistConstants.GET_XRAYS_FAILURE:
      return {
        ...state,
        xray: {
          ...state.xray,
          error: action.error,
        },
      };
    case patientRadiologistConstants.GET_XRAY_REQUEST:
      return {
        ...state,
        fetchingeXray: true,
      };
    case patientRadiologistConstants.GET_XRAY_SUCCESS:
      return {
        ...state,
        fetchedXray: true,
        xray: {
          ...state.xray,
          xray: action.xray,
        },
      };
    case patientRadiologistConstants.GET_XRAY_FAILURE:
      return {
        ...state,
        xray: {
          ...state.xray,
          error: action.error,
        },
      };
    case patientRadiologistConstants.GET_SHARED_XRAYS_REQUEST:
      return {
        ...state,
        fetchingeSharedXrays: true,
      };
    case patientRadiologistConstants.GET_SHARED_XRAYS_SUCCESS:
      return {
        ...state,
        fetchedSharedXrays: true,
        xray: {
          ...state.xray,
          xrays: action.xrays,
        },
      };
    case patientRadiologistConstants.GET_SHARED_XRAYS_FAILURE:
      return {
        ...state,
        xray: {
          ...state.xray,
          error: action.error,
        },
      };
    case patientRadiologistConstants.ADD_XRAYS_REQUEST:
      return {
        ...state,
        addXrays: true,
      };
    case patientRadiologistConstants.ADD_XRAYS_SUCCESS:
      return {
        ...state,
        addedXrays: true,
        xray: {
          ...state.xray,
          xray: action.xray,
        },
      };
    case patientRadiologistConstants.ADD_XRAYS_FAILURE:
      return {
        ...state,
        xray: {
          ...state.xray,
          error: action.error,
        },
      };
    case patientRadiologistConstants.UPDATE_XRAYS_REQUEST:
      return {
        ...state,
        updateXray: true,
      };
    case patientRadiologistConstants.UPDATE_XRAYS_SUCCESS:
      return {
        ...state,
        updateecgs: true,
        xray: {
          ...state.xray,
          xray: action.xray,
        },
      };
    case patientRadiologistConstants.UPDATE_XRAYS_FAILURE:
      return {
        error: action.error,
        xray: {
          ...state.xray,
          error: action.error,
        },
      };

    //MRI
    case patientRadiologistConstants.GET_MRIS_REQUEST:
      return {
        ...state,
        fetchingeMRIs: true,
      };
    case patientRadiologistConstants.GET_MRIS_SUCCESS:
      return {
        ...state,
        fetchedMRIs: true,
        mri: {
          ...state.mri,
          mris: action.mris,
        },
      };
    case patientRadiologistConstants.GET_MRIS_FAILURE:
      return {
        ...state,
        mri: {
          ...state.mri,
          error: action.error,
        },
      };
    case patientRadiologistConstants.GET_MRI_REQUEST:
      return {
        ...state,
        fetchingeMRI: true,
      };
    case patientRadiologistConstants.GET_MRI_SUCCESS:
      return {
        ...state,
        fetchedMRI: true,
        mri: {
          ...state.mri,
          mri: action.mri,
        },
      };
    case patientRadiologistConstants.GET_MRI_FAILURE:
      return {
        ...state,
        mri: {
          ...state.mri,
          error: action.error,
        },
      };
    case patientRadiologistConstants.GET_SHARED_MRIS_REQUEST:
      return {
        ...state,
        fetchingeSharedMRIs: true,
      };
    case patientRadiologistConstants.GET_SHARED_MRIS_SUCCESS:
      return {
        ...state,
        fetchedSharedMRIs: true,
        mri: {
          ...state.mri,
          mris: action.mris,
        },
      };
    case patientRadiologistConstants.GET_SHARED_MRIS_FAILURE:
      return {
        ...state,
        mri: {
          ...state.mri,
          error: action.error,
        },
      };
    case patientRadiologistConstants.ADD_MRIS_REQUEST:
      return {
        ...state,
        addMRIs: true,
      };
    case patientRadiologistConstants.ADD_MRIS_SUCCESS:
      return {
        ...state,
        addedMRIs: true,
        mri: {
          ...state.mri,
          mri: action.mri,
        },
      };
    case patientRadiologistConstants.ADD_MRIS_FAILURE:
      return {
        ...state,
        mri: {
          ...state.mri,
          error: action.error,
        },
      };
    case patientRadiologistConstants.UPDATE_MRIS_REQUEST:
      return {
        ...state,
        updateMRI: true,
      };
    case patientRadiologistConstants.UPDATE_MRIS_SUCCESS:
      return {
        ...state,
        updateMRI: true,
        mri: {
          ...state.mri,
          mri: action.mri,
        },
      };
    case patientRadiologistConstants.UPDATE_MRIS_FAILURE:
      return {
        error: action.error,
        mri: {
          ...state.mri,
          error: action.error,
        },
      };

    // CTS
    case patientRadiologistConstants.GET_CTS_REQUEST:
      return {
        ...state,
        fetchingeCTs: true,
      };
    case patientRadiologistConstants.GET_CTS_SUCCESS:
      return {
        ...state,
        fetchedCTs: true,
        ct: {
          ...state.ct,
          cts: action.cts,
        },
      };
    case patientRadiologistConstants.GET_CTS_FAILURE:
      return {
        ...state,
        ct: {
          ...state.ct,
          error: action.error,
        },
      };
    case patientRadiologistConstants.GET_CT_REQUEST:
      return {
        ...state,
        fetchingeCT: true,
      };
    case patientRadiologistConstants.GET_CT_SUCCESS:
      return {
        ...state,
        fetchedCT: true,
        ct: {
          ...state.ct,
          ct: action.ct,
        },
      };
    case patientRadiologistConstants.GET_CT_FAILURE:
      return {
        ...state,
        ct: {
          ...state.ct,
          error: action.error,
        },
      };
    case patientRadiologistConstants.GET_SHARED_CTS_REQUEST:
      return {
        ...state,
        fetchingeSharedCTs: true,
      };
    case patientRadiologistConstants.GET_SHARED_CTS_SUCCESS:
      return {
        ...state,
        fetchedSharedCTs: true,
        ct: {
          ...state.ct,
          cts: action.cts,
        },
      };
    case patientRadiologistConstants.GET_SHARED_CTS_FAILURE:
      return {
        ...state,
        ct: {
          ...state.ct,
          error: action.error,
        },
      };
    case patientRadiologistConstants.ADD_CTS_REQUEST:
      return {
        ...state,
        addCTs: true,
      };
    case patientRadiologistConstants.ADD_CTS_SUCCESS:
      return {
        ...state,
        addedCTs: true,
        ct: {
          ...state.ct,
          ct: action.ct,
        },
      };
    case patientRadiologistConstants.ADD_CTS_FAILURE:
      return {
        ...state,
        ct: {
          ...state.ct,
          error: action.error,
        },
      };
    case patientRadiologistConstants.UPDATE_CTS_REQUEST:
      return {
        ...state,
        updateCT: true,
      };
    case patientRadiologistConstants.UPDATE_CTS_SUCCESS:
      return {
        ...state,
        updateCT: true,
        ct: {
          ...state.ct,
          ct: action.ct,
        },
      };
    case patientRadiologistConstants.UPDATE_CTS_FAILURE:
      return {
        error: action.error,
        ct: {
          ...state.ct,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
