import { patientConstants } from '@actions/types';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case patientConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case patientConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
      };
    case patientConstants.SIGN_UP_REQUEST:
      return {
        signUp: true,
      };
    case patientConstants.SIGN_UP_SUCCESS:
      return {
        user: { user: action.user },
      };
    case patientConstants.SIGN_UP_FAILURE:
      return {
        errors: { errors: action.error },
      };
    case patientConstants.LOGIN_FAILURE:
      return {
        errors: { errors: action.error },
      };
    case patientConstants.ADD_MOBILE_REQUEST:
      return {
        ...state,
        AddMobile: true,
      };
    case patientConstants.ADD_MOBILE_SUCCESS:
      return {
        ...state,
        mobile: { mobile: action.mobile },
      };
    case patientConstants.ADD_MOBILE_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case patientConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfile: true,
      };
    case patientConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: { user: action.user },
      };
    case patientConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case patientConstants.RESEND_CODE_REQUEST:
      return {
        ...state,
        resendCode: true,
      };
    case patientConstants.RESEND_CODE_SUCCESS:
      return {
        ...state,
        resendCode: 'success',
      };
    case patientConstants.RESEND_CODE_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case patientConstants.GENERATE_SHARE_TOKEN_REQUEST:
      return {
        ...state,
        genrateShareToken: true,
      };
    case patientConstants.GENERATE_SHARE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case patientConstants.GENERATE_SHARE_TOKEN_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case patientConstants.STOP_SHARE_TOKEN_REQUEST:
      return {
        ...state,
        stopShareToken: true,
      };
    case patientConstants.STOP_SHARE_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case patientConstants.STOP_SHARE_TOKEN_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case patientConstants.VERIFY_USER_FAILURE:
    case patientConstants.PASSWORD_RESET_USER_FAILURE:
    case patientConstants.PASSWORD_CHANGE_USER_FAILURE:
      return {};
    case patientConstants.LOGOUT:
      return {};
    case patientConstants.CLEAR_FORM:
      return { ...state, resetForm: true };
    default:
      return state;
  }
}
