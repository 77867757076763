import React from 'react';
import { Icon } from 'antd';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import { ProfilePic } from '@utils';

const UserProfileCardWrapper = styled.div`
  padding: 24px;
  text-align: center;
  h3 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
  button {
    background: #fff;
    border-radius: 6px;
    border: solid 0.5px #5cb3db;
    width: 100%;
    padding: 10px;
    color: #5cb3db;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    vertical-align: middle;
    .anticon {
      margin-right: 10px;
      font-size: 15px;
      line-height: 30px;

      vertical-align: bottom;
    }
  }
`;

const UserProfileCard = props => {
  return (
    <>
      <UserProfileCardWrapper
        style={{
          backgroundColor: '#fff',
          boxShadow: '',
          textAlign: 'center',
          padding: 24,
        }}
      >
        <ProfilePic
          user={props.user}
          style={{ borderRadius: '50%' }}
          width="100"
          height="100"
        />
        <h3>
          {props.user.firstName} {props.user.middleName} {props.user.lastName}
        </h3>
        <button
          type="primary"
          onClick={() => {
            if (!props.user.informationShareToken) {
              props.generateInformationShareToken();
            }
            props.setCurrentView('shareData');
          }}
        >
          <Icon type="share-alt" />
          Share Data
        </button>
      </UserProfileCardWrapper>
    </>
  );
};

export default UserProfileCard;

UserProfileCard.propTypes = {
  user: PropTypes.object,
  generateInformationShareToken: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired,
};
