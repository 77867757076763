import React, { Component } from 'react';
import BookAppointment from '../BookAppointment';
import Activity from '../Activity';

export default class SideBar extends Component {
  render() {
    const { activity } = this.props;
    return (
      <div>
        <Activity activity={activity} />
        <BookAppointment />
      </div>
    );
  }
}
