import { alertActions } from '@actions';
import { consultationService } from '@services';
import consultationConstants from './types';

const getNewConsultation = () => {
  return dispatch => {
    dispatch(getNewConsultationRequest());

    consultationService.getNewConsultation().then(res => {
      if (res.error) {
        dispatch(getNewConsultationFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(getNewConsultationSuccess(res.consultations));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAcceptedConsultation = () => {
  return dispatch => {
    dispatch(getAcceptedConsultationRequest());

    consultationService.getAcceptedConsultation().then(res => {
      if (res.error) {
        dispatch(getAcceptedConsultationFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAcceptedConsultationSuccess(res.consultations));
        dispatch(alertActions.clear());
      }
    });
  };
};

const acceptConsultation = id => {
  return dispatch => {
    dispatch(acceptConsultationRequest());

    consultationService.acceptConsultation(id).then(res => {
      if (res.error) {
        dispatch(acceptConsultationFailure(res.errors));
        dispatch(alertActions.error(res.errors));
      } else {
        dispatch(acceptConsultationSuccess(res.consultation));
        dispatch(alertActions.success('Your Just Accept The Consultation'));
      }
      dispatch(alertActions.clear());
    });
  };
};

const rejectConsultation = id => {
  return dispatch => {
    dispatch(rejectConsultationRequest());

    consultationService.rejectConsultation(id).then(res => {
      if (res.error) {
        dispatch(rejectConsultationFailure(res.errors));
        dispatch(alertActions.error(res.errors));
      } else {
        dispatch(rejectConsultationSuccess(res.consultation, id));
        dispatch(alertActions.success('Your Just Reject The Consultation'));
      }
      dispatch(alertActions.clear());
    });
  };
};

const getConsultation = id => {
  return dispatch => {
    dispatch(getConsultationRequest());

    consultationService.getConsultation(id).then(res => {
      if (res.error) {
        dispatch(getConsultationFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(getConsultationSuccess(res.consultation));
        dispatch(alertActions.clear());
      }
    });
  };
};

const requestScan = ({ scanType, id, title, body }) => {
  return dispatch => {
    dispatch(requestScanRequest());

    consultationService.requestScan({ scanType, id, title, body }).then(res => {
      if (res.error) {
        dispatch(requestScanFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(requestScanSuccess(res.scan));
        dispatch(
          alertActions.success(
            `${scanType.replace('_', ' ').replace('-', ' ')} has requested`
          )
        );
        dispatch(alertActions.clear());
      }
    });
  };
};

const sendReport = ({ title, note, consultationId }) => {
  return dispatch => {
    dispatch(sendReportRequest());

    consultationService
      .sendReport({ title, note, consultationId })
      .then(res => {
        if (res.error) {
          dispatch(sendReportFailure(res.errors));
          dispatch(alertActions.error(res.errors));
          dispatch(alertActions.clear());
        } else {
          dispatch(sendReportSuccess(res.report));
          dispatch(alertActions.success(`Your report has Send`));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getFinishedConsultations = () => {
  return dispatch => {
    dispatch(getFinishedConsultationsRequest());

    consultationService.getFinishedConsultations().then(res => {
      if (res.error) {
        dispatch(getFinishedConsultationsFailure(res.errors));
        dispatch(alertActions.error(res.errors));
        dispatch(alertActions.clear());
      } else {
        dispatch(getFinishedConsultationsSuccess(res.FinishedConsultations));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getNewConsultationRequest = () => ({
  type: consultationConstants.GET_CONSULTATIONS_REQUEST,
});

const getNewConsultationSuccess = consultations => {
  return {
    type: consultationConstants.GET_CONSULTATIONS_SUCCESS,
    consultations: consultations,
  };
};

const getNewConsultationFailure = error => ({
  type: consultationConstants.GET_CONSULTATIONS_FAILURE,
  error,
});

const getFinishedConsultationsRequest = () => ({
  type: consultationConstants.GET_FINISHED_CONSULTATIONS_REQUEST,
});

const getFinishedConsultationsSuccess = FinishedConsultations => {
  return {
    type: consultationConstants.GET_FINISHED_CONSULTATIONS_SUCCESS,
    FinishedConsultations,
  };
};

const getFinishedConsultationsFailure = error => ({
  type: consultationConstants.GET_FINISHED_CONSULTATIONS_FAILURE,
  error,
});

const getAcceptedConsultationRequest = () => ({
  type: consultationConstants.GET_ACCEPTED_CONSULTATION_REQUEST,
});

const getAcceptedConsultationSuccess = consultations => {
  return {
    type: consultationConstants.GET_ACCEPTED_CONSULTATION_SUCCESS,
    consultations: consultations,
  };
};

const getAcceptedConsultationFailure = error => ({
  type: consultationConstants.GET_ACCEPTED_CONSULTATION_FAILURE,
  error,
});

const acceptConsultationRequest = () => ({
  type: consultationConstants.ACCEPT_CONSULTATION_REQUEST,
});

const acceptConsultationSuccess = consultation => {
  return {
    type: consultationConstants.ACCEPT_CONSULTATION_SUCCESS,
    consultation: consultation,
  };
};

const acceptConsultationFailure = error => ({
  type: consultationConstants.ACCEPT_CONSULTATION_FAILURE,
  error,
});

const rejectConsultationRequest = () => ({
  type: consultationConstants.REJECT_CONSULTATION_REQUEST,
});

const rejectConsultationSuccess = (consultation, id) => {
  return {
    type: consultationConstants.REJECT_CONSULTATION_SUCCESS,
    consultation: consultation,
    consultationId: id,
  };
};

const rejectConsultationFailure = error => ({
  type: consultationConstants.REJECT_CONSULTATION_FAILURE,
  error,
});

const getConsultationRequest = () => ({
  type: consultationConstants.GET_CONSULTATION_REQUEST,
});

const getConsultationSuccess = consultation => {
  return {
    type: consultationConstants.GET_CONSULTATION_SUCCESS,
    consultation: consultation,
  };
};

const getConsultationFailure = error => ({
  type: consultationConstants.GET_CONSULTATION_FAILURE,
  error,
});

const requestScanRequest = () => ({
  type: consultationConstants.REQUEST_SCAN_REQUEST,
});

const requestScanSuccess = scan => {
  return {
    type: consultationConstants.REQUEST_SCAN_SUCCESS,
    scan: scan,
  };
};

const requestScanFailure = error => ({
  type: consultationConstants.REQUEST_SCAN_FAILURE,
  error,
});

const sendReportRequest = () => ({
  type: consultationConstants.SEND_REPORT_REQUEST,
});

const sendReportSuccess = report => {
  return {
    type: consultationConstants.SEND_REPORT_SUCCESS,
    report: report,
  };
};

const sendReportFailure = error => ({
  type: consultationConstants.SEND_REPORT_FAILURE,
  error,
});

export const consultationActions = {
  getNewConsultation,
  getConsultation,
  getAcceptedConsultation,
  acceptConsultation,
  rejectConsultation,
  requestScan,
  sendReport,
  getFinishedConsultations,
};
