import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Lab, PlusIcon } from '@images';
import { patientEcgsActions, consultationActions } from '@actions';
import { Breadcrumb, H1, Add, Pagination, StateCard, Modal } from '@utils';
import { Container, RequestMedicalRecordForm } from '@components';
import styled from '@emotion/styled/macro';

const ECGsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #5cb3db;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class ECGs extends Component {
  componentDidMount() {
    const {
      getECGs,
      getSharedECGs,
      match: {
        params: { patientId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedECGs(patientId);
    } else if (patientId && sharedToken) {
      getSharedECGs(patientId, sharedToken);
    } else {
      getECGs();
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = page => {
    const {
      getECGs,
      match: {
        params: { patientId },
      },
      getSharedECGs,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedECGs(patientId, null, page);
    } else if (patientId && sharedToken) {
      getSharedECGs(patientId, sharedToken, page);
    } else {
      getECGs(page);
    }
  };

  render() {
    const {
      ecgs,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
    } = this.props;
    const { showModal } = this.state;
    const userType = localStorage.getItem('userType');
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : RequestConsultation
      ? [{ item: 'Request Consultation ', url: '/request-consultation' }]
      : [{ item: 'Medical Record ', url: '/#MedicalRecord' }];

    return (
      <Container>
        <ECGsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>ECG</H1>
          {userType === 'consultant' && !sharedToken && (
            <Link
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request ECG
            </Link>
          )}
          {userType === 'patient' && !sharedToken && (
            <Add
              to={`/ecg/new${
                RequestConsultation ? '?RequestConsultation=1' : ''
              }`}
            />
          )}

          {ecgs &&
            ecgs.data.map(report => (
              <StateCard
                to={
                  userType === 'consultant'
                    ? `/consultation/${consultationId}/ecg/${report.id}?patientId=${patientId}`
                    : sharedToken
                    ? `/ecgs/${report.id}?patientId=${patientId}&sharedToken=${sharedToken}`
                    : `/ecgs/${report.id}`
                }
                key={report.createdAt}
                title={report.title}
                time={report.createdAt}
              />
            ))}
          {ecgs && ecgs.meta.total > ecgs.meta.per_page && (
            <Pagination
              pageSize={ecgs.meta.per_page}
              total={ecgs.meta.total}
              setPage={this.setPage}
            />
          )}
          <Modal
            title={'Request ECG'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'ecg'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </ECGsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { ecgs } = state.ecgs;
  return {
    ecgs,
  };
};

const mapDispatchToProps = dispatch => ({
  getECGs: (page = null) => dispatch(patientEcgsActions.getECGs(page)),
  getSharedECGs: (patientId, sharedToken, page = null) =>
    dispatch(patientEcgsActions.getSharedECGs(patientId, sharedToken, page)),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

ECGs.propTypes = {
  getECGs: PropTypes.func.isRequired,
  ecgs: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedECGs: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECGs);
