import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'antd';
import 'antd/lib/carousel/style/css';
import { paymentActions } from '@actions';
import { MasterCard, MasterCard2X, visa, visa2X } from '@images';
import EmptyPayment from './EmptyPayment';
import Card from './Card';

const PaymentWrapper = styled.div`
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
  }
  .slick-dots.slick-dots-bottom {
    bottom: -16px;
    li {
      button {
        background: #060c2b;
      }
    }
  }
`;

const PaymentButtons = styled.div`
  margin-top: 20px;
  text-align: center;
  a {
    display: inline-block;
    width: 48%;
    border-radius: 6px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 0.5px #5cb3db;
    background: none;
    padding: 10px;
    & + a {
      margin-left: 20px;
    }
  }
`;

const AddNewPayment = styled.div`
  margin-top: 60px;
  h3 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #5cb3db;
    margin-bottom: 10px;
  }
  p {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    color: #060c2b;
  }
`;
class Payment extends Component {
  componentDidMount() {
    const { getCardsList } = this.props;
    getCardsList();
  }
  render() {
    const { setCurrentView, cards, deleteCard } = this.props;
    console.log(cards);
    return (
      <PaymentWrapper className="profile-border">
        <h2>Payment</h2>
        {cards && cards.length > 0 ? (
          <Carousel autoplay>
            {cards.map(card => (
              <Card
                key={card.id}
                id={card.id}
                name="Xing Zheng"
                type={card.brand}
                lastNumber={card.last4}
                deleteCard={deleteCard}
              />
            ))}
          </Carousel>
        ) : (
          <EmptyPayment />
        )}

        <AddNewPayment>
          <h3>Add New Payment</h3>
          <p>Please enter your payment method</p>
        </AddNewPayment>

        <PaymentButtons>
          <Link
            onClick={() => {
              setCurrentView('addPayment');
            }}
            to="#!"
          >
            <picture>
              <source srcSet={`${visa2X} 2x`} />
              <img src={visa} alt="Visa Card" />
            </picture>
          </Link>
          <Link
            onClick={() => {
              setCurrentView('addPayment');
            }}
            to="#!"
          >
            <picture>
              <source srcSet={`${MasterCard2X} 2x`} />
              <img src={MasterCard} alt="Master Card" />
            </picture>
          </Link>
        </PaymentButtons>
      </PaymentWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { cards } = state.payment;
  return {
    cards,
  };
};

const mapDispatchToProps = dispatch => ({
  getCardsList: () => dispatch(paymentActions.getCardsList()),
  deleteCard: id => dispatch(paymentActions.deleteCard(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
Payment.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  getCardsList: PropTypes.func.isRequired,
  cards: PropTypes.array,
  deleteCard: PropTypes.func.isRequired,
};
