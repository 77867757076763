import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import { Form, Input } from 'antd';

import { Condition, Condition2X } from '@images';
import { patientConditionActions } from '@actions';
import { H1, Colors } from '@utils';
import { StepAction } from './';
const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  .header {
    &-image {
      margin-right: 35px;
      img,
      picture {
        height: 120px;
        width: 120px;
      }
    }
    &-content {
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1;
        text-align: left;
      }
    }
  }
`;

const ConditionsWrapper = styled.div`
  width: 650px;
  margin: auto;
  order: 1;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  input,
  .ant-input.text-area {
    height: 50px;
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #bfbfbf;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.black};
    &:focus {
      border: solid 2px ${Colors.black};
    }
  }
  .ant-input.text-area {
    min-height: 180px;
    resize: none;
  }
  .has-error {
    .ant-input {
      border: 1px solid red;
    }
  }
`;

class Conditions extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },

      next,
      setConditions,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        setConditions(values);
        next();
      }
    });
  };
  render() {
    const {
      stepsLength,
      prev,
      form: { getFieldDecorator },
    } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <ConditionsWrapper>
          <HeaderWrapper>
            <div className="header-image">
              <picture>
                <source srcSet={`${Condition2X} 2x`} />
                <img src={Condition} alt={'Condition'} />
              </picture>
            </div>
            <div className="header-content">
              <H1>Conditions</H1>
            </div>
          </HeaderWrapper>

          <FormWrapper>
            <Form.Item>
              {getFieldDecorator('value', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Conditions Title!',
                  },
                ],
              })(<Input placeholder="What do your Conditions about?" />)}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('Conditions', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your new Conditions!',
                  },
                ],
              })(
                <Input.TextArea
                  placeholder="Conditions"
                  className="text-area"
                />
              )}
            </Form.Item>
          </FormWrapper>
        </ConditionsWrapper>
        <StepAction stepsLength={stepsLength} prev={prev} />
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { conditions } = state.condition;
  return {
    conditions,
  };
};
const mapDispatchToProps = dispatch => ({
  addConditions: conditions =>
    dispatch(patientConditionActions.addConditions(conditions)),
});

const WrappedConditions = Form.create({ name: 'conditions_form' })(
  connect(mapStateToProps, mapDispatchToProps)(Conditions)
);
export default WrappedConditions;

Conditions.propTypes = {
  form: PropTypes.object.isRequired,
  conditions: PropTypes.array,
  addConditions: PropTypes.func.isRequired,
  stepsLength: PropTypes.number.isRequired,
  setConditions: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
