import { patientReportsConstants } from '@actions/types';

const initialState = {};

export function medicalReports(state = initialState, action) {
  switch (action.type) {
    case patientReportsConstants.GET_REPORTS_REQUEST:
      return {
        ...state,
        fetchingMedicalReports: true,
      };
    case patientReportsConstants.GET_REPORTS_SUCCESS:
      return {
        fetchedMedicalReports: true,
        medicalReports: action.medicalReports,
      };
    case patientReportsConstants.GET_REPORTS_FAILURE:
      return {
        error: action.error,
      };
    case patientReportsConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        fetchingMedicalReport: true,
      };
    case patientReportsConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        fetchedMedicalReport: true,
        medicalReport: action.medicalReport,
      };
    case patientReportsConstants.GET_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientReportsConstants.GET_PATIENT_REPORT_REQUEST:
      return {
        ...state,
        fetchingPatientMedicalReport: true,
      };
    case patientReportsConstants.GET_PATIENT_REPORT_SUCCESS:
      return {
        ...state,
        fetchedPatientMedicalReport: true,
        medicalReport: action.medicalReport,
      };
    case patientReportsConstants.GET_PATIENT_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_REQUEST:
      return {
        ...state,
        fetchingMedicalReports: true,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_SUCCESS:
      return {
        fetchedMedicalReports: true,
        medicalReports: action.medicalReports,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_FAILURE:
      return {
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_REPORT_REQUEST:
      return {
        ...state,
        fetchingSHaredMedicalReport: true,
      };
    case patientReportsConstants.GET_SHARED_REPORT_SUCCESS:
      return {
        ...state,
        fetchedSHaredMedicalReport: true,
        medicalReport: action.medicalReport,
      };
    case patientReportsConstants.GET_SHARED_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientReportsConstants.ADD_REPORTS_REQUEST:
      return {
        ...state,
        addMedicalReports: true,
      };
    case patientReportsConstants.ADD_REPORTS_SUCCESS:
      return {
        ...state,
        addedMedicalReports: true,
        medicalReport: action.medicalReport,
      };
    case patientReportsConstants.ADD_REPORTS_FAILURE:
      return {
        error: action.error,
      };
    case patientReportsConstants.UPDATE_REPORTS_REQUEST:
      return {
        ...state,
        updateMedicalReports: true,
      };
    case patientReportsConstants.UPDATE_REPORTS_SUCCESS:
      return {
        ...state,
        updateMedicalReports: true,
        medicalReport: action.medicalReport,
      };
    case patientReportsConstants.UPDATE_REPORTS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
