import React from 'react';

import { MainLayout } from '@components';
import { Conditions } from './components';

function ConditionsPage(props) {
  return <MainLayout footer="main" children={<Conditions {...props} />} />;
}

export default ConditionsPage;
