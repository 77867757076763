import React from 'react';

import { MainLayout } from '@components';
import NewMedicalReport from './components/NewMedicalReport';

function NewMedicalReportsPage(props) {
  return (
    <MainLayout footer="main" children={<NewMedicalReport {...props} />} />
  );
}

export default NewMedicalReportsPage;
