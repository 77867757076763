import React from 'react';

import { MainLayout } from '@components';
import NewLabResults from './components/NewLabResults';

function NewLabResultsPage(props) {
  return <MainLayout footer="main" children={<NewLabResults {...props} />} />;
}

export default NewLabResultsPage;
