import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import { H2, Colors } from '@utils';

const ActivityCartWrapper = styled.div`
  display: flex;
  border-radius: 7.2px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  padding: 16px;
`;
const ActivityCartImgWrapper = styled.div`
  margin-right: 16px;
`;
const ActivityCartImg = styled.picture``;
const ActivityCartContent = styled.div`
  h2 {
    margin-bottom: 5px;
    line-height: 1;
  }
  p {
    margin-top: 0;
    font-size: 18px;
    font-weight: 500;
    color: ${Colors.primaryColor};
    margin-bottom: 0;
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;

export default function ActivityCart(props) {
  return (
    <ActivityCartWrapper className={props.className && props.className}>
      <ActivityCartImgWrapper>
        <ActivityCartImg>
          <source srcSet={`${props.img && props.img.src2X} 2x`} />
          <img
            src={props.img && props.img.src}
            alt={props.img && props.img.alt}
          />
        </ActivityCartImg>
      </ActivityCartImgWrapper>
      <ActivityCartContent>
        <H2>{props.title}</H2>
        <p>{props.content}</p>
        <p>{props.time}</p>
      </ActivityCartContent>
    </ActivityCartWrapper>
  );
}

ActivityCart.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  className: PropTypes.string,
};
