import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

import { H1 } from '@utils';
import {
  Medical2X,
  Medical,
  Redilogy2X,
  Redilogy,
  XRay,
  XRay2X,
  Ecg,
  Ecg2X,
  Algro,
  Algro2X,
  Lab,
  Lab2X,
} from '@images';

const MedicalRecordWrapper = styled.div`
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  h1 {
    color: #060c2b;
    margin-bottom: 20px;
  }
`;
const RecordWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const Record = styled.div`
  width: 32%;
  a {
    width: 100%;
    height: 100%;
    display: block;
    padding: 15px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
    border: solid 1px #e4e9f2;
    &:hover {
      box-shadow: 2px 4px 6px 4px #e4e9f2;
    }
  }

  &:first-of-type,
  &:nth-of-type(2),
  &:nth-of-type(3) {
    margin-bottom: 20px;
  }
  .img-wrapper {
    width: 103px;
    height: 73px;
    margin: auto;
    margin-bottom: 10px;
  }
  h3 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #5cb3db;
    margin-bottom: 17px;
  }
  p {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 12px;
    color: #9298ac;
    margin: 0;
  }
`;

export default class MedicalRecord extends Component {
  render() {
    const { patientId, sharedToken } = this.props;
    console.log(patientId, sharedToken);
    return (
      <MedicalRecordWrapper>
        <H1>Medical Record</H1>
        <RecordWrapper>
          <Record>
            <Link
              to={
                patientId && sharedToken
                  ? `/conditions/${patientId}/shared-data?sharedToken=${sharedToken}`
                  : '/conditions'
              }
            >
              <div className="img-wrapper">
                <picture>
                  <source srcSet={`${XRay2X} 2x`} />
                  <img src={XRay} alt="xray" />
                </picture>
              </div>
              <h3>Conditions</h3>
              <p>Dec 10, 2018, 03:29 PM</p>
            </Link>
          </Record>
          <Record>
            <Link
              to={
                patientId && sharedToken
                  ? `/medical-reports/${patientId}/shared-data?sharedToken=${sharedToken}`
                  : '/medical-reports'
              }
            >
              <div className="img-wrapper">
                <picture>
                  <source srcSet={`${Medical2X} 2x`} />
                  <img src={Medical} alt="Medical" />
                </picture>
              </div>
              <h3>Medical Report</h3>
              <p>Dec 10, 2018, 03:29 PM</p>
            </Link>
          </Record>
          <Record>
            <Link
              to={
                patientId && sharedToken
                  ? `/lab-results/${patientId}/shared-data?sharedToken=${sharedToken}`
                  : '/lab-results'
              }
            >
              <div className="img-wrapper">
                <picture>
                  <source srcSet={`${Lab2X} 2x`} />
                  <img src={Lab} alt="Lab" />
                </picture>
              </div>
              <h3>Lab Result</h3>
              <p>Dec 10, 2018, 03:29 PM</p>
            </Link>
          </Record>
          <Record>
            <Link
              to={
                patientId && sharedToken
                  ? `/allergies/${patientId}/shared-data?sharedToken=${sharedToken}`
                  : '/allergies'
              }
            >
              <div className="img-wrapper">
                <picture>
                  <source srcSet={`${Algro2X} 2x`} />
                  <img src={Algro} alt="Allergies" />
                </picture>
              </div>
              <h3>Allergies</h3>
              <p>Dec 10, 2018, 03:29 PM</p>
            </Link>
          </Record>
          <Record>
            <Link
              to={
                patientId && sharedToken
                  ? `/radiologist/${patientId}/shared-data?sharedToken=${sharedToken}`
                  : '/radiologist'
              }
            >
              <div className="img-wrapper">
                <picture>
                  <source srcSet={`${Redilogy2X} 2x`} />
                  <img src={Redilogy} alt="Radiology" />
                </picture>
              </div>
              <h3>Radiology</h3>
              <p>Dec 10, 2018, 03:29 PM</p>
            </Link>
          </Record>
          <Record>
            <Link
              to={
                patientId && sharedToken
                  ? `/ecgs/${patientId}/shared-data?sharedToken=${sharedToken}`
                  : '/ecgs'
              }
            >
              <div className="img-wrapper">
                <picture>
                  <source srcSet={`${Ecg2X} 2x`} />
                  <img src={Ecg} alt="ECG" />
                </picture>
              </div>
              <h3>ECG</h3>
              <p>Dec 10, 2018, 03:29 PM</p>
            </Link>
          </Record>
        </RecordWrapper>
      </MedicalRecordWrapper>
    );
  }
}
