import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { MyInfoForm } from '@components';

export class UserInfo extends React.Component {
  handleCancel = e => {
    e.preventDefault();

    const {
      form: { setFields },
      user,
      handleFormToggle,
    } = this.props;

    setFields({
      email: {
        value: user.email,
      },
      mobile: {
        value: user.mobile,
      },
      dateOfBirth: {
        value: moment.unix(user.dateOfBirth),
      },
      gender: {
        value: user.gender,
      },
    });
    handleFormToggle();
  };

  render() {
    const {
      user,
      form,
      setProfilePic,
      editFormIsOpen,
      handleFormToggle,
    } = this.props;

    return (
      <div>
        <MyInfoForm
          user={user}
          form={form}
          editFormIsOpen={editFormIsOpen}
          handleEditFormToggle={handleFormToggle}
          handleCancel={this.handleCancel}
          consultant={false}
          setProfilePic={setProfilePic}
        />
      </div>
    );
  }
}
export default UserInfo;

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  updateProfile: PropTypes.func.isRequired,
  setProfilePic: PropTypes.func,
  editFormIsOpen: PropTypes.bool.isRequired,
  handleFormToggle: PropTypes.func.isRequired,
};
