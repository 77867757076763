import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Lab, PlusIcon } from '@images';
import { Breadcrumb, H1, Add, Pagination, StateCard, Modal } from '@utils';
import { patientMedicalReportsActions, consultationActions } from '@actions';
import { Container, RequestMedicalRecordForm } from '@components';
import styled from '@emotion/styled/macro';

const MedicalReportsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #5cb3db;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class MedicalReports extends Component {
  componentDidMount() {
    const {
      getMedicalReports,
      match: {
        params: { patientId },
      },
      getSharedMedicalReports,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedMedicalReports(patientId);
    } else if (patientId && sharedToken) {
      getSharedMedicalReports(patientId, sharedToken);
    } else {
      getMedicalReports();
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = page => {
    const {
      getMedicalReports,
      match: {
        params: { patientId },
      },
      getSharedMedicalReports,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedMedicalReports(patientId, null, page);
    } else if (patientId && sharedToken) {
      getSharedMedicalReports(patientId, sharedToken, page);
    } else {
      getMedicalReports(page);
    }
  };

  render() {
    const {
      medicalReports,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
    } = this.props;
    const { showModal } = this.state;
    const userType = localStorage.getItem('userType');
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : RequestConsultation
      ? [{ item: 'Request Consultation ', url: '/request-consultation' }]
      : [{ item: 'Medical Record ', url: '/#MedicalRecord' }];
    return (
      <Container>
        <MedicalReportsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Medical Reports</H1>
          {userType === 'consultant' && !sharedToken && (
            <Link
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request Medical Reports
            </Link>
          )}
          {userType === 'patient' && !sharedToken && (
            <Add
              to={`/medical-report/new${
                RequestConsultation ? '?RequestConsultation=1' : ''
              }`}
            />
          )}
          {medicalReports &&
            medicalReports.data.map(report => (
              <StateCard
                to={
                  userType === 'consultant'
                    ? `/consultation/${consultationId}/medical-report/${report.id}?patientId=${patientId}`
                    : sharedToken
                    ? `/medical-reports/${report.id}?patientId=${patientId}&sharedToken=${sharedToken}`
                    : `/medical-reports/${report.id}`
                }
                key={report.createdAt}
                title={report.title}
                time={report.createdAt}
              />
            ))}
          {medicalReports &&
            medicalReports.meta.total > medicalReports.meta.per_page && (
              <Pagination
                pageSize={medicalReports.meta.per_page}
                total={medicalReports.meta.total}
                setPage={this.setPage}
              />
            )}
          <Modal
            title={'Request Medical Reports'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'medical_report'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </MedicalReportsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { medicalReports } = state.medicalReports;
  return {
    medicalReports,
  };
};

const mapDispatchToProps = dispatch => ({
  getMedicalReports: (page = null) =>
    dispatch(patientMedicalReportsActions.getMedicalReports(page)),
  getSharedMedicalReports: (patientId, sharedToken, page = null) =>
    dispatch(
      patientMedicalReportsActions.getSharedMedicalReports(
        patientId,
        sharedToken,
        page
      )
    ),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

MedicalReports.propTypes = {
  getMedicalReports: PropTypes.func.isRequired,
  medicalReports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedMedicalReports: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalReports);
