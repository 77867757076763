import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import { Form, Input, Upload, Modal } from 'antd';
import { H1, Colors, getBase64 } from '@utils';
import { Medical, Medical2X, CamraIcon } from '@images';
import { StepAction } from './';

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  .header {
    &-image {
      margin-right: 35px;
      img,
      picture {
        height: 120px;
        width: 120px;
      }
    }
    &-content {
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1;
        text-align: left;
      }
      p {
        text-align: left;
      }
    }
  }
`;

const ConditionsWrapper = styled.div`
  width: 650px;
  margin: auto;
  order: 1;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
`;

const FormWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  input,
  .ant-input.text-area {
    height: 50px;
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #bfbfbf;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.black};
    &:focus {
      border: solid 2px ${Colors.black};
    }
  }
  .ant-input.text-area {
    min-height: 180px;
    resize: none;
  }
  .has-error {
    .ant-input {
      border: 1px solid red;
    }
  }
  .ant-upload {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: center;
    padding: 15px;
    margin: 0;
    background: #fff;
    border-color: ${Colors.secondaryColor};
    .upload-button {
      display: flex;
      justify-content: center;
      .ant-upload-text {
        margin-left: 5px;
        color: ${Colors.secondaryColor};
        font-family: Heebo;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
`;

export class MedicalReport extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  handleCancelUplaod = () => this.setState({ previewVisible: false });

  handleChangeUplaod = ({ fileList }) => this.setState({ fileList });
  handlePreviewUplaod = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleBeforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      setMedicalReport,
      next,
    } = this.props;
    const { fileList } = this.state;

    validateFields((err, values) => {
      if (!err) {
        setMedicalReport({ ...values, fileList });
        next();
      }
    });
  };

  render() {
    const {
      stepsLength,
      prev,
      form: { getFieldDecorator },
    } = this.props;
    const { previewVisible, previewImage, fileList } = this.state;

    const uploadButton = (
      <div className="upload-button">
        <CamraIcon />
        <div className="ant-upload-text">Add images</div>
      </div>
    );
    return (
      <Form onSubmit={this.handleSubmit}>
        <ConditionsWrapper>
          <HeaderWrapper>
            <div className="header-image">
              <picture>
                <source srcSet={`${Medical2X} 2x`} />
                <img src={Medical} alt={'Medical Report'} />
              </picture>
            </div>
            <div className="header-content">
              <H1>Medical Report</H1>
              <p>Add your Medical Report</p>
            </div>
          </HeaderWrapper>

          <FormWrapper>
            <Form.Item>
              {getFieldDecorator('uplaod-medcal-report', {
                rules: [
                  {
                    required: true,
                    message: 'Please Uplaod Medcal Report!',
                  },
                ],
              })(
                <div className="clearfix">
                  <Upload
                    listType="picture-card"
                    multiple={true}
                    fileList={fileList}
                    onPreview={this.handlePreviewUplaod}
                    onChange={this.handleChangeUplaod}
                    beforeUpload={this.handleBeforeUpload}
                    accept="image/png, image/jpeg"
                  >
                    {uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={this.handleCancelUplaod}
                  >
                    <img
                      alt="example"
                      style={{ width: '100%' }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(
                'note',
                {}
              )(
                <Input.TextArea
                  placeholder="Extra notes.. "
                  className="text-area"
                />
              )}
            </Form.Item>
          </FormWrapper>
        </ConditionsWrapper>
        <StepAction stepsLength={stepsLength} prev={prev} />
      </Form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default Form.create({ name: 'medical_report_form' })(
  connect(mapStateToProps, mapDispatchToProps)(MedicalReport)
);

MedicalReport.propTypes = {
  form: PropTypes.object.isRequired,
  stepsLength: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  setMedicalReport: PropTypes.func.isRequired,
};
