const consultationConstants = {
  GET_CONSULTATIONS_REQUEST: 'GET_NEW_CONSULTATIONS_REQUEST',
  GET_CONSULTATIONS_SUCCESS: 'GET_NEW_CONSULTATIONS_SUCCESS',
  GET_CONSULTATIONS_FAILURE: 'GET_NEW_CONSULTATIONS_FAILURE',
  GET_CONSULTATION_REQUEST: 'GET_CONSULTATION_REQUEST',
  GET_CONSULTATION_SUCCESS: 'GET_CONSULTATION_SUCCESS',
  GET_CONSULTATION_FAILURE: 'GET_CONSULTATION_FAILURE',
  GET_FINISHED_CONSULTATIONS_REQUEST: 'GET_FINISHED_CONSULTATIONS_REQUEST',
  GET_FINISHED_CONSULTATIONS_SUCCESS: 'GET_FINISHED_CONSULTATIONS_SUCCESS',
  GET_FINISHED_CONSULTATIONS_FAILURE: 'GET_FINISHED_CONSULTATIONS_FAILURE',
  REQUEST_SCAN_REQUEST: 'REQUEST_SCAN_REQUEST',
  REQUEST_SCAN_SUCCESS: 'REQUEST_SCAN_SUCCESS',
  REQUEST_SCAN_FAILURE: 'REQUEST_SCAN_FAILURE',
  SEND_REPORT_REQUEST: 'SEND_REPORT_REQUEST',
  SEND_REPORT_SUCCESS: 'SEND_REPORT_SUCCESS',
  SEND_REPORT_FAILURE: 'SEND_REPORT_FAILURE',
  GET_ACCEPTED_CONSULTATION_REQUEST: 'GET_ACCEPTED_CONSULTATION_REQUEST',
  GET_ACCEPTED_CONSULTATION_SUCCESS: 'GET_ACCEPTED_CONSULTATION_SUCCESS',
  GET_ACCEPTED_CONSULTATION_FAILURE: 'GET_ACCEPTED_CONSULTATION_FAILURE',
  ACCEPT_CONSULTATION_REQUEST: 'ACCEPT_CONSULTATION_REQUEST',
  ACCEPT_CONSULTATION_SUCCESS: 'ACCEPT_CONSULTATION_SUCCESS',
  ACCEPT_CONSULTATION_FAILURE: 'ACCEPT_CONSULTATION_FAILURE',
  REJECT_CONSULTATION_REQUEST: 'REJECT_CONSULTATION_REQUEST',
  REJECT_CONSULTATION_SUCCESS: 'REJECT_CONSULTATION_SUCCESS',
  REJECT_CONSULTATION_FAILURE: 'REJECT_CONSULTATION_FAILURE',
};

export default consultationConstants;
