import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Lab, PlusIcon } from '@images';
import { patientLabResultsActions, consultationActions } from '@actions';
import { Breadcrumb, H1, Add, Pagination, StateCard, Modal } from '@utils';
import { Container, RequestMedicalRecordForm } from '@components';
import styled from '@emotion/styled/macro';

const LabResultsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #5cb3db;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class LabResults extends Component {
  componentDidMount() {
    const {
      getLabResults,
      match: {
        params: { patientId },
      },
      getSharedLabResults,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedLabResults(patientId);
    } else if (patientId && sharedToken) {
      getSharedLabResults(patientId, sharedToken);
    } else {
      getLabResults();
    }
  }
  state = {
    showModal: false,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false });
  };

  handelShowModal = () => {
    this.setState({ showModal: true });
  };

  setPage = page => {
    const {
      getLabResults,
      match: {
        params: { patientId },
      },
      getSharedLabResults,
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    if (patientId && !sharedToken) {
      getSharedLabResults(patientId, null, page);
    } else if (patientId && sharedToken) {
      getSharedLabResults(patientId, sharedToken, page);
    } else {
      getLabResults(page);
    }
  };

  render() {
    const {
      labResults,
      match: {
        params: { consultationId, patientId },
      },
      requestScan,
    } = this.props;
    const { showModal } = this.state;
    const userType = localStorage.getItem('userType');
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');
    const BreadcrumbLinks = consultationId
      ? [
          {
            item: 'Medical Record ',
            url: `/consultant/consultation/${consultationId}`,
          },
        ]
      : sharedToken
      ? [
          {
            item: 'Medical Record ',
            url: `/${patientId}/shared-data?sharedToken=${sharedToken}`,
          },
        ]
      : RequestConsultation
      ? [{ item: 'Request Consultation ', url: '/request-consultation' }]
      : [{ item: 'Medical Record ', url: '/#MedicalRecord' }];

    return (
      <Container>
        <LabResultsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Lab Results</H1>
          {userType === 'consultant' && !sharedToken && (
            <Link
              className="request"
              to="#!"
              onClick={() => this.handelShowModal()}
            >
              <img src={PlusIcon} alt="Plus" />
              Request Lab Results
            </Link>
          )}
          {userType === 'patient' && !sharedToken && (
            <Add
              to={`/lab-result/new${
                RequestConsultation ? '?RequestConsultation=1' : ''
              }`}
            />
          )}
          {labResults &&
            labResults.data.map(report => (
              <StateCard
                to={
                  userType === 'consultant'
                    ? `/consultation/${consultationId}/lab-result/${report.id}?patientId=${patientId}`
                    : sharedToken
                    ? `/lab-results/${report.id}?patientId=${patientId}&sharedToken=${sharedToken}`
                    : `/lab-results/${report.id}`
                }
                title={report.title}
                time={report.createdAt}
                key={report.id}
              />
            ))}
          {labResults && labResults.meta.total > labResults.meta.per_page && (
            <Pagination
              pageSize={labResults.meta.per_page}
              total={labResults.meta.total}
              setPage={this.setPage}
            />
          )}

          <Modal
            title={'Request Lab Results'}
            image={Lab}
            showModal={showModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              <RequestMedicalRecordForm
                requestScan={requestScan}
                scanType={'lab_result'}
                consultationId={Number(consultationId)}
                handelCloseModal={this.handelCloseModal}
              />
            </>
          </Modal>
        </LabResultsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { labResults } = state.labResults;
  return {
    labResults,
  };
};

const mapDispatchToProps = dispatch => ({
  getLabResults: (page = null) =>
    dispatch(patientLabResultsActions.getLabResults(page)),
  getSharedLabResults: (patientId, sharedToken, page = null) =>
    dispatch(
      patientLabResultsActions.getSharedLabResults(patientId, sharedToken, page)
    ),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
});

LabResults.propTypes = {
  getLabResults: PropTypes.func.isRequired,
  labResults: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedLabResults: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabResults);
