import React, { Component } from 'react';
import { Form, Select, Button, Col, Row, TimePicker, Icon } from 'antd';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'antd/lib/form/style/css';
import 'antd/lib/button/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';
import 'antd/lib/time-picker/style/css';

import { RemoveIcon, AddIcon } from '@images';
import { H1 } from '@utils';

const FormItem = Form.Item;
const { Option } = Select;

const AppointmentTimeWrapper = styled.div`
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
  border-radius: 18px;
  border: solid 1px #e4e9f2;
  padding: 33px;
  background-color: #fff;
  margin-top: 25px;
  &.ant-col {
    padding: 0;
    border: none;
    border-radius: 6px;
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5cb3db;
    line-height: 24px;
    margin-right: 10px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
  .edit-close {
    margin-top: 10px;
    * {
      border: none;
    }
    .remove-item,
    .anticon-clock-circle,
    .ant-select-arrow {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-time-picker {
      margin-left: -13px;
    }
  }
`;

const AppointmentTimeContent = styled.div`
  width: 100%;
  margin-top: 25px;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-select-selection--single {
    height: 40px;
  }
  .ant-select-selection__rendered {
    line-height: 38px;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
    &:after {
      content: none;
    }
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
  .ant-time-picker {
    width: 100%;
  }
  .ant-select-disabled .ant-select-selection {
    cursor: auto;
    background: none;
    border: none;
    color: #000;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    .ant-select-selection__rendered {
      margin: 0;
    }
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-calendar-picker-input.ant-input.ant-input-disabled {
    & + .anticon.anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
  .remove-item {
    display: flex;
    height: 90px;
    align-items: center;
  }
`;

const AppointmentTimeTitleWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  .ant-form-item {
    display: inline-block;
  }
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5cb3db;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
    padding: 0;
  }
`;

let id = 1;

export default class AppointmentTimeForm extends Component {
  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('AppointmentTime');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      AppointmentTime: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('AppointmentTime');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      AppointmentTime: nextKeys,
    });
  };
  handleDay = day => {
    let dayNumber;
    switch (day) {
      case 'SUNDAY':
        dayNumber = 0;
        break;
      case 'MONDAY':
        dayNumber = 1;
        break;
      case 'TUESDAY':
        dayNumber = 2;
        break;
      case 'WEDNESDAY':
        dayNumber = 3;
        break;
      case 'THURSDAY':
        dayNumber = 4;
        break;
      case 'FRIDAY':
        dayNumber = 5;
        break;
      case 'SATURDAY':
        dayNumber = 6;
        break;
      default:
        dayNumber = 0;
        break;
    }
    return dayNumber;
  };
  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      editFormIsOpen,
      handleEditFormToggle,
      handleCancel,
      schedules,
    } = this.props;
    let initial = null;
    if (schedules) {
      initial = [];
      schedules.map((s, index) => initial.push(index));
    }

    getFieldDecorator('AppointmentTime', {
      initialValue: initial || [0],
    });
    const keys = getFieldValue('AppointmentTime');

    const AppointmentItems = keys.map(k => (
      <AppointmentTimeContent
        key={`AppointmentTime-for-${k}`}
        className={editFormIsOpen ? 'edit-open' : 'edit-close'}
      >
        <Row key={`row-for-${k}`}>
          <Col span={8} key={`Title-for-${k}`}>
            <FormItem label={'Day'} required={false}>
              {getFieldDecorator(`AppointmentTimeDay[${k}]`, {
                validateTrigger: ['onChange'],
                initialValue: schedules && this.handleDay(schedules[k].day),
                rules: [
                  {
                    required: true,
                    message:
                      'Please input Title of Appointment Time or delete this Appointment.',
                  },
                ],
              })(
                <Select placeholder="Day">
                  <Option value={0}>Sunday</Option>
                  <Option value={1}>Monday</Option>
                  <Option value={2}>Tuesday</Option>
                  <Option value={3}>Wednesday</Option>
                  <Option value={4}>Thursday</Option>
                  <Option value={5}>Friday</Option>
                  <Option value={6}>Saturday</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col span={7} key={`From-for-${k}`}>
            <FormItem label={'From'} required={false}>
              {getFieldDecorator(`AppointmentTimeFrom[${k}]`, {
                validateTrigger: ['onChange'],
                initialValue:
                  schedules && moment(schedules[k].from_time, 'hh:mm A'),
                rules: [
                  {
                    required: true,

                    message:
                      'Please input Body of Appointment Time or delete this Appointment.',
                  },
                ],
              })(<TimePicker use12Hours format="h:mm a" />)}
            </FormItem>
          </Col>
          <Col span={7} key={`To-for-${k}`}>
            <FormItem label={'To'} required={false}>
              {getFieldDecorator(`AppointmentTimeTo[${k}]`, {
                validateTrigger: ['onChange'],
                initialValue:
                  schedules && moment(schedules[k].to_time, 'hh:mm A'),
                rules: [
                  {
                    required: true,

                    message:
                      'Please input Body of Appointment Time or delete this Appointment.',
                  },
                ],
              })(<TimePicker use12Hours format="h:mm a" />)}
            </FormItem>
          </Col>
          {keys.length > 1 ? (
            <Col span={2} className="remove-item">
              <Button type="dashed" onClick={() => this.remove(k)}>
                <RemoveIcon />
              </Button>
            </Col>
          ) : null}
        </Row>
      </AppointmentTimeContent>
    ));

    return (
      <AppointmentTimeWrapper>
        <AppointmentTimeTitleWrapper>
          <H1>AppointmentTime</H1>
          {handleEditFormToggle ? (
            editFormIsOpen ? (
              <>
                <FormItem>
                  <Button type="dashed" onClick={this.add}>
                    <AddIcon />
                  </Button>
                </FormItem>
                <div>
                  <FormItem>
                    <button htmltype="submit" className="save">
                      Save
                    </button>
                  </FormItem>
                  <FormItem>
                    <button
                      htmltype="button"
                      className="close"
                      onClick={handleCancel}
                    >
                      &times;
                    </button>
                  </FormItem>
                </div>
              </>
            ) : (
              <>
                <button
                  className="ant-dropdown-link"
                  onClick={ev => {
                    ev.preventDefault();
                    handleEditFormToggle();
                  }}
                >
                  <Icon type="edit" theme="filled" />
                </button>
              </>
            )
          ) : (
            <></>
          )}
        </AppointmentTimeTitleWrapper>
        {AppointmentItems}
      </AppointmentTimeWrapper>
    );
  }
}

AppointmentTimeForm.propTypes = {
  form: PropTypes.object.isRequired,
  editFormIsOpen: PropTypes.bool,
  handleEditFormToggle: PropTypes.func,
  handleCancel: PropTypes.func,
  schedules: PropTypes.array,
};
