/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const RemoveIcon = ({ color, width, height }) => {
  return (
    <svg
      css={css`
        fill: ${color || '#EC0707'};
        max-width: ${width || '24'}px;
        max-height: ${height || '24'}px;
        height: ${height || '24'}px;
        width: ${width || '24'}px;
      `}
    >
      <g id="surface1">
        <path d="M 22.390625 5.976562 C 21.316406 4.136719 19.863281 2.683594 18.023438 1.609375 C 16.183594 0.535156 14.175781 0 12 0 C 9.824219 0 7.816406 0.535156 5.976562 1.609375 C 4.136719 2.683594 2.683594 4.136719 1.609375 5.976562 C 0.535156 7.816406 0 9.824219 0 12 C 0 14.175781 0.535156 16.183594 1.609375 18.023438 C 2.683594 19.863281 4.136719 21.316406 5.976562 22.390625 C 7.816406 23.464844 9.824219 24 12 24 C 14.175781 24 16.183594 23.464844 18.023438 22.390625 C 19.863281 21.316406 21.316406 19.863281 22.390625 18.023438 C 23.464844 16.183594 24 14.175781 24 12 C 24 9.824219 23.464844 7.816406 22.390625 5.976562 Z M 19 13 C 19 13.269531 18.902344 13.503906 18.703125 13.703125 C 18.503906 13.902344 18.269531 14 18 14 L 6 14 C 5.730469 14 5.496094 13.902344 5.296875 13.703125 C 5.097656 13.503906 5 13.269531 5 13 L 5 11 C 5 10.730469 5.097656 10.496094 5.296875 10.296875 C 5.496094 10.097656 5.730469 10 6 10 L 18 10 C 18.269531 10 18.503906 10.097656 18.703125 10.296875 C 18.902344 10.496094 19 10.730469 19 11 Z M 19 13 " />
      </g>
    </svg>
  );
};

export default RemoveIcon;

RemoveIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
