import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const HeaderWrapper = styled.header`
  background-color: #fff;
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
  padding: 15px 25px;
  position: relative;
  @media (max-width: 767px) {
    display: inline-block;
  }
`;

export default function Header(props) {
  return <HeaderWrapper>{props.children}</HeaderWrapper>;
}

Header.propTypes = {
  children: PropTypes.object,
};
