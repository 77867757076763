import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { patientProfileActions } from '@actions';
import { ActivityCart, H1, convertDateToFullStringDate } from '@utils';
import { Condition, Condition2X } from '@images';
import { MainLayout, Container } from '@components';

const ActivityWrapper = styled.div`
  margin-top: 30px;
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  .title-wrapper {
    width: 100;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .activity-cart + .activity-cart {
    margin-top: 15px;
  }
`;

class MyActivity extends Component {
  componentDidMount() {
    const { getMyActivity } = this.props;
    getMyActivity();
  }
  render() {
    const { activity } = this.props;
    return (
      <MainLayout footer="main">
        <Container>
          <ActivityWrapper>
            <div className="title-wrapper">
              <H1>My Activity</H1>
            </div>
            <div>
              {activity &&
                activity.data.map(action => (
                  <ActivityCart
                    key={JSON.stringify(action)}
                    title={action.activity_text}
                    content=""
                    time={convertDateToFullStringDate(action.created_at)}
                    img={{
                      src: Condition,
                      src2X: Condition2X,
                      alt: 'Condition',
                    }}
                    className="activity-cart"
                  />
                ))}
            </div>
          </ActivityWrapper>
        </Container>
      </MainLayout>
    );
  }
}

const mapStateToProps = state => {
  const { activity } = state.profile;

  return {
    activity,
  };
};

const mapDispatchToProps = dispatch => ({
  getMyActivity: () => dispatch(patientProfileActions.getMyActivity()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyActivity);

MyActivity.propTypes = {
  getMyActivity: PropTypes.func.isRequired,
  activity: PropTypes.object,
};
