import React from 'react';

import { MainLayout, Container } from '@components';
import { Reports } from './components';

const ReportsPage = props => (
  <MainLayout
    footer="main"
    children={
      <Container>
        <Reports {...props} />
      </Container>
    }
    ReportPage={true}
  />
);

export default ReportsPage;
