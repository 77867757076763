import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Form, Input, Modal } from 'antd';
import Facebook from './Facebook';
import 'antd/lib/form/style/css';
import 'antd/lib/modal/style/css';

import { history } from '@utils';
import { FormWrapper, ResetPasswordForm, SuccessCode } from '@components';

const FormItem = Form.Item;

const SubmitButton = styled.button`
  width: 100%;
  background: #000;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  border-color: #000;
`;

const ForgetPassword = styled.button`
  background: none;
  border: none;
  margin: 10px auto;
`;
class LoginForm extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      history.push('/');
    }
    this.state = {
      email: '',
      password: '',
      visible: false,
      success: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    const { verifyUser, errorAlert, location } = this.props;
    const values = queryString.parse(location.search);
    if (values.token) {
      try {
        let userId = JSON.parse(localStorage.getItem('user'));
        if (!userId && atob(values.token.split('.')[1])) {
          userId = JSON.parse(atob(values.token.split('.')[1])).sub;
        }
        verifyUser({ userId, token: values.token });
      } catch (error) {
        errorAlert('An Error Occured!');
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      login,
      form: { validateFields },
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        const { email, password } = values;
        login(email, password);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      resetPassword,
      logInWithFacebook,
      registerLink,
    } = this.props;

    const { success } = this.state;

    return (
      <>
        <FormWrapper>
          <>
            <Form onSubmit={this.handleSubmit}>
              <legend>Login</legend>
              <FormItem label={'Email'}>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      required: true,
                      message: 'Please input your Email!',
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem label="Password">
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password placeholder="Password" />)}
              </FormItem>
              <FormItem className="submit-button">
                <SubmitButton htmlType="submit">Login</SubmitButton>
              </FormItem>
            </Form>
            <ForgetPassword onClick={this.showModal}>
              Forgot Your Password?
            </ForgetPassword>
            {logInWithFacebook && <Facebook logIn={logInWithFacebook} />}
            <Link className="register-button" to={registerLink}>
              New Account? Register
            </Link>
          </>
        </FormWrapper>
        <Modal
          visible={this.state.visible}
          centered={true}
          onCancel={this.handleCancel}
          footer={[
            <button
              key="ok"
              className="visually-hidden"
              onClick={this.handleOk}
            >
              send
            </button>,
          ]}
        >
          <>
            {success ? (
              <SuccessCode submite={this.handleOk} />
            ) : (
              <ResetPasswordForm
                width={460}
                edit={true}
                submite={this.handleOk}
                resetPassword={resetPassword}
              />
            )}
          </>
        </Modal>
      </>
    );
  }
}

export default Form.create({})(LoginForm);

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  verifyUser: PropTypes.func,
  errorAlert: PropTypes.func,
  location: PropTypes.object,
  form: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  logInWithFacebook: PropTypes.func,
  registerLink: PropTypes.string.isRequired,
};
