import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icon, Form, Row, Col, Input } from 'antd';
import 'antd/lib/icon/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/upload/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';

const FormItem = Form.Item;

const EmergencyInfoWrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5cb3db;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5cb3db;
    line-height: 24px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
`;

const EmergencyInfoContentWrapper = styled.div`
  display: flex;
  .img-upload-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    img {
      display: block;
    }
  }
`;

const EmergencyInfoContent = styled.div`
  width: 100%;
  margin-top: 25px;

  .ant-form-item-control {
    width: 90%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    line-height: 1.6;
    &:after {
      content: none;
    }
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 20px 10px;
    margin-top: 0;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 0;
      background: none;
      color: #060c2b;
      cursor: auto;
    }
  }
`;

const EmergencyWrapper = styled.div`
  margin-top: 20px;
`;

export class EmergencyInfo extends React.Component {
  componentDidMount() {
    const { getEmergency } = this.props;
    getEmergency();
  }
  constructor(props) {
    super(props);
    this.state = {
      editFormIsOpen: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      emergency,
      updateEmergency,
      addEmergency,
      form: { validateFields },
    } = this.props;
    const { editFormIsOpen } = this.state;
    const id = emergency && emergency.id;
    validateFields((err, values) => {
      if (!err) {
        emergency && emergency.status !== 204
          ? updateEmergency(values, id)
          : addEmergency(values);

        this.setState({ editFormIsOpen: !editFormIsOpen });
      }
    });
  };

  handleCancel = e => {
    e.preventDefault();
    const { emergency } = this.props;
    const { editFormIsOpen } = this.state;
    this.setFormFields(emergency);

    this.setState({ editFormIsOpen: !editFormIsOpen });
  };

  setFormFields = emergency => {
    const {
      form: { setFields },
    } = this.props;
    setFields({
      email: {
        value: emergency && emergency.email,
      },
      name: {
        value: emergency && emergency.name,
      },
      mobile: {
        value: emergency && emergency.mobile,
      },
      address: {
        value: emergency && emergency.address,
      },
    });
  };
  render() {
    const { form, emergency } = this.props;
    const { getFieldDecorator } = form;
    const { editFormIsOpen } = this.state;

    const EmergencyFrom = emergency => (
      <EmergencyInfoContentWrapper>
        {(emergency && emergency.status !== 204) || editFormIsOpen === true ? (
          <EmergencyInfoContent>
            <Row>
              <Col span={12}>
                <FormItem label={'Name'}>
                  {getFieldDecorator('name', {
                    initialValue: emergency && emergency.name,
                  })(<Input disabled={!editFormIsOpen && true} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={'Phone'}>
                  {getFieldDecorator('mobile', {
                    initialValue: emergency && emergency.mobile,
                  })(<Input disabled={!editFormIsOpen && true} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={'Email'}>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        type: 'email',
                      },
                    ],
                    initialValue: emergency && emergency.email,
                  })(<Input disabled={!editFormIsOpen && true} />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label={'Address'}>
                  {getFieldDecorator('address', {
                    initialValue: emergency && emergency.address,
                  })(<Input disabled={!editFormIsOpen && true} />)}
                </FormItem>
              </Col>
            </Row>
          </EmergencyInfoContent>
        ) : (
          <div></div>
        )}
      </EmergencyInfoContentWrapper>
    );
    return (
      <EmergencyWrapper className="profile-border">
        <Form onSubmit={this.handleSubmit}>
          <EmergencyInfoWrapper>
            <h2>Emergency Info</h2>
            {editFormIsOpen ? (
              <div>
                <button className="save">Save</button>
                <button
                  className="close"
                  onClick={ev => {
                    ev.preventDefault();
                    this.setState({ editFormIsOpen: !editFormIsOpen });
                  }}
                >
                  &times;
                </button>
              </div>
            ) : (
              <button
                className="ant-dropdown-link"
                onClick={ev => {
                  ev.preventDefault();
                  this.setState({ editFormIsOpen: !editFormIsOpen });
                }}
              >
                }
                {emergency && emergency.status !== 204 ? (
                  <Icon type="edit" theme="filled" />
                ) : (
                  <Icon type="plus" />
                )}
              </button>
            )}
          </EmergencyInfoWrapper>
          {EmergencyFrom(emergency)}
        </Form>
      </EmergencyWrapper>
    );
  }
}

const mapStateToProps = state => {
  const { emergency } = state.profile;
  return {
    emergency,
  };
};

const WrappedEmergencyInfo = Form.create({ name: 'user_info_form' })(
  connect(mapStateToProps, {})(EmergencyInfo)
);

export default WrappedEmergencyInfo;

EmergencyInfo.propTypes = {
  emergency: PropTypes.object,
  form: PropTypes.object,
  addEmergency: PropTypes.func,
  updateEmergency: PropTypes.func,
  getEmergency: PropTypes.func,
};
