const patientProfileConstants = {
  GET_ADDRESSES_REQUEST: 'PROFILE_GET_ADDRESSES_REQUEST',
  GET_ADDRESSES_SUCCESS: 'PROFILE_GET_ADDRESSES_SUCCESS',
  GET_ADDRESSES_FAILURE: 'PROFILE_GET_ADDRESSES_FAILURE',
  ADD_ADDRESSES_REQUEST: 'PROFILE_ADD_ADDRESSES_REQUEST',
  ADD_ADDRESSES_SUCCESS: 'PROFILE_ADD_ADDRESSES_SUCCESS',
  ADD_ADDRESSES_FAILURE: 'PROFILE_ADD_ADDRESSES_FAILURE',
  UPDATE_ADDRESSES_REQUEST: 'PROFILE_UPDATE_ADDRESSES_REQUEST',
  UPDATE_ADDRESSES_SUCCESS: 'PROFILE_UPDATE_ADDRESSES_SUCCESS',
  UPDATE_ADDRESSES_FAILURE: 'PROFILE_UPDATE_ADDRESSES_FAILURE',
  GET_EMERGANCY_REQUEST: 'PROFILE_GET_EMERGANCY_REQUEST',
  GET_EMERGANCY_SUCCESS: 'PROFILE_GET_EMERGANCY_SUCCESS',
  GET_EMERGANCY_FAILURE: 'PROFILE_GET_EMERGANCY_FAILURE',
  ADD_EMERGANCY_REQUEST: 'PROFILE_ADD_EMERGANCY_REQUEST',
  ADD_EMERGANCY_SUCCESS: 'PROFILE_ADD_EMERGANCY_SUCCESS',
  ADD_EMERGANCY_FAILURE: 'PROFILE_ADD_EMERGANCY_FAILURE',
  UPDATE_EMERGANCY_REQUEST: 'PROFILE_UPDATE_EMERGANCY_REQUEST',
  UPDATE_EMERGANCY_SUCCESS: 'PROFILE_UPDATE_EMERGANCY_SUCCESS',
  UPDATE_EMERGANCY_FAILURE: 'PROFILE_UPDATE_EMERGANCY_FAILURE',
  GET_MY_ACTIVITY_REQUEST: 'PROFILE_GET_MY_ACTIVITY_REQUEST',
  GET_MY_ACTIVITY_SUCCESS: 'PROFILE_GET_MY_ACTIVITY_SUCCESS',
  GET_MY_ACTIVITY_FAILURE: 'PROFILE_GET_MY_ACTIVITY_FAILURE',
  GET_MY_CONSULTATION_REQUEST: 'PROFILE_GET_MY_CONSULTATION_REQUEST',
  GET_MY_CONSULTATION_SUCCESS: 'PROFILE_GET_MY_CONSULTATION_SUCCESS',
  GET_MY_CONSULTATION_FAILURE: 'PROFILE_GET_MY_CONSULTATION_FAILURE',
};

export default patientProfileConstants;
