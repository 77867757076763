import React, { Component } from 'react';
import styled from '@emotion/styled';
import {
  ConsultationForm,
  // Conditions,
  // MedicalReport,
  Readings,
  // Allergies,
  // LabRequest,
  // ECG,
  MedicalRecords,
  Done,
} from './';

import { addItemToLocalStorge, getItemFromLocalStorge } from '@utils';

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-steps {
    background: #ededed;
    order: 2;
    display: flex;
    height: 10px;
    .ant-steps-item {
      width: ${props => 100 / props.total}%;
      * {
        display: none;
      }
      &.ant-steps-item-finish,
      &.ant-steps-item-process {
        background: #5cb3db;
        &:first-of-type {
          border-radius: 18px 0 0 18px;
        }
        &:last-child {
          border-radius: 0 18px 18px 0;
        }
      }
    }
  }
`;

const StepsContent = styled.div`
  background: #fff;
  border-radius: 18px 18px 18px 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  order: 1;
  min-height: 690px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

class StepsCompoent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: getItemFromLocalStorge('RequestConsultationSteps') || 0,
      canSubmit: false,
      consultation: null,
      conditions: null,
      medicalReport: null,
      ecg: null,
      allergies: null,
      readings: null,
      labResult: null,
      steps: [
        {
          title: 'Consultation',
          content: (
            <ConsultationForm
              stepsLength={4}
              prev={this.prev}
              next={this.next}
              setConsultation={this.setConsultation}
            />
          ),
        },
        // {
        //   title: 'Conditions',
        //   content: (
        //     <Conditions
        //       current={1}
        //       stepsLength={9}
        //       prev={this.prev}
        //       next={this.next}
        //       setConditions={this.setConditions}
        //     />
        //   ),
        // },
        // {
        //   title: 'Medical Report',
        //   content: (
        //     <MedicalReport
        //       current={2}
        //       stepsLength={9}
        //       prev={this.prev}
        //       next={this.next}
        //       setMedicalReport={this.setMedicalReport}
        //     />
        //   ),
        // },
        // {
        //   title: 'Allergies',
        //   content: (
        //     <Allergies
        //       current={4}
        //       stepsLength={9}
        //       prev={this.prev}
        //       next={this.next}
        //       setAllergies={this.setAllergies}
        //     />
        //   ),
        // },
        // {
        //   title: 'Lab',
        //   content: (
        //     <LabRequest
        //       current={5}
        //       stepsLength={9}
        //       prev={this.prev}
        //       next={this.next}
        //       setLabResult={this.setLabResult}
        //     />
        //   ),
        // },
        // {
        //   title: 'rediologuy',
        //   content: 'Rediologuy',
        // },
        // {
        //   title: 'ECG',
        //   content: (
        //     <ECG
        //       current={7}
        //       stepsLength={9}
        //       prev={this.prev}
        //       next={this.next}
        //       setLabResult={this.setLabResult}
        //     />
        //   ),
        // },
        {
          title: 'Medical Records',
          content: (
            <MedicalRecords stepsLength={4} prev={this.prev} next={this.next} />
          ),
        },
        {
          title: 'Readings',
          content: (
            <Readings stepsLength={4} prev={this.prev} next={this.next} />
          ),
        },
        {
          title: 'Done',
          content: <Done prev={this.prev} stepsLength={4} />,
        },
      ],
    };
  }

  next = () => {
    const current = Number(this.state.current) + 1;
    this.setState({ current });
    addItemToLocalStorge('RequestConsultationSteps', current);
  };

  prev = () => {
    const current = Number(this.state.current) - 1;
    this.setState({ current });
    addItemToLocalStorge('RequestConsultationSteps', current);
  };

  setConsultation = consultation => {
    this.setState({ consultation });
    addItemToLocalStorge('consultation', consultation);
  };

  // setConditions = conditions => this.setState({ conditions });
  // setMedicalReport = medicalReport => this.setState({ medicalReport });
  // setReadings = readings => this.setState({ readings });
  // setAllergies = allergies => this.setState({ allergies });
  // setLabResult = labResult => this.setState({ labResult });
  // setECG = ecg => this.setState({ ecg });

  render() {
    const { current, steps } = this.state;
    return (
      <StepsWrapper total={steps.length}>
        <StepsContent>{steps[current].content}</StepsContent>
      </StepsWrapper>
    );
  }
}

export default StepsCompoent;
