import React from 'react';
import { Menu, Icon } from 'antd';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const UserProfileNavigationWrapper = styled.div`
  border-radius: 6px;
  box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
`;

const UserProfileNavigation = props => {
  const { setCurrentView } = props;
  return (
    <UserProfileNavigationWrapper>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="vertical"
      >
        <Menu.Item
          key="1"
          onClick={() => {
            setCurrentView('userInfo');
          }}
        >
          <Icon type="info-circle" theme="filled" />
          My Info
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => {
            setCurrentView('Payment');
          }}
        >
          <Icon type="file-protect" />
          Payment
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => {
            setCurrentView('changePassword');
          }}
        >
          <Icon type="lock" />
          Change Password
        </Menu.Item>
      </Menu>
    </UserProfileNavigationWrapper>
  );
};

UserProfileNavigation.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
};

export default UserProfileNavigation;
