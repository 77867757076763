import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import styled from '@emotion/styled/macro';
import { HeartRendingIcon, HomeIcon, MedicalRecordIcon } from '@images';
import PropTypes from 'prop-types';

const HomeNavigationWrapper = styled.div`
  border: 1px solid rgba(92, 179, 219, 0.1);
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  background-color: rgba(92, 179, 219, 0.1);
  ul {
    border-radius: 6px;
    li {
      line-height: 31px !important;
      vertical-align: middle;
      position: relative;
      padding: 12px 30px !important;
      margin: 0 !important;
      height: 55px !important;
      font-family: AvenirNext, Arial, sans-serif !important;
      font-size: 15px !important;
      font-weight: 500 !important;

      &:first-of-type {
        border-radius: 6px 6px 0 0;
      }
      &:last-child {
        border-radius: 0 0 6px 6px;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-62%);
      }
      :hover,
      &.ant-menu-item-selected {
        color: #5cb3db !important;
        background-color: #eef7fb;
      }
    }
  }
`;

const HomeNavigation = props => {
  const { setCurrentView, currentView } = props;

  return (
    <HomeNavigationWrapper>
      <Menu defaultSelectedKeys={[currentView]} mode="vertical">
        <Menu.Item
          key="Home"
          onClick={() => {
            setCurrentView('Home');
          }}
        >
          <Link to="#Home">
            <HomeIcon />
            Home
          </Link>
        </Menu.Item>
        <Menu.Item
          key="MedicalRecord"
          onClick={() => {
            setCurrentView('MedicalRecord');
          }}
        >
          <Link to="#MedicalRecord">
            <MedicalRecordIcon />
            Medical Record
          </Link>
        </Menu.Item>
        <Menu.Item
          key="Readings"
          onClick={() => {
            setCurrentView('Readings');
          }}
        >
          <Link to="#Readings">
            <HeartRendingIcon />
            Readings
          </Link>
        </Menu.Item>
      </Menu>
    </HomeNavigationWrapper>
  );
};

HomeNavigation.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  currentView: PropTypes.string,
};

export default HomeNavigation;
