import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';

import { Container } from '@components';
import { Logo, Logo2X, GooglePlay, Apple, FaceBook, Twitter } from '@images';

const FooterWrapper = styled.footer`
  min-height: 300px;
  background: #fff;
  padding: 50px 0;
  p {
    margin: 0;
  }
  & > div {
    display: flex;
    justify-content: space-between;
  }
  .footer-details {
    .image {
      width: 270px;
      height: 65px;
      margin-bottom: 15px;
      display: block;
    }

    p {
      max-width: 590px;
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 2.2;
      color: #9298ac;
      margin-bottom: 25px;
    }

    .footer-nav {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          display: inline-block;
          a {
            font-family: AvenirNext, Arial, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.63;
            color: #060c2b;
          }
          & + li {
            margin-left: 30px;
          }
        }
      }
    }
  }
  .connect-us {
    .apps {
      margin-bottom: 50px;
      a {
        border-radius: 10000px;
        background-color: #000000;
        width: 195px;
        min-height: 60px;
        padding: 15px 35px;
        display: flex;
        color: #fff;
        font-family: OpenSans;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        img {
          object-fit: contain;
          margin-right: 10px;
        }
        span {
          display: block;
          font-family: OpenSans;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.2;
        }
        &:first-of-type {
          margin-bottom: 30px;
        }
      }
    }
    .socials {
      p {
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: #060c2b;
        display: inline-block;
        margin-left: 30px;
      }
      a {
        margin-left: 15px;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterWrapper>
      <Container>
        <>
          <div className="footer-details">
            <Link to="/" className="image">
              <picture>
                <source srcSet={`${Logo2X} 2x`} />
                <img src={Logo} alt="Holoden Khight Logo" />
              </picture>
            </Link>
            <p>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast of the Semantics, a
              large language ocean.
            </p>
            <div className="footer-nav">
              <nav>
                <ul>
                  <li>
                    <Link to="#!">About us</Link>
                  </li>
                  <li>
                    <Link to="#!">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="#!">Feedback</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="connect-us">
            <div className="apps">
              <a href="#!" target="_blank" className="app">
                <img src={Apple} alt="Apple Logo" />
                <p>
                  Download on the <span>App Store</span>
                </p>
              </a>
              <a href="#!" target="_blank" className="app">
                <img src={GooglePlay} alt="Google Play Logo" />
                <p>
                  Get it on <span>Google Play</span>
                </p>
              </a>
            </div>
            <div className="socials">
              <p>Follow us</p>
              <a href="#!" target="_blank" className="social">
                <img src={FaceBook} alt="FaceBook" />
              </a>
              <a href="#!" target="_blank" className="social">
                <img src={Twitter} alt="Twitter" />
              </a>
            </div>
          </div>
        </>
      </Container>
    </FooterWrapper>
  );
}
