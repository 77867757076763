import React, { Component } from 'react';
import { Steps } from './';
import { H1 } from '@utils';
import { Container } from '@components';
import styled from '@emotion/styled';

const RequestConsultationWrapper = styled.div`
  max-width: 950px;
  margin: auto;
  h1 {
    margin-bottom: 30px;
  }
`;
export default class RequestConsultation extends Component {
  render() {
    return (
      <Container>
        <RequestConsultationWrapper>
          <H1>Request Consultation</H1>
          <Steps />
        </RequestConsultationWrapper>
      </Container>
    );
  }
}
