import React, { Component } from 'react';
import Book from '../Book';
import { More, H1 } from '@utils';
import styled from '@emotion/styled/macro';

const BookAppointmentWrapper = styled.div`
  margin-bottom: 10px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  .book-wrapper:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const BookAppointmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px;
  background-color: #f9fafc;
  border-radius: 6px 6px 0 0;
  h1 {
    font-size: 18px;
  }
`;
export default class BookAppointment extends Component {
  render() {
    return (
      <BookAppointmentWrapper>
        <BookAppointmentHeader>
          <H1>Book Appointment</H1>
          <div>
            <More to="#!" />
          </div>
        </BookAppointmentHeader>

        <Book />
        <Book />
      </BookAppointmentWrapper>
    );
  }
}
