import { patientProfileConstants } from '@actions/types';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function profile(state = initialState, action) {
  switch (action.type) {
    case patientProfileConstants.GET_ADDRESSES_REQUEST:
      return {
        ...state,
        getAddress: true,
      };
    case patientProfileConstants.GET_ADDRESSES_SUCCESS:
      return {
        ...state,
        getAddress: true,
        address: action.address,
      };
    case patientProfileConstants.GET_ADDRESSES_FAILURE:
      return {
        error: action.error,
      };
    case patientProfileConstants.ADD_ADDRESSES_REQUEST:
      return {
        ...state,
        addAddress: true,
      };
    case patientProfileConstants.ADD_ADDRESSES_SUCCESS:
      return {
        ...state,
        addAddress: true,
        address: action.address,
      };
    case patientProfileConstants.ADD_ADDRESSES_FAILURE:
      return {
        error: action.error,
      };
    case patientProfileConstants.UPDATE_ADDRESSES_REQUEST:
      return {
        ...state,
        updateAddress: true,
      };
    case patientProfileConstants.UPDATE_ADDRESSES_SUCCESS:
      return {
        ...state,
        updateAddress: true,
        address: action.address,
      };
    case patientProfileConstants.UPDATE_ADDRESSES_FAILURE:
      return {
        emergency: state.emergency,
        error: action.error,
      };
    case patientProfileConstants.GET_EMERGANCY_REQUEST:
      return {
        getEmergency: true,
        ...state,
      };
    case patientProfileConstants.GET_EMERGANCY_SUCCESS:
      return {
        getEmergency: true,
        emergency: action.emergency,
        ...state,
      };
    case patientProfileConstants.GET_EMERGANCY_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case patientProfileConstants.ADD_EMERGANCY_REQUEST:
      return {
        ...state,
        addEmergency: true,
      };
    case patientProfileConstants.ADD_EMERGANCY_SUCCESS:
      return {
        ...state,
        addEmergency: true,
        emergency: action.emergency,
      };
    case patientProfileConstants.ADD_EMERGANCY_FAILURE:
      return {
        error: action.error,
      };
    case patientProfileConstants.UPDATE_EMERGANCY_REQUEST:
      return {
        ...state,
        updateEmergency: true,
      };
    case patientProfileConstants.UPDATE_EMERGANCY_SUCCESS:
      return {
        ...state,
        updateEmergency: true,
        emergency: action.emergency,
      };
    case patientProfileConstants.UPDATE_EMERGANCY_FAILURE:
      return {
        address: state.address,
        error: action.error,
      };
    case patientProfileConstants.GET_MY_ACTIVITY_REQUEST:
      return {
        ...state,
        getMyActivity: true,
      };
    case patientProfileConstants.GET_MY_ACTIVITY_SUCCESS:
      return {
        ...state,
        getMyActivity: true,
        activity: action.activity,
      };
    case patientProfileConstants.GET_MY_ACTIVITY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
