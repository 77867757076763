import React from 'react';
import { Icon } from 'antd';
import 'antd/lib/icon/style/css';
import styled from '@emotion/styled/macro';

const LoadingWrapper = styled.div`
  background: #000;
  z-index: 100;
  position: fixed;
  width: 100%;
  height: calc(100vh - 89px);
  top: 89px;
  left: 0;
  opacity: 0.6;
  .anticon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    svg {
      width: 50px;
      height: 50px;
      color: #fff;
    }
  }
`;

export default function Loading() {
  return (
    <LoadingWrapper>
      <Icon type="loading" />
    </LoadingWrapper>
  );
}
