const patientConsultationsConstants = {
  GET_MY_CONSULTATION_REQUEST: 'GET_MY_CONSULTATION_REQUEST',
  GET_MY_CONSULTATION_SUCCESS: 'GET_MY_CONSULTATION_SUCCESS',
  GET_MY_CONSULTATION_FAILURE: 'GET_MY_CONSULTATION_FAILURE',
  GET_SINGLE_CONSULTATION_REQUEST: 'GET_SINGLE_CONSULTATION_REQUEST',
  GET_SINGLE_CONSULTATION_SUCCESS: 'GET_SINGLE_CONSULTATION_SUCCESS',
  GET_SINGLE_CONSULTATION_FAILURE: 'GET_SINGLE_CONSULTATION_FAILURE',
  ADD_CONSULTATION_REQUEST: 'ADD_CONSULTATION_REQUEST',
  ADD_CONSULTATION_SUCCESS: 'ADD_CONSULTATION_SUCCESS',
  ADD_CONSULTATION_FAILURE: 'ADD_CONSULTATION_FAILURE',
};

export default patientConsultationsConstants;
