const patientConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  SIGN_UP_REQUEST: 'USERS_SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'USERS_SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'USERS_SIGN_UP_FAILURE',
  ADD_MOBILE_REQUEST: 'USERS_ADD_MOBILE_REQUEST',
  ADD_MOBILE_SUCCESS: 'USERS_ADD_MOBILE_SUCCESS',
  ADD_MOBILE_FAILURE: 'USERS_ADD_MOBILE_FAILURE',
  RESEND_CODE_REQUEST: 'USERS_RESEND_CODE_REQUEST',
  RESEND_CODE_SUCCESS: 'USERS_RESEND_CODE_SUCCESS',
  RESEND_CODE_FAILURE: 'USERS_RESEND_CODE_FAILURE',
  FACEBOOK_LOGIN_REQUEST: 'USERS_FACEBOOK_LOGIN_REQUEST',
  FACEBOOK_LOGIN_SUCCESS: 'USERS_FACEBOOK_LOGIN_SUCCESS',
  FACEBOOK_LOGIN_FAILURE: 'USERS_FACEBOOK_LOGIN_FAILURE',
  CLEAR_FORM: 'USERS_CLEAR_FORM',
  VERIFY_USER_REQUEST: 'USERS_VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS: 'USERS_VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE: 'USERS_VERIFY_USER_FAILURE',
  PASSWORD_RESET_USER_REQUEST: 'USERS_PASSWORD_RESET_USER_REQUEST',
  PASSWORD_RESET_USER_SUCCESS: 'USERS_PASSWORD_RESET_USER_SUCCESS',
  PASSWORD_RESET_USER_FAILURE: 'USERS_PASSWORD_RESET_USER_FAILURE',
  PASSWORD_CHANGE_USER_REQUEST: 'USERS_PASSWORD_CHANGE_USER_REQUEST',
  PASSWORD_CHANGE_USER_SUCCESS: 'USERS_PASSWORD_CHANGE_USER_SUCCESS',
  PASSWORD_CHANGE_USER_FAILURE: 'USERS_PASSWORD_CHANGE_USER_FAILURE',
  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
  GENERATE_SHARE_TOKEN_REQUEST: 'GENERATE_SHARE_TOKEN_REQUEST',
  GENERATE_SHARE_TOKEN_SUCCESS: 'GENERATE_SHARE_TOKEN_SUCCESS',
  GENERATE_SHARE_TOKEN_FAILURE: 'GENERATE_SHARE_TOKEN_FAILURE',
  STOP_SHARE_TOKEN_REQUEST: 'STOP_SHARE_TOKEN_REQUEST',
  STOP_SHARE_TOKEN_SUCCESS: 'STOP_SHARE_TOKEN_SUCCESS',
  STOP_SHARE_TOKEN_FAILURE: 'STOP_SHARE_TOKEN_FAILURE',
  LOGOUT: 'USERS_LOGOUT',
  // messages
  USER_UNVERIFIED_MESSAGE:
    'Please verify your email address to access the appliction',
  EXPIRED_TOKEN: 'Unauthorized: Expired token',
};

export default patientConstants;
