import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const FooterWrapper = styled.footer`
  min-height: 25px;
  text-align: center;
  p {
    margin-bottom: 0;
    background-color: #f5f5f5;
  }
`;

export default function Footer(props) {
  return <FooterWrapper>{props.children}</FooterWrapper>;
}

Footer.propTypes = {
  children: PropTypes.element,
};
