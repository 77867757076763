import { consultationConstants } from '@actions/types';

export function consultation(state = {}, action) {
  switch (action.type) {
    case consultationConstants.GET_CONSULTATIONS_REQUEST:
      return {
        ...state,
        requestConsultation: true,
      };
    case consultationConstants.GET_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        getConsultation: true,
        newConsultations: action.consultations,
      };
    case consultationConstants.GET_CONSULTATIONS_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.GET_ACCEPTED_CONSULTATION_REQUEST:
      return {
        ...state,
        requestConsultation: true,
      };
    case consultationConstants.GET_ACCEPTED_CONSULTATION_SUCCESS:
      return {
        ...state,
        getConsultation: true,
        acceptedConsultations: action.consultations,
      };
    case consultationConstants.GET_ACCEPTED_CONSULTATION_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.GET_CONSULTATION_REQUEST:
      return {
        ...state,
        getConsultation: true,
      };
    case consultationConstants.GET_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultation: action.consultation,
      };
    case consultationConstants.GET_CONSULTATION_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.REQUEST_SCAN_REQUEST:
      return {
        ...state,
        sendingScan: true,
      };
    case consultationConstants.REQUEST_SCAN_SUCCESS:
      return {
        ...state,
        scan: action.scan,
      };
    case consultationConstants.REQUEST_SCAN_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.SEND_REPORT_REQUEST:
      return {
        ...state,
        sendingReport: true,
      };
    case consultationConstants.SEND_REPORT_SUCCESS:
      return {
        ...state,
        report: action.report,
      };
    case consultationConstants.SEND_REPORT_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.ACCEPT_CONSULTATION_REQUEST:
      return {
        ...state,
        acceptConsultation: true,
      };
    case consultationConstants.ACCEPT_CONSULTATION_SUCCESS:
      return {
        ...state,
        acceptingConsultation: true,
        consultation: {
          ...state.consultation,
          consultantId: action.consultation.consultantId,
        },
        newConsultations: {
          ...state.newConsultations,
          data: state.newConsultations
            ? state.newConsultations.data.filter(
                consultation => consultation.id !== action.consultation.id
              )
            : null,
        },
      };
    case consultationConstants.ACCEPT_CONSULTATION_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.REJECT_CONSULTATION_REQUEST:
      return {
        ...state,
        rejectConsultation: true,
      };
    case consultationConstants.REJECT_CONSULTATION_SUCCESS:
      return {
        rejectingConsultation: true,
        ...state,
        newConsultations: {
          ...state.newConsultations,
          data: state.newConsultations
            ? state.newConsultations.data.filter(
                consultation => consultation.id !== action.consultationId
              )
            : null,
        },
      };
    case consultationConstants.REJECT_CONSULTATION_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    case consultationConstants.GET_FINISHED_CONSULTATIONS_REQUEST:
      return {
        ...state,
        getFinishedConsultations: true,
      };
    case consultationConstants.GET_FINISHED_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        FinishedConsultations: action.FinishedConsultations,
      };
    case consultationConstants.GET_FINISHED_CONSULTATIONS_FAILURE:
      return {
        ...state,
        errors: { errors: action.error },
      };
    default:
      return state;
  }
}
