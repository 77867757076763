import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patientMedicalReportsActions } from '@actions';
import { Form, Input, Upload, Modal } from 'antd';

import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';

import { Container } from '@components';
import { Medical, Medical2X, CamraIcon } from '@images';
import { H1, PrimaryButton, Breadcrumb, getBase64 } from '@utils';

const NewMedicalReportWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 45px;
  margin-left: -30px;
  .img-wrapper {
    width: 120px;
    height: 120px;

    img {
      width: 100%;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-family: AvenirNext;
      font-size: 24px;
      font-weight: 500;
      color: #5cb3db;
      margin-bottom: 5px;
      line-height: 1;
    }
    p {
      font-family: AvenirNext;
      font-size: 16.8px;
      color: #060c2b;
      margin-bottom: 0;
    }
  }
`;

const FormWrapper = styled.div`
  label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .ant-row.ant-form-item {
    max-width: 780px;
  }
  .ant-form-item-label {
    display: none;
  }
  .ant-input {
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    padding: 15px;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 500;
    min-height: 43px;
  }
  .has-error {
    .ant-input {
      border: 1px solid red;
    }
  }
  .text-area {
    min-height: 219px;
    font-family: Heebo;
    font-size: 17px;
    font-weight: 500;
  }
  .primary-button {
    width: 780px;
    button {
      padding: 5px;
    }
  }
  .upload-button {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px;
  }

  .ant-upload-text {
    margin-left: 10px;
    font-family: Heebo;
    font-size: 15px;
    color: #5cb3db;
  }

  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    display: block;
    width: 100%;
    text-align: center;
    height: auto;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card .ant-upload {
    display: inline-block;
  }
`;

class NewMedicalReport extends Component {
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };
  handleCancelUplaod = () => this.setState({ previewVisible: false });

  handleChangeUplaod = ({ fileList }) => this.setState({ fileList });
  handlePreviewUplaod = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleBeforeUpload = file => {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  };

  handleSubmit = e => {
    e.preventDefault();

    const {
      form: { validateFields },
      addMedicalReports,
    } = this.props;
    const { fileList } = this.state;
    const medicalReportsData = new FormData();

    if (fileList) {
      fileList.forEach(file => {
        medicalReportsData.append('file', file.originFileObj, file.name);
      });
    }

    validateFields((err, values) => {
      if (!err) {
        medicalReportsData.append('note', values.note);
        medicalReportsData.append('title', values.title);
        addMedicalReports(medicalReportsData);
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { previewVisible, previewImage, fileList } = this.state;
    const RequestConsultation = new URLSearchParams(
      this.props.location.search
    ).get('RequestConsultation');

    const uploadButton = (
      <div className="upload-button">
        <CamraIcon />
        <div className="ant-upload-text">Add images</div>
      </div>
    );

    return (
      <Container>
        <NewMedicalReportWrapper>
          <Breadcrumb
            links={
              RequestConsultation
                ? [
                    {
                      item: 'Request Consultation ',
                      url: '/request-consultation',
                    },
                    { item: 'Medical Reports', url: '/medical-reports' },
                  ]
                : [
                    { item: 'Medical Record ', url: '/#MedicalRecord' },
                    { item: 'Medical Reports', url: '/medical-reports' },
                  ]
            }
          />
          <H1>Add Medical Report</H1>

          <TitleWrapper>
            <div className="img-wrapper">
              <picture>
                <source srcSet={`${Medical2X} 2x`} />
                <img src={Medical} alt="Medical" />
              </picture>
            </div>
            <div className="content">
              <h2>Medical Report</h2>
              <p>Add your Medical Report</p>
            </div>
          </TitleWrapper>
          <FormWrapper>
            <Form onSubmit={this.handleSubmit}>
              <div>
                <Form.Item label="Uplaod Medcal Report" hasFeedback>
                  {getFieldDecorator('uplaod-medcal-report', {
                    rules: [
                      {
                        required: true,
                        message: 'Please Uplaod Medcal Report!',
                      },
                    ],
                  })(
                    <div className="clearfix">
                      <Upload
                        listType="picture-card"
                        multiple={true}
                        fileList={fileList}
                        onPreview={this.handlePreviewUplaod}
                        onChange={this.handleChangeUplaod}
                        beforeUpload={this.handleBeforeUpload}
                        accept="image/png, image/jpeg"
                      >
                        {uploadButton}
                      </Upload>
                      <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancelUplaod}
                      >
                        <img
                          alt="example"
                          style={{ width: '100%' }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  )}
                </Form.Item>
                <Form.Item label="Title">
                  {getFieldDecorator('title', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Allergies Title!',
                      },
                    ],
                  })(<Input placeholder="Title" />)}
                </Form.Item>
                <Form.Item label="Note" hasFeedback>
                  {getFieldDecorator(
                    'note',
                    {}
                  )(
                    <Input.TextArea placeholder="Note" className="text-area" />
                  )}
                </Form.Item>

                <Form.Item>
                  <PrimaryButton>
                    <button htmltype="submit" className="submit">
                      Add
                    </button>
                  </PrimaryButton>
                </Form.Item>
              </div>
            </Form>
          </FormWrapper>
        </NewMedicalReportWrapper>
      </Container>
    );
  }
}

NewMedicalReport.propTypes = {
  form: PropTypes.object.isRequired,
  addMedicalReports: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = dispatch => ({
  addMedicalReports: medicalReports =>
    dispatch(patientMedicalReportsActions.addMedicalReports(medicalReports)),
});

const WrappedNewMedicalReport = Form.create({ name: 'new_medical_report' })(
  connect(mapStateToProps, mapDispatchToProps)(NewMedicalReport)
);

export default WrappedNewMedicalReport;
