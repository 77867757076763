import React from 'react';
import { connect } from 'react-redux';

import { patientActions, consultantActions } from '@actions';
import {
  BaseLayout,
  ResetPasswordForm,
  FullPage,
  Container,
} from '@components';
import { history } from '@utils';

const ResetPassword = props => (
  <BaseLayout>
    <FullPage>
      <Container>
        <ResetPasswordForm {...props} />
      </Container>
    </FullPage>
  </BaseLayout>
);

const consultant =
  history.location.pathname === '/consultant/login' ? true : false;

const mapStateToProps = state => {
  const { loggingIn, resetForm } = state.authentication;
  return {
    loggingIn,
    resetForm,
  };
};

const mapDispatchToProps = dispatch => ({
  resetPassword: ({ email }) =>
    dispatch(
      consultant
        ? consultantActions.resetPassword({ email })
        : patientActions.resetPassword({ email })
    ),
});

const ResetPasswordPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ResetPasswordPageContainer;
