const getXRays = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/xrays${page ? `/?page=${page}` : ''}`, requestOptions)
    .then(xrays => {
      return { error: false, xrays };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getXRay = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/xray/show/${id}/`, requestOptions)
    .then(xray => {
      return { error: false, xray };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedXRays = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }
  return fetch(`/patient/${id}/xrays${prams ? prams : ''}`, requestOptions)
    .then(xrays => {
      return { error: false, xrays };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addXRay = Xray => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: Xray,
  };

  return fetch(`/patient/xrays/`, requestOptions)
    .then(xray => {
      return { error: false, xray };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateXRay = (id, Xray) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: Xray,
  };

  return fetch(`/patient/xrays/${id}/`, requestOptions)
    .then(xray => {
      return { error: false, xray };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getMRIs = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/mris${page ? `/?page=${page}` : ''}`, requestOptions)
    .then(mris => {
      return { error: false, mris };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getMRI = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/mri/show/${id}/`, requestOptions)
    .then(mri => {
      return { error: false, mri };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedMRIs = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }

  return fetch(`/patient/${id}/mris${prams ? prams : ''}`, requestOptions)
    .then(mris => {
      return { error: false, mris };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addMRI = MRI => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: MRI,
  };

  return fetch(`/patient/mris/`, requestOptions)
    .then(mri => {
      return { error: false, mri };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateMRI = (id, mri) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: mri,
  };

  return fetch(`/patient/mris/${id}/`, requestOptions)
    .then(mri => {
      return { error: false, mri };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getCTs = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/cts${page ? `/?page=${page}` : ''}`, requestOptions)
    .then(cts => {
      return { error: false, cts };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getCT = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/ct/show/${id}/`, requestOptions)
    .then(ct => {
      return { error: false, ct };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedCTs = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }

  return fetch(`/patient/${id}/cts${prams ? prams : ''}`, requestOptions)
    .then(cts => {
      return { error: false, cts };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addCT = CT => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: CT,
  };

  return fetch(`/patient/cts/`, requestOptions)
    .then(ct => {
      return { error: false, ct };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateCT = (id, ct) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: ct,
  };

  return fetch(`/patient/cts/${id}/`, requestOptions)
    .then(ct => {
      return { error: false, ct };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getXRays,
  getXRay,
  getSharedXRays,
  addXRay,
  updateXRay,
  getMRIs,
  getMRI,
  getSharedMRIs,
  addMRI,
  updateMRI,
  getCTs,
  getCT,
  getSharedCTs,
  addCT,
  updateCT,
};
