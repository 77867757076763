const patientEcgsConstants = {
  GET_ECGS_REQUEST: 'GET_ECGS_REQUEST',
  GET_ECGS_SUCCESS: 'GET_ECGS_SUCCESS',
  GET_ECGS_FAILURE: 'GET_ECGS_FAILURE',
  GET_ECG_REQUEST: 'GET_ECG_REQUEST',
  GET_ECG_SUCCESS: 'GET_ECG_SUCCESS',
  GET_ECG_FAILURE: 'GET_ECG_FAILURE',
  GET_PATIENT_ECG_REQUEST: 'GET_PATIENT_ECG_REQUEST',
  GET_PATIENT_ECG_SUCCESS: 'GET_PATIENT_ECG_SUCCESS',
  GET_PATIENT_ECG_FAILURE: 'GET_PATIENT_ECG_FAILURE',
  GET_SHARED_ECGS_REQUEST: 'GET_SHARED_ECGS_REQUEST',
  GET_SHARED_ECGS_SUCCESS: 'GET_SHARED_ECGS_SUCCESS',
  GET_SHARED_ECGS_FAILURE: 'GET_SHARED_ECGS_FAILURE',
  GET_SHARED_ECG_REQUEST: 'GET_SHARED_ECG_REQUEST',
  GET_SHARED_ECG_SUCCESS: 'GET_SHARED_ECG_SUCCESS',
  GET_SHARED_ECG_FAILURE: 'GET_SHARED_ECG_FAILURE',
  ADD_ECGS_REQUEST: 'ADD_ECGS_REQUEST',
  ADD_ECGS_SUCCESS: 'ADD_ECGS_SUCCESS',
  ADD_ECGS_FAILURE: 'ADD_ECGS_FAILURE',
  UPDATE_ECGS_REQUEST: 'UPDATE_ECGS_REQUEST',
  UPDATE_ECGS_SUCCESS: 'UPDATE_ECGS_SUCCESS',
  UPDATE_ECGS_FAILURE: 'UPDATE_ECGS_FAILURE',
};

export default patientEcgsConstants;
