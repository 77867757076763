import React from 'react';
import styled from '@emotion/styled/macro';

import { Form, Row, Col } from 'antd';
import 'antd/lib/icon/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/upload/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';

const AddPaymentWrapper = styled.div`
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
`;

const AddPaymentContentWrapper = styled.div`
  display: flex;
  width: 65%;
  .ant-form {
    width: 100%;
  }
  .img-upload-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    img {
      display: block;
    }
  }
  button {
    border-radius: 7.2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: #5cb3db;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    border: none;
    margin-top: 20px;
    color: #fff;
  }
`;

const AddPaymentContent = styled.div`
  width: 100%;
  margin-top: 25px;
  .ant-col:first-of-type,
  .ant-col:nth-of-type(2) {
    margin-bottom: 35px;
  }

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
  }
  input {
    width: 100%;
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 10px;
    margin-top: 5px;
    &.width90 {
      width: 90%;
    }
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 8px;
    }
  }
`;

const AddressWrapper = styled.div`
  margin-top: 20px;
`;

export class AddPayment extends React.Component {
  render() {
    return (
      <AddressWrapper className="profile-border">
        <AddPaymentWrapper>
          <h2>Add Payment</h2>
        </AddPaymentWrapper>
        <AddPaymentContentWrapper>
          <Form onSubmit={this.handleSubmit}>
            <AddPaymentContent>
              <Row>
                <Col span={24}>
                  <label>Card Number</label>
                  <input type="text" />
                </Col>
                <Col span={24}>
                  <label>Card Holder</label>
                  <input type="text" />
                </Col>
                <Col span={12}>
                  <label>Expires date</label>
                  <input className="width90" type="text" />
                </Col>
                <Col span={12}>
                  <label>CVV</label>
                  <input type="text" />
                </Col>
              </Row>
            </AddPaymentContent>
            <button>Add</button>
          </Form>
        </AddPaymentContentWrapper>
      </AddressWrapper>
    );
  }
}

const WrappedAddPayment = Form.create({ name: 'user_info_form' })(AddPayment);

export default WrappedAddPayment;
