import React, { Component } from 'react';
import { Form, Input } from 'antd';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Colors } from '@utils';

const FormItem = Form.Item;
const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.secondaryColor};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px ${Colors.gary};
  font-family: Heebo;
  font-size: 15px;
  font-weight: 500;
  min-width: 247px;
  line-height: 1;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-bottom: 0;
    min-width: 1px;
  }
`;

class SearchForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        console.log(values);
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('search', {
              rules: [
                {
                  required: true,
                  message: 'please fill Search field',
                },
              ],
            })(<Input placeholder="Search" />)}
          </FormItem>
          <SubmitButton className="submit-button" htmlType="submit">
            Search
          </SubmitButton>
        </Form>
      </div>
    );
  }
}

export default Form.create({})(SearchForm);

SearchForm.propTypes = {
  form: PropTypes.object.isRequired,
};
