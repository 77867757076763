const getAddress = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/addresses/mine`, requestOptions)
    .then(address => {
      return { error: false, address };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addAddress = ({ country, city, street }) => {
  let raw = JSON.stringify({
    country: country,
    city: city,
    street: street,
  });

  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/addresses`, requestOptions)
    .then(address => {
      return { error: false, address };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateAddress = (address, id) => {
  let raw = JSON.stringify(address);
  const requestOptions = {
    method: 'PATCH',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/addresses/${id}`, requestOptions)
    .then(address => {
      return { error: false, address };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getEmergency = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/emergency-contacts/mine`, requestOptions)
    .then(emergency => {
      return { error: false, emergency };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addEmergency = ({ name, email, mobile, address }) => {
  let raw = JSON.stringify({
    name: name,
    email: email,
    mobile: mobile,
    address: address,
  });

  const requestOptions = {
    method: 'POST',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/emergency-contacts`, requestOptions)
    .then(emergency => {
      return { error: false, emergency };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateEmergency = (emergency, id) => {
  const raw = JSON.stringify(emergency);
  const requestOptions = {
    method: 'PATCH',
    body: raw,
    redirect: 'follow',
  };

  return fetch(`/patient/emergency-contacts/${id}`, requestOptions)
    .then(emergency => {
      return { error: false, emergency };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getMyActivity = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/patient/myactivity`, requestOptions)
    .then(activity => {
      return { error: false, activity };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getMyConsultationRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  return fetch(`/patient/consultation-request`, requestOptions)
    .then(consultationRequests => {
      return { error: false, consultationRequests };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getAddress,
  addAddress,
  updateAddress,
  getEmergency,
  addEmergency,
  updateEmergency,
  getMyActivity,
  getMyConsultationRequests,
};
