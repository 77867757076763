import { patientConsultationsConstants } from '@actions/types';

const initialState = {};

export function consultations(state = initialState, action) {
  switch (action.type) {
    case patientConsultationsConstants.GET_MY_CONSULTATION_REQUEST:
      return {
        ...state,
        getMyconsultationRequests: true,
      };
    case patientConsultationsConstants.GET_MY_CONSULTATION_SUCCESS:
      return {
        ...state,
        MyconsultationRequests: true,
        consultationRequests: action.consultationRequests,
      };
    case patientConsultationsConstants.GET_MY_CONSULTATION_FAILURE:
      return {
        error: action.error,
      };
    case patientConsultationsConstants.GET_SINGLE_CONSULTATION_REQUEST:
      return {
        ...state,
        getConsultation: true,
      };
    case patientConsultationsConstants.GET_SINGLE_CONSULTATION_SUCCESS:
      return {
        ...state,
        getedConsultation: true,
        consultation: action.consultation,
      };
    case patientConsultationsConstants.GET_SINGLE_CONSULTATION_FAILURE:
      return {
        error: action.error,
      };
    case patientConsultationsConstants.ADD_CONSULTATION_REQUEST:
      return {
        ...state,
        getConsultation: true,
      };
    case patientConsultationsConstants.ADD_CONSULTATION_SUCCESS:
      return {
        ...state,
        getedConsultation: true,
        consultation: action.consultation,
      };
    case patientConsultationsConstants.ADD_CONSULTATION_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
