const getMedicalReports = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/medical-reports${page ? `/?page=${page}` : ''}`,
    requestOptions
  )
    .then(medicalReports => {
      return { error: false, medicalReports };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getMedicalReport = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/medicalReport/show/${id}`, requestOptions)
    .then(medicalReport => {
      return { error: false, medicalReport };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getPatientMedicalReport = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/medical-reports/show/${id}`, requestOptions)
    .then(medicalReport => {
      return { error: false, medicalReport };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedMedicalReports = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }
  return fetch(
    `/patient/${id}/medical-reports${prams ? prams : ''}`,
    requestOptions
  )
    .then(medicalReports => {
      return { error: false, medicalReports };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedMedicalReport = (
  patientId,
  id,
  informationShareToken = null
) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${patientId}/medical-reports/show/${id}${
      informationShareToken
        ? `?informationShareToken=${informationShareToken}`
        : ''
    }`,
    requestOptions
  )
    .then(medicalReport => {
      return { error: false, medicalReport };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addMedicalReports = MedicalReport => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: MedicalReport,
  };

  return fetch(`/patient/medical-reports`, requestOptions)
    .then(medicalReports => {
      return { error: false, medicalReports };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateMedicalReports = (id, MedicalReport) => {
  const formdata = new FormData();
  formdata.append('note', MedicalReport.note);
  formdata.append('file', MedicalReport.fileInput.files[0], 'file');

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };

  return fetch(`/patient/medical-reports/${id}`, requestOptions)
    .then(medicalReports => {
      return { error: false, medicalReports };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getMedicalReports,
  getMedicalReport,
  getPatientMedicalReport,
  getSharedMedicalReports,
  getSharedMedicalReport,
  addMedicalReports,
  updateMedicalReports,
};
