import React, { Component } from 'react';
import { Switch } from 'antd';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import 'antd/lib/switch/style/css';
import PropTypes from 'prop-types';

import { PrimaryButton, CalculateTimes } from '@utils';

const SharedDataWrapper = styled.div`
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
`;
const SharedDataHeader = styled.div`
  display: flex;
  padding: 30px 30px 2px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e4e9f2;
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
`;
const SharedDataBody = styled.div`
  padding: 40px 0 50px;
  max-width: 420px;
  margin: auto;
  text-align: center;
  position: relative;

  .valid-time {
    margin-bottom: 40px;
    .time {
      font-size: 55.3px;
      font-weight: bold;
      color: #2b90be;
      margin-bottom: 10px;
      line-height: 1;
      & + p {
        color: #9298ac;
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
  .link {
    display: flex;
    justify-content: space-between;
    border-radius: 7.2px;
    border: solid 1.2px #5cb3db;
    padding: 15px 20px;
    margin-bottom: 10px;
    p {
      margin: 0;
      font-family: AvenirNext;
      font-size: 16.8px;
      color: #9298ac;
      text-align: left;
      max-width: 330px;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      &:after {
        content: '...';
        position: absolute;
        left: 320px;
        background: #fff;
      }
    }
    .copy {
      font-family: Heebo;
      font-size: 18px;
      font-weight: 500;
      color: #5cb3db;
    }
  }
`;
const SharedDataDisabledWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 83px);
  z-index: 5;
  background: #000;
  opacity: 0.5;
  border-radius: 0 0 18px 18px;
`;
export default class SharedData extends Component {
  componentDidMount() {
    const {
      user: { informationShareToken },
    } = this.props;
    console.log(this.props.user);
    if (!informationShareToken) {
      this.setState({ disabled: true });
    }
  }
  state = {
    disabled: false,
  };
  copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  toggleDisabled = () => {
    this.setState({ disabled: !this.state.disabled });
  };
  onChange = checked => {
    const {
      generateInformationShareToken,
      stopInformationShareToken,
    } = this.props;
    if (checked) {
      generateInformationShareToken();
      this.setState({ disabled: false });
    } else {
      stopInformationShareToken();
      this.setState({ disabled: true });
    }
  };
  render() {
    const { user } = this.props;
    const { disabled } = this.state;
    const baseURL =
      !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : 'https://hk.phantasm.biz';

    const sheredLink = `${baseURL}/${user.id}/shared-data?sharedToken=${user.informationShareToken}`;

    return (
      <SharedDataWrapper>
        <SharedDataHeader>
          <h2>Share Data</h2>
          <Switch
            defaultChecked={user.informationShareTokenExpiryTime ? true : false}
            onChange={this.onChange}
          />
        </SharedDataHeader>
        {disabled && <SharedDataDisabledWrapper />}

        <SharedDataBody>
          <div className="valid-time">
            <p className="time">
              {user.informationShareTokenExpiryTime
                ? CalculateTimes(user.informationShareTokenExpiryTime)
                : '00:00'}
            </p>
            <p>Valid until</p>
          </div>
          <p>
            Your data has been share incule medical reports, Readings and your
            presonal info
          </p>
          <div className="link">
            <p id="shereToken">{sheredLink}</p>
            <Link
              to="#!"
              className="copy"
              onClick={() => {
                this.copyToClipboard(sheredLink);
              }}
            >
              Copy
            </Link>
          </div>
          <PrimaryButton className="primary-button">
            <button>Share Link</button>
          </PrimaryButton>
        </SharedDataBody>
      </SharedDataWrapper>
    );
  }
}

SharedData.propTypes = {
  user: PropTypes.object,
  generateInformationShareToken: PropTypes.func.isRequired,
  stopInformationShareToken: PropTypes.func.isRequired,
};
