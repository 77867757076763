import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from '@utils';
import styled from '@emotion/styled/macro';

const SkipStepWrapper = styled.div`
  p {
    font-family: AvenirNext;
    font-size: 16.8px;
    color: #9298ac;
    margin-top: 16px;
    margin-bottom: 11px;
  }
  button {
    margin-bottom: 25px;
  }
`;

export default function SkipStep(props) {
  return (
    <SkipStepWrapper>
      <p>You can add this later from Medical Report tab </p>
      <SecondaryButton onClick={() => props.next()} minWidth={'650'}>
        <button htmltype="button">Skip this step</button>
      </SecondaryButton>
    </SkipStepWrapper>
  );
}

SkipStep.propTypes = {
  next: PropTypes.func.isRequired,
};
