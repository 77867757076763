import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Select, Input } from 'antd';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { consultantActions } from '@actions';
import { H1, Colors, getItemFromLocalStorge } from '@utils';
import { ConsultantinIcon } from '@images';
import { StepAction } from './';

const FormItem = Form.Item;
const { Option } = Select;
const ConsultationWrapper = styled.div`
  width: 650px;
  margin: auto;
  order: 1;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const HeaderWrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
  .header {
    &-image {
      margin-right: 35px;
    }
    &-content {
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1;
      }
      p {
        font-size: 16.8px;
        font-weight: normal;
        margin: 0;
        line-height: 1;
      }
    }
  }
`;
const FormWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-select-selection,
  input,
  .ant-input.text-area {
    height: 50px;
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #bfbfbf;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.black};
    &:focus {
      border: solid 2px ${Colors.black};
    }
  }
  .ant-input.text-area {
    min-height: 180px;
    resize: none;
  }
  .has-error {
    .ant-input,
    .ant-select-selection {
      border: 1px solid red;
    }
  }
`;

class Consultation extends Component {
  componentDidMount() {
    const { getConsultationTypes } = this.props;
    getConsultationTypes();
  }
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      setConsultation,
      next,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        setConsultation(values);
        next();
      }
    });
  };
  render() {
    const {
      consultationTypes,
      form: { getFieldDecorator },
      stepsLength,
      prev,
    } = this.props;
    const consultation = JSON.parse(getItemFromLocalStorge('consultation'));

    return (
      <Form onSubmit={this.handleSubmit}>
        <ConsultationWrapper>
          <HeaderWrapper>
            <div className="header-image">
              <ConsultantinIcon />
            </div>
            <div className="header-content">
              <H1>What are your symptoms?</H1>
              <p>Please describe what you are feeling.</p>
            </div>
          </HeaderWrapper>
          <FormWrapper>
            <FormItem>
              {getFieldDecorator('consultationTypeId', {
                initialValue:
                  consultation &&
                  consultationTypes &&
                  consultation.consultationTypes,
                rules: [
                  {
                    required: true,
                    message: 'please fill Consultation Types field',
                  },
                ],
              })(
                <Select placeholder="Select Specialty">
                  {consultationTypes &&
                    consultationTypes.map(type => (
                      <Option
                        key={`consultationTypes-${type.id}`}
                        value={type.id}
                      >
                        {type.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('symptoms', {
                initialValue: consultation && consultation.symptoms,
                rules: [
                  {
                    required: true,
                    message: 'please fill Symptoms field',
                  },
                ],
              })(
                <Input placeholder="What are your symptoms? eg: Throat ache, lump, persistent headache.." />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('explain', {
                initialValue: consultation && consultation.explain,
                rules: [
                  {
                    required: true,
                    message: 'please fill Explain field',
                  },
                ],
              })(
                <Input.TextArea
                  placeholder="Please explain your symptom/s in more detail."
                  className="text-area"
                />
              )}
            </FormItem>
          </FormWrapper>
        </ConsultationWrapper>
        <StepAction stepsLength={stepsLength} prev={prev} />
      </Form>
    );
  }
}

const mapStateToProps = state => {
  const { consultationTypes } = state.consultantAuth;
  return {
    consultationTypes,
  };
};

const mapDispatchToProps = dispatch => ({
  getPatientTypes: () => dispatch(consultantActions.getPatientType()),
  getConsultationTypes: () => dispatch(consultantActions.getConsultationType()),
  register: consultant => dispatch(consultantActions.register(consultant)),
});
const WrappedConsultation = Form.create({ name: 'steps_form' })(
  connect(mapStateToProps, mapDispatchToProps)(Consultation)
);
export default WrappedConsultation;

Consultation.propTypes = {
  form: PropTypes.object.isRequired,
  consultationTypes: PropTypes.array,
  getConsultationTypes: PropTypes.func.isRequired,
  stepsLength: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
  setConsultation: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
