import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

const ModalWrapper = styled.div`
  .modal {
    &__wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: #000;
      opacity: 0.5;
      z-index: 10;
    }
    &__content {
      border-radius: 6px;
      position: fixed;
      top: 50%;
      left: 50%;
      background: #fff;
      transform: translateX(-50%) translateY(-50%);
      z-index: 11;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
    }
    &__title {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    &__close {
      button {
        border: none;
        background: none;
        font-size: 20px;
        font-weight: bold;
      }
    }
    &__body {
      padding: 20px;
    }
  }
`;

export default class Modal extends Component {
  render() {
    const { image, title, children, showModal, handelCloseModal } = this.props;

    return (
      <>
        {showModal && (
          <ModalWrapper>
            <div
              className="modal__wrapper"
              onClick={() => {
                handelCloseModal();
              }}
            ></div>
            <div className="modal__content">
              {title !== '' && (
                <div className="modal__header">
                  <div className="modal__title">
                    {image && <img src={image} alt={title} />}
                    <p>{title}</p>
                  </div>
                  <div className="modal__close">
                    <button
                      onClick={() => {
                        handelCloseModal();
                      }}
                    >
                      &times;
                    </button>
                  </div>
                </div>
              )}

              <div className="modal__body">{children}</div>
            </div>
          </ModalWrapper>
        )}
      </>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  handelCloseModal: PropTypes.func.isRequired,
};
