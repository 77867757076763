const patientReadingsConstants = {
  GET_READINGS_REQUEST: 'GET_READINGS_REQUEST',
  GET_READINGS_SUCCESS: 'GET_READINGS_SUCCESS',
  GET_READINGS_FAILURE: 'GET_READINGS_FAILURE',
  GET_SHARED_READINGS_REQUEST: 'GET_SHARED_READINGS_REQUEST',
  GET_SHARED_READINGS_SUCCESS: 'GET_SHARED_READINGS_SUCCESS',
  GET_SHARED_READINGS_FAILURE: 'GET_SHARED_READINGS_FAILURE',
  ADD_READINGS_REQUEST: 'ADD_READINGS_REQUEST',
  ADD_READINGS_SUCCESS: 'ADD_READINGS_SUCCESS',
  ADD_READINGS_FAILURE: 'ADD_READINGS_FAILURE',
  ADD_MULTI_READINGS_REQUEST: 'ADD_MULTI_READINGS_REQUEST',
  ADD_MULTI_READINGS_SUCCESS: 'ADD_MULTI_READINGS_SUCCESS',
  ADD_MULTI_READINGS_FAILURE: 'ADD_MULTI_READINGS_FAILURE',
  UPDATE_READINGS_REQUEST: 'UPDATE_READINGS_REQUEST',
  UPDATE_READINGS_SUCCESS: 'UPDATE_READINGS_SUCCESS',
  UPDATE_READINGS_FAILURE: 'UPDATE_READINGS_FAILURE',
};

export default patientReadingsConstants;
