const getECGs = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/ecgs${page ? `/?page=${page}` : ''}`, requestOptions)
    .then(ecgs => {
      return { error: false, ecgs };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getECG = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/ecg/show/${id}`, requestOptions)
    .then(ecg => {
      return { error: false, ecg };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getPatientECG = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/ecgs/show/${id}`, requestOptions)
    .then(ecg => {
      return { error: false, ecg };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedECGs = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }

  return fetch(`/patient/${id}/ecgs${prams ? prams : ''}`, requestOptions)
    .then(ecgs => {
      return { error: false, ecgs };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedECG = (patientId, id, informationShareToken = null) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${patientId}/ecgs/show/${id}${
      informationShareToken
        ? `/?informationShareToken=${informationShareToken}`
        : ''
    }`,
    requestOptions
  )
    .then(ecg => {
      return { error: false, ecg };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};
const addECG = ECG => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: ECG,
  };

  return fetch(`/patient/ecgs`, requestOptions)
    .then(ecg => {
      return { error: false, ecg };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateECG = (id, ECG) => {
  const formdata = new FormData();
  formdata.append('note', ECG.note);
  formdata.append('file', ECG.fileInput.files[0], 'file');

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };

  return fetch(`/patient/ecgs/${id}`, requestOptions)
    .then(ecg => {
      return { error: false, ecg };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};
export default {
  getECGs,
  getECG,
  getPatientECG,
  getSharedECGs,
  getSharedECG,
  addECG,
  updateECG,
};
