const patientLabResultsConstants = {
  GET_LAB_RESULTS_REQUEST: 'GET_LAB_RESULTS_REQUEST',
  GET_LAB_RESULTS_SUCCESS: 'GET_LAB_RESULTS_SUCCESS',
  GET_LAB_RESULTS_FAILURE: 'GET_LAB_RESULTS_FAILURE',
  GET_LAB_RESULT_REQUEST: 'GET_LAB_RESULT_REQUEST',
  GET_LAB_RESULT_SUCCESS: 'GET_LAB_RESULT_SUCCESS',
  GET_LAB_RESULT_FAILURE: 'GET_LAB_RESULT_FAILURE',
  GET_PATIENT_LAB_RESULT_REQUEST: 'GET_PATIENT_LAB_RESULT_REQUEST',
  GET_PATIENT_LAB_RESULT_SUCCESS: 'GET_PATIENT_LAB_RESULT_SUCCESS',
  GET_PATIENT_LAB_RESULT_FAILURE: 'GET_PATIENT_LAB_RESULT_FAILURE',
  GET_SHARED_LAB_RESULTS_REQUEST: 'GET_SHARED_LAB_RESULTS_REQUEST',
  GET_SHARED_LAB_RESULTS_SUCCESS: 'GET_SHARED_LAB_RESULTS_SUCCESS',
  GET_SHARED_LAB_RESULTS_FAILURE: 'GET_SHARED_LAB_RESULTS_FAILURE',
  GET_SHARED_LAB_RESULT_REQUEST: 'GET_SHARED_LAB_RESULT_REQUEST',
  GET_SHARED_LAB_RESULT_SUCCESS: 'GET_SHARED_LAB_RESULT_SUCCESS',
  GET_SHARED_LAB_RESULT_FAILURE: 'GET_SHARED_LAB_RESULT_FAILURE',
  ADD_LAB_RESULTS_REQUEST: 'ADD_LAB_RESULTS_REQUEST',
  ADD_LAB_RESULTS_SUCCESS: 'ADD_LAB_RESULTS_SUCCESS',
  ADD_LAB_RESULTS_FAILURE: 'ADD_LAB_RESULTS_FAILURE',
  UPDATE_LAB_RESULTS_REQUEST: 'UPDATE_LAB_RESULTS_REQUEST',
  UPDATE_LAB_RESULTS_SUCCESS: 'UPDATE_LAB_RESULTS_SUCCESS',
  UPDATE_LAB_RESULTS_FAILURE: 'UPDATE_LAB_RESULTS_FAILURE',
};

export default patientLabResultsConstants;
