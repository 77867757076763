import React, { Component } from 'react';
import styled from '@emotion/styled/macro';

import { Mail, Mail2X } from '@images';

const SuccessCodeWrapper = styled.div`
  text-align: center;
  padding: 40px 20px 20px;

  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #222222;
    margin: 35px auto 15px;
  }
  p {
    max-width: 240px;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    color: #9b9b9b;
    margin: 15px auto 20px;
  }
  button {
    width: 100%;
    border-radius: 6px;
    border-color: #000;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #000;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    font-family: Heebo;
    font-weight: 500;
    margin-top: 35px;
  }
`;

export default class SuccessCode extends Component {
  render() {
    return (
      <SuccessCodeWrapper>
        <picture>
          <source srcSet={`${Mail2X} 2x`} />
          <img src={Mail} alt="Mail Icon" />
        </picture>

        <h2>Code send sucssefuly</h2>
        <p>
          Please cheeck your inbox, we have send a code to resset your password
        </p>
        <button>Done</button>
      </SuccessCodeWrapper>
    );
  }
}
