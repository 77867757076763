export const addItemToLocalStorge = (item, data) => {
  const sortData = typeof data === 'string' ? data : JSON.stringify(data);
  return localStorage.setItem(item, sortData);
};

export const getItemFromLocalStorge = item => {
  return localStorage.getItem(item);
};

export const removeItemFromLocalStorge = item => {
  return localStorage.removeItem(item);
};
