import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import { StepAction } from './';
import {
  Medical2X,
  Medical,
  Redilogy2X,
  Redilogy,
  XRay,
  XRay2X,
  Ecg,
  Ecg2X,
  Algro,
  Algro2X,
  Lab,
  Lab2X,
} from '@images';
import {
  patientMedicalReportsActions,
  patientLabResultsActions,
  patientAllergyActions,
  patientConditionActions,
  patientEcgsActions,
  patientRadiologistActions,
} from '@actions';

const MedicalRecordWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 80px;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .medical-record__title {
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
  & > p {
    margin-bottom: 65px;
  }
  .ant-row {
    .ant-col {
      &:first-of-type > div {
        margin-right: auto;
      }
      &:nth-of-type(2) > div {
        margin: auto;
      }
      &:last-child > div {
        margin-left: auto;
      }
    }
  }
`;

const Record = styled.div`
  width: 95%;
  background #fff;
  height: 170px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  position: relative;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 4px 6px 4px #e4e9f2;
  }
  
  & > .empty {
    &::before  {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: #f9fafc;
      left: 0;
      top: 0;
      z-index: 2;
    }
    a {
      position: relative;
      z-index: 3;
      color: #5cb3db;
      font-size: 12px;
    }
  }
  .record {
    &__image {
      margin-bottom: 10px;
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #5cb3db;
    }
    &__time {
      font-size: 12px;
      font-weight: normal;
      color: #9298ac;
    }
  }
`;

export class MedicalRecords extends Component {
  componentDidMount() {
    const {
      medicalReports,
      labResults,
      ecgs,
      conditions,
      allergies,
      xrays,
      cts,
      mris,
      getMedicalReports,
      getLabResults,
      getAllergies,
      getConditions,
      getECGs,
      getXRays,
      getCTs,
      getMRIs,
    } = this.props;

    if (!medicalReports) {
      getMedicalReports();
    }
    if (!labResults) {
      getLabResults();
    }
    if (!allergies) {
      getAllergies();
    }
    if (!conditions) {
      getConditions();
    }
    if (!ecgs) {
      getECGs();
    }
    if (!xrays) {
      getXRays();
    }
    if (!cts) {
      getCTs();
    }
    if (!mris) {
      getMRIs();
    }
  }
  render() {
    const {
      medicalReports,
      labResults,
      ecgs,
      conditions,
      allergies,
      xrays,
      cts,
      mris,
      stepsLength,
      prev,
      next,
    } = this.props;

    let RadiologyLength = 0;
    if (mris && xrays && cts) {
      RadiologyLength = mris.meta.total + cts.meta.total + xrays.meta.total;
    }
    return (
      <div>
        <MedicalRecordWrapper>
          <p>Please add the necessary medical files</p>
          <Row>
            <Col span={8}>
              <Record>
                {conditions && conditions.data.length > 0 ? (
                  <Link to={'/conditions?RequestConsultation=1'}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="xray" />
                      </picture>
                    </div>
                    <h3 className="record__title">Conditions</h3>
                    <p className="record__time">
                      {conditions.meta.total} Conditions
                    </p>
                  </Link>
                ) : (
                  <Link
                    to={'/condition/new?RequestConsultation=1'}
                    className="empty"
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="xray" />
                      </picture>
                    </div>
                    <h3 className="record__title">Conditions</h3>
                    <p className="record__time">______</p>
                  </Link>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {medicalReports && medicalReports.data.length > 0 ? (
                  <Link to={'/medical-reports?RequestConsultation=1'}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Medical2X} 2x`} />
                        <img src={Medical} alt="Medical" />
                      </picture>
                    </div>
                    <h3 className="record__title">Medical Report</h3>
                    <p className="record__time">
                      {medicalReports.meta.total} Medical Reports
                    </p>
                  </Link>
                ) : (
                  <Link
                    to={'/medical-reports/new?RequestConsultation=1'}
                    className="empty"
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Medical2X} 2x`} />
                        <img src={Medical} alt="Medical" />
                      </picture>
                    </div>
                    <h3 className="record__title">Medical Report</h3>
                    <p className="record__time">______</p>
                  </Link>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {labResults && labResults.data.length > 0 ? (
                  <Link to={'/lab-results?RequestConsultation=1'}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Lab2X} 2x`} />
                        <img src={Lab} alt="Lab" />
                      </picture>
                    </div>
                    <h3 className="record__title">Lab Result</h3>
                    <p className="record__time">
                      {labResults.meta.total} Lab Results
                    </p>
                  </Link>
                ) : (
                  <Link
                    to={'/lab-results/new?RequestConsultation=1'}
                    className="empty"
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Lab2X} 2x`} />
                        <img src={Lab} alt="Lab" />
                      </picture>
                    </div>
                    <h3 className="record__title">Lab Result</h3>
                    <p className="record__time">______</p>
                  </Link>
                )}
              </Record>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Record>
                {allergies && allergies.data.length > 0 ? (
                  <Link to={'/allergies?RequestConsultation=1'}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Algro2X} 2x`} />
                        <img src={Algro} alt="Allergies" />
                      </picture>
                    </div>
                    <h3 className="record__title">Allergies</h3>
                    <p className="record__time">
                      {allergies.meta.total} Allergies
                    </p>
                  </Link>
                ) : (
                  <Link
                    to={'/allergies/new?RequestConsultation=1'}
                    className="empty"
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Algro2X} 2x`} />
                        <img src={Algro} alt="Allergies" />
                      </picture>
                    </div>
                    <h3 className="record__title">Allergies</h3>
                    <p className="record__time">______</p>
                  </Link>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {(mris && mris.data.length > 0) ||
                (cts && cts.data.length > 0) ||
                (xrays && xrays.data.length > 0) ? (
                  <Link to={'/radiologist?RequestConsultation=1'}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Redilogy2X} 2x`} />
                        <img src={Redilogy} alt="Radiology" />
                      </picture>
                    </div>
                    <h3 className="record__title">Radiology</h3>
                    <p className="record__time">{RadiologyLength} Radiology</p>
                  </Link>
                ) : (
                  <Link
                    to={'/radiologist?RequestConsultation=1'}
                    className="empty"
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Redilogy2X} 2x`} />
                        <img src={Redilogy} alt="Radiology" />
                      </picture>
                    </div>
                    <h3 className="record__title">Radiology</h3>
                    <p className="record__time">______</p>
                  </Link>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {ecgs && ecgs.data.length > 0 ? (
                  <Link to={'/ecgs?RequestConsultation=1'}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Ecg2X} 2x`} />
                        <img src={Ecg} alt="ECG" />
                      </picture>
                    </div>
                    <h3 className="record__title">ECG</h3>
                    <p className="record__time">{ecgs.meta.total} ECGs</p>
                  </Link>
                ) : (
                  <Link
                    to={'/ecgs/new?RequestConsultation=1'}
                    className="empty"
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Ecg2X} 2x`} />
                        <img src={Ecg} alt="ECG" />
                      </picture>
                    </div>
                    <h3 className="record__title">ECG</h3>
                    <p className="record__time">______</p>
                  </Link>
                )}
              </Record>
            </Col>
          </Row>
        </MedicalRecordWrapper>
        <StepAction stepsLength={stepsLength} prev={prev} next={next} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    medicalReports: { medicalReports },
    labResults: { labResults },
    ecgs: { ecgs },
    condition: { conditions },
    allergies: { allergies },
    radiologist: {
      xray: { xrays },
      ct: { cts },
      mri: { mris },
    },
  } = state;

  return {
    medicalReports,
    labResults,
    ecgs,
    conditions,
    allergies,
    xrays,
    cts,
    mris,
  };
};

const mapDispatchToProps = dispatch => ({
  getMedicalReports: (page = null) =>
    dispatch(patientMedicalReportsActions.getMedicalReports(page)),
  getLabResults: (page = null) =>
    dispatch(patientLabResultsActions.getLabResults(page)),
  getAllergies: (page = null) =>
    dispatch(patientAllergyActions.getAllergies(page)),
  getConditions: (page = null) =>
    dispatch(patientConditionActions.getConditions(page)),
  getECGs: (page = null) => dispatch(patientEcgsActions.getECGs(page)),
  getXRays: (page = null) => dispatch(patientRadiologistActions.getXRays(page)),
  getCTs: (page = null) => dispatch(patientRadiologistActions.getCTs(page)),
  getMRIs: (page = null) => dispatch(patientRadiologistActions.getMRIs(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecords);

MedicalRecords.propTypes = {
  medicalReports: PropTypes.object,
  labResults: PropTypes.object,
  ecgs: PropTypes.object,
  conditions: PropTypes.object,
  allergies: PropTypes.object,
  xrays: PropTypes.object,
  cts: PropTypes.object,
  mris: PropTypes.object,
  getMedicalReports: PropTypes.func.isRequired,
  getLabResults: PropTypes.func.isRequired,
  getAllergies: PropTypes.func.isRequired,
  getConditions: PropTypes.func.isRequired,
  getECGs: PropTypes.func.isRequired,
  getXRays: PropTypes.func.isRequired,
  getCTs: PropTypes.func.isRequired,
  getMRIs: PropTypes.func.isRequired,
  stepsLength: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
