import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Steps } from 'antd';
import {
  PrimaryButton,
  SecondaryButton,
  getItemFromLocalStorge,
  removeItemFromLocalStorge,
  history,
} from '@utils';
const { Step } = Steps;

const StepsActionWrapper = styled.div`
  background: #f6f6f6;
  padding: 20px;
  order: 3;
  border-radius: 0 0 18px 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  text-align: center;
  display: flex;
  justify-content: ${props => (props.center ? 'center' : 'space-between')};
`;

export default function StepAction(props) {
  const { prev, stepsLength, next } = props;
  const current = Number(
    getItemFromLocalStorge('RequestConsultationSteps') || 0
  );
  const steps = () => {
    let stepsReturn = [];
    for (let i = 0; i < stepsLength; i++) {
      stepsReturn.push(<Step key={stepsLength * i + 12} />);
    }
    return stepsReturn;
  };
  return (
    <>
      <Steps current={current}>{steps()}</Steps>
      <StepsActionWrapper
        center={current === 0 || current === stepsLength - 1 ? true : false}
      >
        {current > 0 && current !== stepsLength - 1 && (
          <SecondaryButton onClick={() => prev()}>
            <button>Previous</button>
          </SecondaryButton>
        )}
        {current === 0 && (
          <PrimaryButton minWidth={'650'}>
            <button htmltype="submit">Continue</button>
          </PrimaryButton>
        )}
        {current < stepsLength - 2 && current > 0 && (
          <PrimaryButton>
            <button onClick={() => next && next()} htmltype="submit">
              Next
            </button>
          </PrimaryButton>
        )}
        {current === stepsLength - 2 && (
          <PrimaryButton htmltype="submit">
            <button>Request Consultation</button>
          </PrimaryButton>
        )}
        {current === stepsLength - 1 && (
          <PrimaryButton
            onClick={() => {
              removeItemFromLocalStorge('RequestConsultationSteps');
              history.push('/');
            }}
            minWidth={'650'}
          >
            <button>Done</button>
          </PrimaryButton>
        )}
      </StepsActionWrapper>
    </>
  );
}

StepAction.propTypes = {
  stepsLength: PropTypes.number.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func,
};
