import React from 'react';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { paymentActions, alertActions } from '@actions';
import { loadStripe } from '@stripe/stripe-js';

const FormFieldContainer = styled.div`
  &:first-of-type {
    border-top: none;
  }
`;

const Label = styled.label`
  width: 20%;
  min-width: 70px;
  padding: 11px 0 5px;
  color: #9298ac;
  overflow: hidden;
  font-family: Heebo;
  font-size: 15px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

const Input = styled.input`
  font-family: Heebo;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 11px 15px 11px 8px;
  color: #9298ac;
  background-color: transparent;
  animation: 1ms void-animation-out;
  object-fit: contain;
  border-radius: 4.8px;
  border: solid 1.2px #dbdde5;
  &::placeholder {
    color: #060c2b;
  }
`;

const AddPaymentWrapper = styled.div`
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
`;

const AddPaymentContentWrapper = styled.div`
  display: flex;
  width: 65%;
  .ant-col-12 {
    padding-right: 5px;
    & + .ant-col-12 {
      padding-right: 0;
      padding-left: 5px;
    }
  }
  .ant-form,
  form {
    width: 100%;
  }
  .img-upload-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    img {
      display: block;
    }
  }
  button {
    border-radius: 7.2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: #5cb3db;
    font-family: Heebo;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 10px;
    border: none;
    margin-top: 20px;
    color: #fff;
  }
`;

const AddressWrapper = styled.div`
  margin-top: 20px;
`;

const CardElementContainer = styled.div`
  margin-top: 20px;
  margin-bootm: 20px;
  height: 52px;
  display: flex;
  align-items: center;
  border: 1px solid #9298ac;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

export class AddPayment extends React.Component {
  componentDidMount() {
    const { getClientSecret } = this.props;
    getClientSecret();
  }
  handleSubmit = async ev => {
    const {
      clientSecret: { client_secret },
    } = this.props;
    const { stripe, elements } = this.props;
    const cardElement = elements.getElement('card');
    const paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: ev.target.name.value,
      },
    });
    const error = await stripe.confirmSetupIntent(client_secret, {
      payment_method: paymentMethodReq.paymentMethod.id,
    });
    if (error) {
      alertActions.error(error || 'there is something wrong');
    }
  };

  render() {
    const iframeStyles = {
      base: {
        color: '#9298ac',
        fontSize: '18px',
        '::placeholder': {
          color: '#060c2b',
        },
      },
      invalid: {
        iconColor: '#f00',
        color: '#f00',
      },
      complete: {
        iconColor: '#cbf4c9',
      },
    };
    const cardElementOpts = {
      iconStyle: 'solid',
      style: iframeStyles,
      hidePostalCode: true,
    };
    return (
      <AddressWrapper className="profile-border">
        <AddPaymentWrapper>
          <h2>Add Payment</h2>
        </AddPaymentWrapper>
        <AddPaymentContentWrapper>
          <form onSubmit={this.handleSubmit}>
            <FormFieldContainer>
              <Label htmlFor={'name'}>Card Holder</Label>
              <Input
                name={'name'}
                type={'text'}
                placeholder={'Enter You Card Holder'}
                required
              />
            </FormFieldContainer>
            <CardElementContainer>
              <CardElement
                options={cardElementOpts}
                onChange={this.handleCardDetailsChange}
              />
            </CardElementContainer>
            <button htmltype="submit">Add</button>
          </form>
        </AddPaymentContentWrapper>
      </AddressWrapper>
    );
  }
}

const InjectedCheckoutForm = props => {
  const stripePromise = loadStripe(
    'pk_test_CvYYqOlv8LwPUeY2vZ5TAXmp00CMd8yubo'
  );
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <AddPayment elements={elements} stripe={stripe} {...props} />
        )}
      </ElementsConsumer>
    </Elements>
  );
};
const mapStateToProps = state => {
  const { clientSecret } = state.payment;
  return {
    clientSecret,
  };
};

const mapDispatchToProps = dispatch => ({
  getClientSecret: () => dispatch(paymentActions.getClientSecret()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectedCheckoutForm);

AddPayment.propTypes = {
  getClientSecret: PropTypes.func.isRequired,
  clientSecret: PropTypes.object,
  stripe: PropTypes.object,
  elements: PropTypes.object,
};
