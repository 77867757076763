import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import { Form, Input } from 'antd';
import 'antd/lib/icon/style/css';
import 'antd/lib/form/style/css';
import 'antd/lib/input/style/css';
import 'antd/lib/upload/style/css';
import 'antd/lib/row/style/css';
import 'antd/lib/col/style/css';

const ChangePasswordWrapper = styled.div`
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
    margin: 0;
  }
  .ant-dropdown-link {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #5cb3db;
    padding: 7px;
    font-size: 14px;
    position: relative;
    .anticon {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
    }
  }
  button {
    border: none;
    background: none;
  }
  .save {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #5cb3db;
    line-height: 24px;
  }
  .close {
    color: #ec0707;
    font-size: 24px;
    line-height: 1;
    vertical-align: middle;
  }
`;

const ChangePasswordContentWrapper = styled.div``;

const ChangePasswordContent = styled.div`
  width: 60%;

  label {
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #9298ac;
    display: block;
    &::before,
    &::after {
      content: none;
    }
  }
  input {
    font-family: Heebo;
    font-size: 16.8px;
    font-weight: 500;
    color: #060c2b;
    margin-bottom: 0;
    border-radius: 4.8px;
    border: solid 1.2px #dbdde5;
    background: #fff;
    padding: 15px;

    height: 50px;
    &:disabled {
      border: none;
      padding: 0;
      margin-top: 8px;
    }
  }
  .ant-form-item {
    margin-bottom: 18px;
  }
  .submit {
    width: 100%;
    border-radius: 7.2px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: #5cb3db;
    padding: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-family: Heebo;
  }
`;

export class ChangePassword extends React.Component {
  state = {
    confirmDirty: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const { changePassword } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { oldPassword, password } = values;
        changePassword({ oldPassword, password });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ChangePasswordWrapper className="profile-border">
        <ChangePasswordWrapper>
          <h2>ChangePassword</h2>
        </ChangePasswordWrapper>
        <ChangePasswordContentWrapper>
          <Form onSubmit={this.handleSubmit}>
            <ChangePasswordContent>
              <Form.Item label="Old Password" hasFeedback>
                {getFieldDecorator('oldPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your old password!',
                    },
                  ],
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Password" hasFeedback>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your new password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Re-Enter new password" hasFeedback>
                {getFieldDecorator('confirm', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
              <Form.Item>
                <button htmltype="submit" className="submit">
                  Save
                </button>
              </Form.Item>
            </ChangePasswordContent>
          </Form>
        </ChangePasswordContentWrapper>
      </ChangePasswordWrapper>
    );
  }
}

const WrappedChangePassword = Form.create({ name: 'change_password' })(
  ChangePassword
);

export default WrappedChangePassword;

ChangePassword.propTypes = {
  form: PropTypes.object,
  changePassword: PropTypes.func,
};
