import React, { Component } from 'react';
import { Container, MedicalRecordComponent } from '@components';
import styled from '@emotion/styled/macro';
const SharedDateWrapper = styled.div`
  max-width: 800px;
  margin: auto;
`;
export default class SharedDate extends Component {
  state = {
    patientId: null,
    sharedToken: null,
  };
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
    } = this.props;
    const sharedToken = new URLSearchParams(this.props.location.search).get(
      'sharedToken'
    );
    this.setState({ patientId, sharedToken });
  }

  render() {
    const { sharedToken, patientId } = this.state;
    return (
      <Container>
        <SharedDateWrapper>
          <MedicalRecordComponent
            sharedToken={sharedToken}
            patientId={patientId}
          />
        </SharedDateWrapper>
      </Container>
    );
  }
}
