import { alertActions } from '@actions';
import { patientConditionServices } from '@services';
import patientConditionsConstants from './types';
import { history } from '@utils';

const getConditions = page => {
  return dispatch => {
    dispatch(getConditionsRequest());
    patientConditionServices.getConditions(page).then(res => {
      if (res.error) {
        dispatch(getConditionsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getConditionsSuccess(res.condition));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getCondition = id => {
  return dispatch => {
    dispatch(getConditionRequest());
    patientConditionServices.getCondition(id).then(res => {
      if (res.error) {
        dispatch(getConditionFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getConditionSuccess(res.condition));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getPatientCondition = id => {
  return dispatch => {
    dispatch(getPatientConditionRequest());
    patientConditionServices.getPatientCondition(id).then(res => {
      if (res.error) {
        dispatch(getPatientConditionFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientConditionSuccess(res.condition));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedConditions = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedConditionsRequest());
    patientConditionServices
      .getSharedConditions(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedConditionsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedConditionsSuccess(res.conditions));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getSharedCondition = (patientId, id, informationShareToken) => {
  return dispatch => {
    dispatch(getSharedConditionRequest());
    patientConditionServices
      .getSharedCondition(patientId, id, informationShareToken)
      .then(res => {
        if (res.error) {
          dispatch(getSharedConditionFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedConditionSuccess(res.condition));
          dispatch(alertActions.clear());
        }
      });
  };
};
const addConditions = conditions => {
  return dispatch => {
    dispatch(addConditionsRequest(conditions));

    patientConditionServices.addCondition(conditions).then(res => {
      if (res.error) {
        dispatch(addConditionsFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addConditionsSuccess(res.condition));
        dispatch(alertActions.success('Your Conditions has Added'));
        dispatch(alertActions.clear());

        const RequestConsultation = new URLSearchParams(
          history.location.search
        ).get('RequestConsultation');
        if (RequestConsultation) {
          history.push('/conditions?RequestConsultation=1');
        } else {
          history.push('/conditions');
        }
      }
    });
  };
};

const updateConditions = (id, conditions) => {
  return dispatch => {
    dispatch(updateConditionsRequest(conditions));

    patientConditionServices.updateCondition(id, conditions).then(res => {
      if (res.error) {
        dispatch(updateConditionsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateConditionsSuccess(res.condition));
        dispatch(alertActions.success('Your Conditions has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getConditionsRequest = () => ({
  type: patientConditionsConstants.GET_CONDITIONS_REQUEST,
});
const getConditionsSuccess = conditions => ({
  type: patientConditionsConstants.GET_CONDITIONS_SUCCESS,
  conditions,
});
const getConditionsFailure = error => ({
  type: patientConditionsConstants.GET_CONDITIONS_FAILURE,
  error,
});

const getConditionRequest = () => ({
  type: patientConditionsConstants.GET_CONDITION_REQUEST,
});
const getConditionSuccess = condition => ({
  type: patientConditionsConstants.GET_CONDITION_SUCCESS,
  condition,
});
const getConditionFailure = error => ({
  type: patientConditionsConstants.GET_CONDITION_FAILURE,
  error,
});

const getPatientConditionRequest = () => ({
  type: patientConditionsConstants.GET_PATIENT_CONDITION_REQUEST,
});
const getPatientConditionSuccess = condition => ({
  type: patientConditionsConstants.GET_PATIENT_CONDITION_SUCCESS,
  condition,
});
const getPatientConditionFailure = error => ({
  type: patientConditionsConstants.GET_PATIENT_CONDITION_FAILURE,
  error,
});

const getSharedConditionsRequest = () => ({
  type: patientConditionsConstants.GET_SHARED_CONDITIONS_REQUEST,
});
const getSharedConditionsSuccess = conditions => ({
  type: patientConditionsConstants.GET_SHARED_CONDITIONS_SUCCESS,
  conditions,
});
const getSharedConditionsFailure = error => ({
  type: patientConditionsConstants.GET_SHARED_CONDITIONS_FAILURE,
  error,
});

const getSharedConditionRequest = () => ({
  type: patientConditionsConstants.GET_SHARED_CONDITION_REQUEST,
});
const getSharedConditionSuccess = condition => ({
  type: patientConditionsConstants.GET_SHARED_CONDITION_SUCCESS,
  condition,
});
const getSharedConditionFailure = error => ({
  type: patientConditionsConstants.GET_SHARED_CONDITION_FAILURE,
  error,
});

const addConditionsRequest = condition => ({
  type: patientConditionsConstants.ADD_CONDITIONS_REQUEST,
  condition,
});
const addConditionsSuccess = condition => ({
  type: patientConditionsConstants.ADD_CONDITIONS_SUCCESS,
  condition,
});
const addConditionsFailure = error => ({
  type: patientConditionsConstants.ADD_CONDITIONS_FAILURE,
  error,
});

const updateConditionsRequest = condition => ({
  type: patientConditionsConstants.UPDATE_CONDITIONS_REQUEST,
  condition,
});
const updateConditionsSuccess = condition => ({
  type: patientConditionsConstants.UPDATE_CONDITIONS_SUCCESS,
  condition,
});
const updateConditionsFailure = error => ({
  type: patientConditionsConstants.UPDATE_CONDITIONS_FAILURE,
  error,
});

export const patientConditionActions = {
  getConditions,
  getCondition,
  getPatientCondition,
  getSharedConditions,
  getSharedCondition,
  addConditions,
  updateConditions,
};
