import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import { Form, Input } from 'antd';

import { Condition, Condition2X } from '@images';
import { H1, Colors } from '@utils';
import { StepAction, SkipStep } from './';

const HeaderWrapper = styled.header`
  display: flex;

  align-items: center;
  margin-bottom: 55px;
  .header {
    &-image {
      margin-right: 35px;
      img,
      picture {
        height: 120px;
        width: 120px;
      }
    }
    &-content {
      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1;
        text-align: left;
      }
    }
  }
`;

const AllergiesWrapper = styled.div`
  width: 650px;
  margin: auto;
  order: 1;
  min-height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
`;

const FormWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  input,
  .ant-input.text-area {
    height: 50px;
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #bfbfbf;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: ${Colors.black};
    &:focus {
      border: solid 2px ${Colors.black};
    }
  }
  .ant-input.text-area {
    min-height: 180px;
    resize: none;
  }
  .has-error {
    .ant-input {
      border: 1px solid red;
    }
  }
`;

export class Allergies extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      next,
      setAllergies,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        setAllergies(values);
        next();
      }
    });
  };
  render() {
    const {
      stepsLength,
      prev,
      form: { getFieldDecorator },
      next,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <AllergiesWrapper>
          <HeaderWrapper>
            <div className="header-image">
              <picture>
                <source srcSet={`${Condition2X} 2x`} />
                <img src={Condition} alt={'Condition'} />
              </picture>
            </div>
            <div className="header-content">
              <H1>Allergies</H1>
              <p>Do you have any allergies? if not, skip this step.</p>
            </div>
          </HeaderWrapper>

          <FormWrapper>
            <Form.Item>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Allergies Title!',
                  },
                ],
              })(
                <Input placeholder="Do you have any allergies? eg: food allergies, drug allergies, animal allergies.." />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator('note', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your new Allergies!',
                  },
                ],
              })(
                <Input.TextArea
                  placeholder="Please explain your allergies in more detail."
                  className="text-area"
                />
              )}
            </Form.Item>
          </FormWrapper>
          <SkipStep next={next} />
        </AllergiesWrapper>
        <StepAction stepsLength={stepsLength} prev={prev} />
      </Form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default Form.create({ name: 'allergies_form' })(
  connect(mapStateToProps, mapDispatchToProps)(Allergies)
);

Allergies.propTypes = {
  form: PropTypes.object.isRequired,
  stepsLength: PropTypes.number.isRequired,
  setAllergies: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};
