const patientConditionsConstants = {
  GET_CONDITIONS_REQUEST: 'GET_CONDITIONS_REQUEST',
  GET_CONDITIONS_SUCCESS: 'GET_CONDITIONS_SUCCESS',
  GET_CONDITIONS_FAILURE: 'GET_CONDITIONS_FAILURE',
  GET_CONDITION_REQUEST: 'GET_CONDITION_REQUEST',
  GET_CONDITION_SUCCESS: 'GET_CONDITION_SUCCESS',
  GET_CONDITION_FAILURE: 'GET_CONDITION_FAILURE',
  GET_PATIENT_CONDITION_REQUEST: 'GET_PATIENT_CONDITION_REQUEST',
  GET_PATIENT_CONDITION_SUCCESS: 'GET_PATIENT_CONDITION_SUCCESS',
  GET_PATIENT_CONDITION_FAILURE: 'GET_PATIENT_CONDITION_FAILURE',
  GET_SHARED_CONDITIONS_REQUEST: 'GET_SHARED_CONDITIONS_REQUEST',
  GET_SHARED_CONDITIONS_SUCCESS: 'GET_SHARED_CONDITIONS_SUCCESS',
  GET_SHARED_CONDITIONS_FAILURE: 'GET_SHARED_CONDITIONS_FAILURE',
  GET_SHARED_CONDITION_REQUEST: 'GET_SHARED_CONDITION_REQUEST',
  GET_SHARED_CONDITION_SUCCESS: 'GET_SHARED_CONDITION_SUCCESS',
  GET_SHARED_CONDITION_FAILURE: 'GET_SHARED_CONDITION_FAILURE',
  ADD_CONDITIONS_REQUEST: 'ADD_CONDITIONS_REQUEST',
  ADD_CONDITIONS_SUCCESS: 'ADD_CONDITIONS_SUCCESS',
  ADD_CONDITIONS_FAILURE: 'ADD_CONDITIONS_FAILURE',
  UPDATE_CONDITIONS_REQUEST: 'UPDATE_CONDITIONS_REQUEST',
  UPDATE_CONDITIONS_SUCCESS: 'UPDATE_CONDITIONS_SUCCESS',
  UPDATE_CONDITIONS_FAILURE: 'UPDATE_CONDITIONS_FAILURE',
};

export default patientConditionsConstants;
