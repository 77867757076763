import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Upload, Icon, message, Form } from 'antd';

const FormItem = Form.Item;
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default class Avatar extends PureComponent {
  state = {
    imageUrl: null,
  };

  componentDidMount() {
    const { user } = this.props;
    if (user && user.picture) {
      this.setState({
        imageUrl: user.picture,
      });
    }
  }

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return false;
  };

  handleChange = info => {
    const { setProfilePic } = this.props;
    info.fileList.forEach(file => {
      if (info.file.uid === file.uid) {
        if (setProfilePic) {
          setProfilePic(true);
        }
        // Get this url from response in real world.
        return getBase64(file.originFileObj, imageUrl =>
          this.setState({
            imageUrl,
          })
        );
      }
    });
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type="camera" theme="filled" />
      </div>
    );
    const { imageUrl } = this.state;
    const {
      form: { getFieldDecorator },
    } = this.props;
    return (
      <FormItem hasFeedback>
        {getFieldDecorator(
          'picture',
          {}
        )(
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={this.beforeUpload}
            onChange={this.handleChange}
            accept="image/png, image/jpeg"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{ borderRadius: '50%' }}
                width="100"
                height="100"
              />
            ) : (
              uploadButton
            )}
          </Upload>
        )}
      </FormItem>
    );
  }
}
Avatar.propTypes = {
  form: PropTypes.object.isRequired,
  user: PropTypes.object,
  setProfilePic: PropTypes.func,
};
