const getAllergies = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/allergies${page ? `/?page=${page}` : ''}`,
    requestOptions
  )
    .then(allergies => {
      return { error: false, allergies };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getAllergy = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/allergy/show/${id}`, requestOptions)
    .then(allergy => {
      return { error: false, allergy };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getPatientAllergy = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/allergies/show/${id}`, requestOptions)
    .then(allergy => {
      return { error: false, allergy };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedAllergies = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }
  return fetch(`/patient/${id}/allergies${prams ? prams : ''}`, requestOptions)
    .then(allergies => {
      return { error: false, allergies };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedAllergy = (patientId, id, informationShareToken = null) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${patientId}/allergies/show/${id}${
      informationShareToken
        ? `/?informationShareToken=${informationShareToken}`
        : ''
    }`,
    requestOptions
  )
    .then(allergy => {
      return { error: false, allergy };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addAllergy = allergy => {
  const raw = JSON.stringify(allergy);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/allergies`, requestOptions)
    .then(allergy => {
      return { error: false, allergy };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateAllergy = (id, allergy) => {
  const raw = JSON.stringify({
    value: allergy.title,
    description: allergy.note,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/allergies/${id}`, requestOptions)
    .then(allergy => {
      return { error: false, allergy };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getAllergies,
  getPatientAllergy,
  getSharedAllergies,
  getSharedAllergy,
  addAllergy,
  updateAllergy,
  getAllergy,
};
