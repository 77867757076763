import React from 'react';
import PropTypes from 'prop-types';
import { MalePic, FemalePic } from '@images';

export default function ProfilePic(props) {
  return (
    <>
      <img
        {...props}
        alt={props.user && props.user.name}
        src={
          props.user && props.user.picture
            ? props.user.picture
            : props.user
            ? props.user.gender === 'male'
              ? MalePic
              : FemalePic
            : MalePic
        }
      />
    </>
  );
}

ProfilePic.propTypes = {
  user: PropTypes.object,
};
