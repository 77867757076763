import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';

import { Message, Message2X, Dollar, Dollar2X } from '@images';
import { H1, PrimaryButton } from '@utils';
const Wrapper = styled.div`
  text-align: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 128px;
    height: 128px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 300px;
  text-align: center;
  p {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 17px;
    color: #060c2b;
    margin-bottom: 0;
  }
`;

const FeesWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  color: #5cb3db;
  font-family: SFProText;
  font-size: 17px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  p {
    margin-left: 15px;
    span {
      text-decoration: line-through;
    }
    & + p {
      margin-left: 20px;
    }
  }
`;
export default class EmptyConsultants extends Component {
  render() {
    return (
      <Wrapper>
        <HeaderWrapper>
          <ImgWrapper>
            <picture>
              <source srcSet={`${Message2X} 2x`} />
              <img src={Message} alt="Message" />
            </picture>
          </ImgWrapper>
          <ContentWrapper>
            <H1>Start your first consultation</H1>
            <p>Please tap the button below to begin your consultation</p>
          </ContentWrapper>
        </HeaderWrapper>
        <FeesWrapper>
          <picture>
            <source srcSet={`${Dollar2X} 2x`} />
            <img src={Dollar} alt="Dollar" />
          </picture>
          <p>
            Fees: <span>650EGP</span>
          </p>
          <p>You Pay: 150EGP</p>
        </FeesWrapper>
        <PrimaryButton minWidth="412">
          <Link to="/request-consultation">Request Consultation</Link>
        </PrimaryButton>
      </Wrapper>
    );
  }
}
