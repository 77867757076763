import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as scenes from '@scenes';
import { alertActions } from '@actions';
import { history, GlobalStyles } from '@utils';
import { PrivateRoute } from './PrivateRoute';
import './utils/fetchIntercept';
class App extends Component {
  constructor(props) {
    super(props);

    const { clearAlerts } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      clearAlerts();
    });
  }

  render() {
    const userType = localStorage.getItem('userType');
    return (
      <div>
        <GlobalStyles />
        <Router history={history}>
          <div>
            <PrivateRoute
              exact
              path="/"
              component={
                userType === 'consultant'
                  ? scenes.ConsultantHomePage
                  : scenes.PatientHomePage
              }
            />
            <PrivateRoute
              exact
              path="/profile"
              component={
                userType === 'consultant'
                  ? scenes.ConsultantProfilePage
                  : scenes.PatientProfilePage
              }
            />
            <Route path="/register" component={scenes.PatientSignUpPage} />
            <Route
              path="/reset-password"
              component={scenes.PatientResetPasswordPage}
            />
            <Route
              path="/set-password"
              component={scenes.PatientNewPasswordPage}
            />
            <Route
              exact
              path="/verify-mobile"
              component={scenes.PatientMobileFormPage}
            />
            <Route
              path="/verify-mobile/:mobileNum"
              component={scenes.PatientVerifyMobilePage}
            />
            <PrivateRoute
              exact
              path="/conditions"
              component={scenes.PatientConditionsPage}
            />
            <Route
              exact
              path="/conditions/:id"
              component={scenes.PatientConditionPage}
            />
            <Route
              exact
              path="/conditions/:patientId/shared-data"
              component={scenes.PatientConditionsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/condition/:id"
              component={scenes.PatientConditionPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/conditions/:patientId"
              component={scenes.PatientConditionsPage}
            />
            <PrivateRoute
              exact
              path="/condition/new"
              component={scenes.PatientNewConditionsPage}
            />
            <PrivateRoute
              exact
              path="/allergies"
              component={scenes.PatientAllergiesPage}
            />
            <Route
              exact
              path="/allergies/:id"
              component={scenes.PatientAllergyPage}
            />
            <Route
              exact
              path="/allergies/:patientId/shared-data"
              component={scenes.PatientAllergiesPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/allergy/:id"
              component={scenes.PatientAllergyPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/allergies/:patientId"
              component={scenes.PatientAllergiesPage}
            />
            <PrivateRoute
              exact
              path="/allergy/new"
              component={scenes.PatientNewAllergiesPage}
            />
            <PrivateRoute
              exact
              path="/medical-reports"
              component={scenes.PatientMedicalReportsPage}
            />
            <Route
              exact
              path="/medical-reports/:id"
              component={scenes.PatientMedicalReportPage}
            />
            <Route
              exact
              path="/medical-reports/:patientId/shared-data"
              component={scenes.PatientMedicalReportsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/medical-report/:id"
              component={scenes.PatientMedicalReportPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/medical-reports/:patientId"
              component={scenes.PatientMedicalReportsPage}
            />
            <PrivateRoute
              exact
              path="/medical-report/new"
              component={scenes.PatientNewMedicalReportsPage}
            />
            <PrivateRoute
              exact
              path="/lab-results"
              component={scenes.PatientLabResultsPage}
            />
            <Route
              exact
              path="/lab-results/:id"
              component={scenes.PatientLabResultPage}
            />
            <Route
              exact
              path="/lab-results/:patientId/shared-data"
              component={scenes.PatientLabResultsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/lab-result/:id"
              component={scenes.PatientLabResultPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/lab-results/:patientId"
              component={scenes.PatientLabResultsPage}
            />
            <PrivateRoute
              exact
              path="/lab-result/new"
              component={scenes.PatientNewLabResultsPage}
            />
            <PrivateRoute
              exact
              path="/ecgs"
              component={scenes.PatientECGsPage}
            />
            <Route exact path="/ecgs/:id" component={scenes.PatientECGPage} />
            <Route
              exact
              path="/ecgs/:patientId/shared-data"
              component={scenes.PatientECGsPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/ecg/:id"
              component={scenes.PatientECGPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consultationId/ecgs/:patientId"
              component={scenes.PatientECGsPage}
            />
            <PrivateRoute
              exact
              path="/ecg/new"
              component={scenes.PatientNewECGsPage}
            />
            <PrivateRoute
              exact
              path="/radiologist"
              component={scenes.PatientRadiologistPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consiltionId/radiologist/:patientId"
              component={scenes.PatientRadiologistPage}
            />
            <PrivateRoute
              exact
              path="/consultation/:consiltionId/xray/:patientId"
              component={scenes.XRayPage}
            />
            <PrivateRoute exact path="/myxrays" component={scenes.XRayPage} />
            <PrivateRoute
              exact
              path="/consultation/:consiltionId/ct/:patientId"
              component={scenes.CTsPage}
            />
            <PrivateRoute exact path="/mycts" component={scenes.CTsPage} />
            <PrivateRoute
              exact
              path="/consultation/:consiltionId/mri/:patientId"
              component={scenes.MRIsPage}
            />
            <PrivateRoute exact path="/mymris" component={scenes.MRIsPage} />
            <PrivateRoute
              exact
              path="/consultation/:consiltionId/radiology/mri/:id"
              component={scenes.MRIPage}
            />
            <Route
              exact
              path="/radiologist/:patientId/shared-data"
              component={scenes.PatientRadiologistPage}
            />
            <PrivateRoute
              exact
              path="/consultant/consultation/:id"
              component={scenes.ViewConsultationPage}
            />
            <PrivateRoute
              exact
              path="/reports"
              component={scenes.ReportsPage}
            />
            <PrivateRoute
              exact
              path="/my-activity"
              component={scenes.MyActivity}
            />
            <PrivateRoute
              exact
              path="/my-consultationRequests"
              component={scenes.MyAllConsultants}
            />
            <PrivateRoute
              exact
              path="/my-consultationRequests/:id"
              component={scenes.ConsultationPage}
            />
            <PrivateRoute
              exact
              path="/request-consultation"
              component={scenes.RequestConsultationPage}
            />
            <Route
              exact
              path="/:patientId/shared-data"
              component={scenes.SharedDatePage}
            />
            <Route path="/login" component={scenes.PatientLoginPage} />
            <Route
              path="/consultant/login"
              component={scenes.ConsultantLoginPage}
            />
            <Route
              path="/consultant/register"
              component={scenes.ConsultantRegisterPage}
            />
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({
  clearAlerts: () => dispatch(alertActions.clear()),
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default connectedApp;

App.propTypes = {
  clearAlerts: PropTypes.func,
};
