import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Form, Input } from 'antd';
import 'antd/lib/form/style/css';

import { FormWrapper } from '@components';
import { Colors } from '@utils';

const FormItem = Form.Item;
const SubmitButton = styled.button`
  width: 100%;
  background: ${Colors.black};
  color: ${Colors.white};
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  border-color: ${Colors.black};
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

class ResetPasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      resetPassword,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        resetPassword(values.email);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      width,
    } = this.props;

    return (
      <FormWrapper width={width}>
        <>
          <Form onSubmit={this.handleSubmit}>
            <legend>
              Reset Password
              <span>
                Please enter youe Email to start reseting your password, we will
                send you email with link to reset your passwrod
              </span>
            </legend>
            <FormItem label={'Email'}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    required: true,
                    message: 'please fill Email field',
                  },
                ],
              })(<Input placeholder="Email" />)}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Send
              </SubmitButton>
            </FormItem>
          </Form>
        </>
      </FormWrapper>
    );
  }
}

export default Form.create({})(ResetPasswordForm);

ResetPasswordForm.propTypes = {
  form: PropTypes.object.isRequired,
  width: PropTypes.number,
  resetPassword: PropTypes.func.isRequired,
};
