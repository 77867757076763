import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import moment from 'moment';
import { consultantActions } from '@actions';

import {
  MyInfoForm,
  AddressInfoForm,
  BaseLayout,
  Container,
  QualificationsForm,
  // AppointmentTimeForm,
  AboutMe,
} from '@components';

import { H1, PrimaryButton } from '@utils';

const RegisterPageWrapper = styled.div`
  max-width: 850px;
  h1 {
    font-size: 30px;
    font-weight: bold;
    color: #474747;
    font-family: DINNextLTW23;
  }
  .primary-button {
    width: 100%;
    margin-top: 15px;
  }
`;
const HaveAccount = styled.p`
  text-align: center;
  font-family: AvenirNext, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 30px auto 0;
  a {
    margin: 0;
  }
`;

class Register extends Component {
  componentDidMount() {
    const { getPatientTypes, getConsultationTypes } = this.props;
    getConsultationTypes();
    getPatientTypes();
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      form: { validateFields },
      register,
    } = this.props;
    validateFields((err, values) => {
      if (!err) {
        const {
          Qualifications,
          QualificationTitle,
          QualificationTBody,
          // AppointmentTime,
          // AppointmentTimeFrom,
          // AppointmentTimeTo,
          // AppointmentTimeDay,
          firstName,
          lastName,
          email,
          password,
          mobile,
          gender,
          gmcNumber,
          yearsOfExperienceAsConsultant,
          currentEmployer,
          jobTitle,
          patientTypeId,
          city,
          country,
          street,
          consultationTypes,
          dateOfBirth,
          picture,
          code,
        } = values;

        const qualifications = Qualifications.map(key => {
          return {
            title: QualificationTitle[key],
            body: QualificationTBody[key],
          };
        });

        // const appointmentTimes = AppointmentTime.map(key => {
        //   return {
        //     day: AppointmentTimeDay[key],
        //     to_time: moment(AppointmentTimeTo[key]).format('LT'),
        //     from_time: moment(AppointmentTimeFrom[key]).format('LT'),
        //   };
        // });
        // console.log(appointmentTimes);
        const consultant = {
          name: `${firstName} ${lastName}`,
          email,
          password,
          mobile: code + mobile,
          dateOfBirth: moment(dateOfBirth).unix(),
          gender,
          gmcNumber,
          yearsOfExperienceAsConsultant,
          currentEmployer,
          jobTitle,
          patientTypeId,
          address: {
            country,
            city,
            street,
          },
          consultationTypes: [Number(consultationTypes)],
          qualifications,
          picture: picture ? picture.fileList[0].originFileObj : null,
        };

        register(consultant);
      }
    });
  };
  render() {
    const { form, consultationTypes, patientTypes } = this.props;

    return (
      <BaseLayout>
        <Container>
          <RegisterPageWrapper>
            <Form onSubmit={this.handleSubmit}>
              <H1>New Account</H1>
              <MyInfoForm form={form} editFormIsOpen={true} consultant={true} />
              <AddressInfoForm form={form} editFormIsOpen={true} />
              <AboutMe
                form={form}
                consultationTypes={consultationTypes && consultationTypes}
                patientTypes={patientTypes && patientTypes}
                editFormIsOpen={true}
              />
              <QualificationsForm form={form} />
              {/* <AppointmentTimeForm form={form} /> */}
              <PrimaryButton>
                <button>Register</button>
              </PrimaryButton>
            </Form>
            <HaveAccount>
              Have account ? <Link to="/consultant/login">Login!</Link>
            </HaveAccount>
          </RegisterPageWrapper>
        </Container>
      </BaseLayout>
    );
  }
}

const mapStateToProps = state => {
  const { consultationTypes, patientTypes } = state.consultantAuth;
  return {
    consultationTypes,
    patientTypes,
  };
};

const mapDispatchToProps = dispatch => ({
  getPatientTypes: () => dispatch(consultantActions.getPatientType()),
  getConsultationTypes: () => dispatch(consultantActions.getConsultationType()),
  register: consultant => dispatch(consultantActions.register(consultant)),
});

const WrappedRegister = Form.create({ name: 'register_consultant_form' })(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);

export default WrappedRegister;

Register.propTypes = {
  form: PropTypes.object.isRequired,
  patientTypes: PropTypes.array,
  consultationTypes: PropTypes.array,
  getPatientTypes: PropTypes.func.isRequired,
  getConsultationTypes: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};
