const getConditions = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/conditions${page ? `/?page=${page}` : ''}`,
    requestOptions
  )
    .then(condition => {
      return { error: false, condition };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getCondition = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/condition/show/${id}`, requestOptions)
    .then(condition => {
      return { error: false, condition };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};
const getPatientCondition = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/conditions/show/${id}`, requestOptions)
    .then(condition => {
      return { error: false, condition };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedConditions = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };
  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }
  return fetch(`/patient/${id}/conditions${prams ? prams : ''}`, requestOptions)
    .then(conditions => {
      return { error: false, conditions };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedCondition = (patientId, id, informationShareToken = null) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${patientId}/conditions/show/${id}${
      informationShareToken
        ? `/?informationShareToken=${informationShareToken}`
        : ''
    }`,
    requestOptions
  )
    .then(condition => {
      return { error: false, condition };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addCondition = condition => {
  const raw = JSON.stringify(condition);
  const requestOptions = {
    method: 'POST',

    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/conditions`, requestOptions)
    .then(condition => {
      return { error: false, condition };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateConditions = (id, condition) => {
  const raw = JSON.stringify({
    value: condition.title,
    description: condition.note,
  });

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: raw,
  };

  return fetch(`/patient/conditions/${id}`, requestOptions)
    .then(conditions => {
      return { error: false, conditions };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getConditions,
  getCondition,
  getPatientCondition,
  getSharedConditions,
  getSharedCondition,
  addCondition,
  updateConditions,
};
