import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { patientConsultationsActions } from '@actions';
import { Container } from '@components';
import {
  H1,
  ProfilePic,
  convertDateToFullStringDate,
  CapitalizeFirstChart,
  H2,
  WaitingToBeAssigned,
  PrimaryButton,
} from '@utils';
import { QuestionIcon } from '@images';
import { Link } from 'react-router-dom';
const ConsultationHeader = styled.div`
  background: #fff;
  padding: 30px 0 20px;
  margin-top: -3em;
  margin-bottom: 75px;
`;
const TitleWrapper = styled.div`
  h1 {
    font-size: 30px;
    margin-bottom: 6px;
    line-height: 1;
    & + p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      color: #9298ac;
      margin-top: 0;
    }
  }
  p {
    color: #060c2b;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
  }
`;
const ConsultantWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    p {
      margin-left: 20px;
      font-family: AvenirNext;
      font-size: 18px;
      font-weight: 500;
      color: #060c2b;
      margin-bottom: 0;
    }
  }
  p {
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: 500;
    color: #5cb3db;
    margin-bottom: 0;
  }
  .waiting {
    p {
      color: #5cb3db;
      margin-left: 0;
    }
  }
`;
const DoctorRequestWrapper = styled.div`
  margin-bottom: 60px;
  h2 {
    font-family: AvenirNext;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 1;
  }
  .request + .request {
    margin-top: 20px;
  }
`;
const DoctorRequest = styled.div`
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background-color: #fff;
  padding: 20px;
  h3 {
    font-family: AvenirNext;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    & + p {
      font-family: AvenirNext;
      font-size: 14.4px;
      font-weight: normal;
      color: #9298ac;
    }
  }
  p {
    font-family: AvenirNext;
    font-size: 16px;
    color: #060c2b;
    line-height: 2;
  }
`;
const MedicalReportWrapper = styled.div`
  margin-bottom: 60px;
  h2 {
    font-family: AvenirNext;
    font-size: 30px;
    margin-bottom: 20px;
  }
`;

const NeedMore = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    align-items: center;
    line-height: 1;
    div {
      margin-left: 20px;
    }

    h3 {
      font-family: AvenirNext;
      font-size: 19.2px;
      font-weight: 600;
      color: #5cb3db;
      line-height: 1;
      margin: 0;
      margin-bottom: 5px;
    }
    p {
      font-family: AvenirNext;
      font-size: 16.8px;
      color: #060c2b;
      line-height: 1;
      margin: 0;
    }
  }
`;

class Consultation extends Component {
  componentDidMount() {
    const {
      getSingleConsultation,
      match: {
        params: { id },
      },
    } = this.props;
    getSingleConsultation(id);
  }

  render() {
    const { consultation } = this.props;

    return (
      <>
        {consultation && (
          <>
            <ConsultationHeader>
              <Container>
                <>
                  <TitleWrapper>
                    <H1>{consultation.symptoms}</H1>
                    <p>
                      Starting on:{' '}
                      {convertDateToFullStringDate(consultation.createdAt)}
                    </p>

                    <p>{consultation.symptomsExplained}</p>
                  </TitleWrapper>
                  <ConsultantWrapper>
                    {consultation.consultant ? (
                      <div>
                        <ProfilePic user={consultation.consultant} />
                        <p>DR.{consultation.consultant.name}</p>
                      </div>
                    ) : (
                      <div className="waiting">
                        <WaitingToBeAssigned />
                      </div>
                    )}

                    <p>{CapitalizeFirstChart(consultation.status)}</p>
                  </ConsultantWrapper>
                </>
              </Container>
            </ConsultationHeader>
            <Container>
              <>
                {consultation.scanRequests.length > 0 && (
                  <DoctorRequestWrapper>
                    <H2>Doctor Requests</H2>
                    {consultation.scanRequests.map(request => (
                      <DoctorRequest
                        key={JSON.stringify(request)}
                        className="request"
                      >
                        <h3>{request.title}</h3>
                        <p>{convertDateToFullStringDate(request.created_at)}</p>
                        <div>
                          <p>{request.body}</p>
                        </div>
                      </DoctorRequest>
                    ))}
                  </DoctorRequestWrapper>
                )}

                {consultation.medicalReport && (
                  <MedicalReportWrapper>
                    <H2>Medical Report</H2>
                    <DoctorRequest>
                      <h3>{consultation.medicalReport.title}</h3>
                      <p>
                        {convertDateToFullStringDate(
                          consultation.medicalReport.created_at
                        )}
                      </p>

                      <div>
                        <p>{consultation.medicalReport.note}</p>
                      </div>
                    </DoctorRequest>
                  </MedicalReportWrapper>
                )}
                <NeedMore>
                  <div>
                    <QuestionIcon />
                    <div>
                      <h3>Need More help?</h3>
                      <p>You can book appointment with this doctor</p>
                    </div>
                  </div>
                  <div>
                    <PrimaryButton minWidth="412">
                      <Link to="#!">Book Appointment</Link>
                    </PrimaryButton>
                  </div>
                </NeedMore>
              </>
            </Container>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { consultation } = state.consultations;

  return {
    consultation,
  };
};

const mapDispatchToProps = dispatch => ({
  getSingleConsultation: id =>
    dispatch(patientConsultationsActions.getSingleConsultation(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Consultation);

Consultation.propTypes = {
  getSingleConsultation: PropTypes.func.isRequired,
  consultation: PropTypes.object,
  match: PropTypes.object.isRequired,
};
