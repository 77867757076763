const getLabResults = page => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/lab-results${page ? `/?page=${page}` : ''}`,
    requestOptions
  )
    .then(labResults => {
      return { error: false, labResults };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getLabResult = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultant/labResult/show/${id}`, requestOptions)
    .then(labResult => {
      return { error: false, labResult };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getPatientLabResult = id => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/patient/lab-results/show/${id}`, requestOptions)
    .then(labResult => {
      return { error: false, labResult };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedLabResults = (id, informationShareToken = null, page) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let prams = null;
  if (informationShareToken && !page) {
    prams = `/?informationShareToken=${informationShareToken}`;
  } else if (informationShareToken && page) {
    prams = `/?informationShareToken=${informationShareToken}&page=${page}`;
  } else if (!informationShareToken && page) {
    prams = `/?page=${page}`;
  }

  return fetch(
    `/patient/${id}/lab-results${prams ? prams : ''}`,
    requestOptions
  )
    .then(labResults => {
      return { error: false, labResults };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const getSharedLabResult = (patientId, id, informationShareToken = null) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/patient/${patientId}/lab-results/show/${id}${
      informationShareToken
        ? `?informationShareToken=${informationShareToken}`
        : ''
    }`,
    requestOptions
  )
    .then(labResult => {
      return { error: false, labResult };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const addLabResults = LabResults => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: LabResults,
  };

  return fetch(`/patient/lab-results`, requestOptions)
    .then(labResults => {
      return { error: false, labResults };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

const updateLabResults = (id, LabResults) => {
  const formdata = new FormData();
  formdata.append('note', LabResults.note);
  formdata.append('file', LabResults.fileInput.files[0], 'file');

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };

  return fetch(`/patient/lab-results/${id}`, requestOptions)
    .then(labResults => {
      return { error: false, labResults };
    })
    .catch(error => {
      return { error: true, errors: error };
    });
};

export default {
  getLabResults,
  getLabResult,
  getPatientLabResult,
  getSharedLabResults,
  getSharedLabResult,
  addLabResults,
  updateLabResults,
};
