import React from 'react';
import { MainLayout } from '@components';
import MedicalReportComponents from './MedicalReport';

export default function MedicalReportPage(props) {
  return (
    <MainLayout
      footer="main"
      children={<MedicalReportComponents {...props} />}
    />
  );
}
