import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  // BaseLayout,
  VerifyMobile,
  FullPage,
  Container,
  MainLayout,
} from '@components';
import { patientActions, consultantActions } from '@actions';

class VerifyMobilePage extends Component {
  render() {
    const {
      match: {
        params: { mobileNum },
      },
    } = this.props;
    return (
      <MainLayout
        children={
          <FullPage>
            <Container>
              <VerifyMobile phone={mobileNum} {...this.props} />
            </Container>
          </FullPage>
        }
      />
    );
  }
}

const userType = localStorage.getItem('userType');
const consultant = userType === 'consultant';
const mapStateToProps = state => {
  const { errors, user } = state.authentication;
  return {
    errors,
    user,
  };
};

const mapDispatchToProps = dispatch => ({
  addMobile: mobUser => {
    consultant
      ? dispatch(consultantActions.addMobile(mobUser))
      : dispatch(patientActions.addMobile(mobUser));
  },
  resendCode: token => {
    consultant
      ? dispatch(consultantActions.resendCode(token))
      : dispatch(patientActions.resendCode(token));
  },
  verify: ({ code, token }) => {
    consultant
      ? dispatch(consultantActions.verify({ code, token }))
      : dispatch(patientActions.verify({ code, token }));
  },
});

VerifyMobilePage.propTypes = {
  match: PropTypes.object.isRequired,
};

const connectedVerifyMobilePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyMobilePage);

export default connectedVerifyMobilePage;
