import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { consultationActions } from '@actions';
import { Modal } from '@utils';
import {
  MainLayout,
  Container,
  PatientInfo,
  Readings,
  RequestMedicalRecordForm,
  AcceptConsultants,
  CancelConsultants,
} from '@components';
import {
  // Activity,
  MedicalRecord,
  PatientActivity,
  PatientConcern,
} from './components';

const ViewConsultationWrapper = styled.div`
  margin-top: 60px;

  .consultation-content,
  .consultation-sidebar {
    padding: 0 15px;
  }
`;

class ViewConsultation extends Component {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getConsultation,
    } = this.props;
    getConsultation(id);
  }
  state = {
    showModal: false,
    modalTitle: '',
    modalImage: '',
    scanType: '',
    showActionModal: false,
    whichModalShow: '',
    acceptedConsultantsid: null,
    cancelConsultantsid: null,
  };
  handelShowModal = (title, image, scanType) => {
    this.setState({
      showModal: true,
      modalTitle: title,
      modalImage: image,
      scanType,
    });
  };
  handelCloseModal = () => {
    this.setState({ showModal: false, showActionModal: false });
  };
  handelShowActionModal = whichItem => {
    this.setState({ showActionModal: true, whichModalShow: whichItem });
  };
  setAcceptedConsultantsid = id => {
    this.setState({ acceptedConsultantsid: id });
  };
  setCancelConsultantsid = id => {
    this.setState({ cancelConsultantsid: id });
  };

  render() {
    const {
      showActionModal,
      whichModalShow,
      showModal,
      modalTitle,
      modalImage,
      scanType,
      acceptedConsultantsid,
      cancelConsultantsid,
    } = this.state;
    const {
      consultation,
      requestScan,
      sendReport,
      acceptConsultation,
      rejectConsultation,
      getConsultation,
    } = this.props;

    return (
      <div>
        <MainLayout footer="main">
          <Container>
            <ViewConsultationWrapper>
              {consultation && (
                <Row>
                  <Col span={16} className="consultation-content">
                    <PatientConcern
                      consultation={consultation}
                      sendReport={sendReport}
                      setAcceptedConsultantsid={this.setAcceptedConsultantsid}
                      setCancelConsultantsid={this.setCancelConsultantsid}
                      handelShowModal={this.handelShowActionModal}
                      acceptConsultation={acceptConsultation}
                      rejectConsultation={rejectConsultation}
                      getConsultation={getConsultation}
                    />
                    <MedicalRecord
                      medicalReports={consultation.medicalReports}
                      allergy={consultation && consultation.allergy}
                      condition={consultation && consultation.condition}
                      ecg={consultation && consultation.ecg}
                      labResult={consultation && consultation.labResult}
                      radiology={consultation && consultation.radiology}
                      handelShowModal={this.handelShowModal}
                      patientId={consultation && consultation.patientId}
                      medicalReport={consultation && consultation.medicalReport}
                      consultationId={consultation && consultation.id}
                    />
                    {/* <Activity /> */}
                    <PatientActivity
                      patientActivity={consultation.patientActivity}
                    />
                  </Col>
                  <Col span={8} className="consultation-sidebar">
                    <PatientInfo patient={consultation.patient} />
                    <Readings
                      readings={consultation.readings}
                      requestScan={requestScan}
                      consultationId={consultation.id}
                      handelShowModal={this.handelShowModal}
                    />
                  </Col>
                </Row>
              )}
            </ViewConsultationWrapper>
          </Container>
        </MainLayout>
        <Modal
          title={modalTitle}
          image={modalImage}
          showModal={showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={requestScan}
              scanType={scanType}
              consultationId={consultation && consultation.id}
              handelCloseModal={this.handelCloseModal}
            />
          </>
        </Modal>
        {whichModalShow && (
          <Modal
            title={modalTitle}
            image={modalImage}
            showModal={showActionModal}
            handelCloseModal={this.handelCloseModal}
          >
            <>
              {whichModalShow === 'Accept' && (
                <AcceptConsultants consultantsId={acceptedConsultantsid} />
              )}
              {whichModalShow === 'Cancel' && (
                <CancelConsultants consultantsId={cancelConsultantsid} />
              )}
            </>
          </Modal>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { consultation } = state.consultation;

  return {
    consultation,
  };
};

const mapDispatchToProps = dispatch => ({
  getConsultation: id => dispatch(consultationActions.getConsultation(id)),
  requestScan: ({ scanType, id, title, body }) =>
    dispatch(consultationActions.requestScan({ scanType, id, title, body })),
  sendReport: ({ title, note, consultationId }) =>
    dispatch(consultationActions.sendReport({ title, note, consultationId })),
  acceptConsultation: id =>
    dispatch(consultationActions.acceptConsultation(id)),
  rejectConsultation: id =>
    dispatch(consultationActions.rejectConsultation(id)),
});

const WrappedViewConsultation = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewConsultation);

export default WrappedViewConsultation;

ViewConsultation.propTypes = {
  match: PropTypes.object.isRequired,
  consultation: PropTypes.object,
  getConsultation: PropTypes.func.isRequired,
  requestScan: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  rejectConsultation: PropTypes.func.isRequired,
  acceptConsultation: PropTypes.func.isRequired,
};
