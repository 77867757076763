import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { BaseLayout } from '@components';
import { Dropdown, Icon, Menu } from 'antd';
import { patientActions, consultantActions } from '@actions';
import { ProfilePic } from '@utils';

import 'antd/lib/dropdown/style/css';
import 'antd/lib/icon/style/css';
import 'antd/lib/menu/style/css';

const LayoutWrapper = styled.div`
  background-color: #f5f5f5;
  min-height: 100vh;
  .layout-navigation {
    line-height: 64px;
    list-style: none;
    padding: 0;

    li:first-of-type {
      display: flex;
    }
    img,
    .navigation .ant-dropdown-link {
      width: 48px;
      height: 48px;
      display: inline-block;
      vertical-align: middle;
      position: relative;

      .anticon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #5cb3db;
      }
    }
    .navigation .ant-dropdown-link {
      margin-right: 20px;
    }
    .ant-dropdown-link {
      border-radius: 50%;
      background-color: rgb(247, 251, 253);
      padding: 10px;
      &:hover {
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.1);
      }

      .anticon {
        color: rgb(99, 99, 99);
      }
      &.profile-dropdown {
        position: relative;
        border-radius: 50%;
        background-color: rgb(238, 238, 238);
        margin-left: -5px;
        border: 2px solid rgb(255, 255, 255);
        padding: 0;
        .anticon {
          color: rgb(99, 99, 99);
          margin: 2px;
        }
      }
    }
  }
`;

class MainLayout extends PureComponent {
  render() {
    const {
      patientLogout,
      consultantLogout,
      children,
      footer,
      ReportPage,
    } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));
    const userType = localStorage.getItem('userType');

    // const menu = (
    //   <Menu>
    //     <Menu.Item>
    //       <Link
    //         target="_blank"
    //         rel="noopener noreferrer"
    //         to="http://www.alipay.com/"
    //       >
    //         1st menu item
    //       </Link>
    //     </Menu.Item>
    //   </Menu>
    // );
    const accountMenu = (
      <Menu>
        <Menu.Item>
          <Link to="/profile">
            <span className="link-text">Profile</span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            onClick={
              userType === 'consultant' ? consultantLogout : patientLogout
            }
            to={userType === 'consultant' ? '/consultant/login' : '/login'}
          >
            <span className="link-text">Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <LayoutWrapper>
        <BaseLayout
          className="layout"
          footer={footer ? footer : null}
          ReportPage={ReportPage}
          navigation={
            <ul className="layout-navigation" mode="horizontal">
              {user && (
                <li key="2">
                  {/* <Dropdown overlay={menu}>
                    <div className="navigation">
                      <Link className="ant-dropdown-link" to="#!">
                        <Icon type="bell" theme="filled" />
                      </Link>
                    </div>
                  </Dropdown> */}

                  <Dropdown overlay={accountMenu}>
                    <div>
                      <ProfilePic
                        user={user}
                        width="36"
                        height="36"
                        style={{ borderRadius: '50%' }}
                      />
                      <Link
                        className="ant-dropdown-link profile-dropdown"
                        to="#!"
                      >
                        <Icon type="down" />
                      </Link>
                    </div>
                  </Dropdown>
                </li>
              )}
            </ul>
          }
          children={children}
        />
      </LayoutWrapper>
    );
  }
}

MainLayout.propTypes = {
  patientLogout: PropTypes.func.isRequired,
  consultantLogout: PropTypes.func.isRequired,
  children: PropTypes.object,
  footer: PropTypes.string,
  ReportPage: PropTypes.bool,
};

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({
  patientLogout: () => dispatch(patientActions.logout()),
  consultantLogout: () => dispatch(consultantActions.logout()),
});

const MainLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout);

export default MainLayoutContainer;
