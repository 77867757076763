import { alertActions } from '@actions';
import { patientReadingsServices } from '@services';
import patientReadingsConstants from './types';

const getReadings = () => {
  return dispatch => {
    dispatch(getReadingsRequest());
    patientReadingsServices.getReadings().then(res => {
      if (res.error) {
        dispatch(getReadingsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getReadingsSuccess(res));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedReadings = (id, informationShareToken) => {
  return dispatch => {
    dispatch(getSharedReadingsRequest());
    patientReadingsServices
      .getSharedReadings(id, informationShareToken)
      .then(res => {
        if (res.error) {
          dispatch(getSharedReadingsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedReadingsSuccess(res));
          dispatch(alertActions.clear());
        }
      });
  };
};

const addReading = readings => {
  return dispatch => {
    dispatch(addReadingRequest(readings));

    patientReadingsServices.addReading(readings).then(res => {
      if (res.error) {
        dispatch(addReadingFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(addReadingSuccess(res));
        dispatch(alertActions.success('Your Conditions has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const addMultiReadings = readings => {
  return dispatch => {
    dispatch(addMultiReadingsRequest(readings));
    patientReadingsServices.addMultiReadings(readings).then(res => {
      if (res.error) {
        dispatch(addMultiReadingsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(addMultiReadingsSuccess(res));
        dispatch(alertActions.success('Your Readings has Added'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const updateReading = (id, reading) => {
  return dispatch => {
    dispatch(updateReadingRequest(reading));

    patientReadingsServices.updateReading(id, reading).then(res => {
      if (res.error) {
        dispatch(updateReadingFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateReadingSuccess(res));
        dispatch(alertActions.success('Your Conditions has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getReadingsRequest = () => ({
  type: patientReadingsConstants.GET_READINGS_REQUEST,
});
const getReadingsSuccess = readings => ({
  type: patientReadingsConstants.GET_READINGS_SUCCESS,
  readings: readings.readings,
});
const getReadingsFailure = error => ({
  type: patientReadingsConstants.GET_READINGS_FAILURE,
  error,
});

const getSharedReadingsRequest = () => ({
  type: patientReadingsConstants.GET_SHARED_READINGS_REQUEST,
});
const getSharedReadingsSuccess = readings => ({
  type: patientReadingsConstants.GET_SHARED_READINGS_SUCCESS,
  readings: readings.readings,
});
const getSharedReadingsFailure = error => ({
  type: patientReadingsConstants.GET_SHARED_READINGS_FAILURE,
  error,
});

const addReadingRequest = reading => ({
  type: patientReadingsConstants.ADD_READINGS_REQUEST,
  reading,
});
const addReadingSuccess = reading => ({
  type: patientReadingsConstants.ADD_READINGS_SUCCESS,
  reading,
});
const addReadingFailure = error => ({
  type: patientReadingsConstants.ADD_READINGS_FAILURE,
  error,
});

const addMultiReadingsRequest = readings => ({
  type: patientReadingsConstants.ADD_MULTI_READINGS_REQUEST,
  readings,
});
const addMultiReadingsSuccess = readings => ({
  type: patientReadingsConstants.ADD_MULTI_READINGS_SUCCESS,
  readings,
});
const addMultiReadingsFailure = error => ({
  type: patientReadingsConstants.ADD_MULTI_READINGS_FAILURE,
  error,
});

const updateReadingRequest = reading => ({
  type: patientReadingsConstants.UPDATE_READINGS_REQUEST,
  reading,
});
const updateReadingSuccess = reading => ({
  type: patientReadingsConstants.UPDATE_READINGS_SUCCESS,
  reading,
});
const updateReadingFailure = error => ({
  type: patientReadingsConstants.UPDATE_READINGS_FAILURE,
  error,
});

export const patientReadingActions = {
  getReadings,
  getSharedReadings,
  addReading,
  addMultiReadings,
  updateReading,
};
