import { alertActions } from '@actions';
import { paymentServices } from '@services';
import paymentConstants from './types';

const makePayment = payConsultant => {
  return dispatch => {
    dispatch(makePaymentRequest());

    paymentServices.makePayment(payConsultant).then(res => {
      if (res.error) {
        dispatch(makePaymentFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(makePaymentSuccess(res.payDetails));
        dispatch(alertActions.success('Your Payment has Success'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getClientSecret = () => {
  return dispatch => {
    dispatch(getClientSecretRequest());

    paymentServices.getClientSecret().then(res => {
      if (res.error) {
        dispatch(getClientSecretFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getClientSecretSuccess(res.result));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getCardsList = () => {
  return dispatch => {
    dispatch(getCardsListRequest());

    paymentServices.getCardsList().then(res => {
      if (res.error) {
        dispatch(getCardsListFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getCardsListSuccess(res.cards));
        dispatch(alertActions.clear());
      }
    });
  };
};

const deleteCard = card => {
  return dispatch => {
    dispatch(deleteCardRequest());

    paymentServices.deleteCard(card).then(res => {
      if (res.error) {
        dispatch(deleteCardFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(deleteCardSuccess(res.card));
        dispatch(alertActions.success('Your Card has DELETED'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getAuthRequiredFaildPayment = () => {
  return dispatch => {
    dispatch(getAuthRequiredFaildPaymentRequest());

    paymentServices.getAuthRequiredFaildPayment().then(res => {
      if (res.error) {
        dispatch(getAuthRequiredFaildPaymentFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAuthRequiredFaildPaymentSuccess(res.failedPayment));
        dispatch(alertActions.clear());
      }
    });
  };
};

const makePaymentRequest = () => ({
  type: paymentConstants.MAK_PAYMENT_REQUEST,
});
const makePaymentSuccess = payDetails => ({
  type: paymentConstants.MAK_PAYMENT_SUCCESS,
  payDetails,
});
const makePaymentFailure = error => ({
  type: paymentConstants.MAK_PAYMENT_FAILURE,
  error,
});

const getClientSecretRequest = () => ({
  type: paymentConstants.GET_CLIENT_SECRET_REQUEST,
});
const getClientSecretSuccess = clientSecret => ({
  type: paymentConstants.GET_CLIENT_SECRET_SUCCESS,
  clientSecret,
});
const getClientSecretFailure = error => ({
  type: paymentConstants.GET_CLIENT_SECRET_FAILURE,
  error,
});

const getCardsListRequest = () => ({
  type: paymentConstants.GET_CARDS_LIST_REQUEST,
});
const getCardsListSuccess = cards => ({
  type: paymentConstants.GET_CARDS_LIST_SUCCESS,
  cards,
});
const getCardsListFailure = error => ({
  type: paymentConstants.GET_CARDS_LIST_FAILURE,
  error,
});

const deleteCardRequest = () => ({
  type: paymentConstants.DELETE_CARD_REQUEST,
});
const deleteCardSuccess = card => ({
  type: paymentConstants.DELETE_CARD_SUCCESS,
  card,
});
const deleteCardFailure = error => ({
  type: paymentConstants.DELETE_CARD_FAILURE,
  error,
});

const getAuthRequiredFaildPaymentRequest = () => ({
  type: paymentConstants.GET_FAILED_PAYMENT_REQUEST,
});
const getAuthRequiredFaildPaymentSuccess = auth => ({
  type: paymentConstants.GET_FAILED_PAYMENT_SUCCESS,
  auth,
});
const getAuthRequiredFaildPaymentFailure = error => ({
  type: paymentConstants.GET_FAILED_PAYMENT_FAILURE,
  error,
});

export const paymentActions = {
  makePayment,
  getClientSecret,
  getCardsList,
  deleteCard,
  getAuthRequiredFaildPayment,
};
