import { alertActions } from '@actions';
import { patientLabResultsServices } from '@services';
import patientLabResultsConstants from './types';
import { history } from '@utils';

const getLabResults = page => {
  return dispatch => {
    dispatch(getLabResultsRequest());
    patientLabResultsServices.getLabResults(page).then(res => {
      if (res.error) {
        dispatch(getLabResultsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getLabResultsSuccess(res.labResults));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getLabResult = id => {
  return dispatch => {
    dispatch(getLabResultRequest());
    patientLabResultsServices.getLabResult(id).then(res => {
      if (res.error) {
        dispatch(getLabResultFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getLabResultSuccess(res.labResult));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getPatientLabResult = id => {
  return dispatch => {
    dispatch(getPatientLabResultRequest());
    patientLabResultsServices.getPatientLabResult(id).then(res => {
      if (res.error) {
        dispatch(getPatientLabResultFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getPatientLabResultSuccess(res.labResult));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedLabResults = (id, informationShareToken = null, page) => {
  return dispatch => {
    dispatch(getSharedLabResultsRequest());
    patientLabResultsServices
      .getSharedLabResults(id, informationShareToken, page)
      .then(res => {
        if (res.error) {
          dispatch(getSharedLabResultsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedLabResultsSuccess(res.labResults));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getSharedLabResult = (patientId, id, informationShareToken) => {
  return dispatch => {
    dispatch(getSharedLabResultRequest());
    patientLabResultsServices
      .getSharedLabResult(patientId, id, informationShareToken)
      .then(res => {
        if (res.error) {
          dispatch(getSharedLabResultFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedLabResultSuccess(res.labResult));
          dispatch(alertActions.clear());
        }
      });
  };
};
const addLabResults = LabResults => {
  return dispatch => {
    dispatch(addLabResultsRequest(LabResults));

    patientLabResultsServices.addLabResults(LabResults).then(res => {
      if (res.error) {
        dispatch(addLabResultsFailure(res.errors));
        if (typeof res.errors === 'object') {
          Object.keys(res.errors).map(key =>
            dispatch(
              alertActions.error(
                res.errors[key] || 'An Error occured, please try again!'
              )
            )
          );
        }
        dispatch(alertActions.clear());
      } else {
        dispatch(addLabResultsSuccess(res.labResults));
        dispatch(alertActions.success('Your Lab Results has Added'));
        dispatch(alertActions.clear());
        const RequestConsultation = new URLSearchParams(
          history.location.search
        ).get('RequestConsultation');
        if (RequestConsultation) {
          history.push('/lab-results?RequestConsultation=1');
        } else {
          history.push('/lab-results');
        }
      }
    });
  };
};

const updateLabResults = (id, LabResults) => {
  return dispatch => {
    dispatch(updateLabResultsRequest(LabResults));

    patientLabResultsServices.updateLabResults(id, LabResults).then(res => {
      if (res.error) {
        dispatch(updateLabResultsFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(updateLabResultsSuccess(res));
        dispatch(alertActions.success('Your Conditions has Updateed'));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getLabResultsRequest = () => ({
  type: patientLabResultsConstants.GET_LAB_RESULTS_REQUEST,
});

const getLabResultsSuccess = labResults => ({
  type: patientLabResultsConstants.GET_LAB_RESULTS_SUCCESS,
  labResults,
});

const getLabResultsFailure = error => ({
  type: patientLabResultsConstants.GET_LAB_RESULTS_FAILURE,
  error,
});

const getLabResultRequest = () => ({
  type: patientLabResultsConstants.GET_LAB_RESULT_REQUEST,
});

const getLabResultSuccess = labResult => ({
  type: patientLabResultsConstants.GET_LAB_RESULT_SUCCESS,
  labResult,
});

const getLabResultFailure = error => ({
  type: patientLabResultsConstants.GET_LAB_RESULT_FAILURE,
  error,
});

const getPatientLabResultRequest = () => ({
  type: patientLabResultsConstants.GET_PATIENT_LAB_RESULT_REQUEST,
});

const getPatientLabResultSuccess = labResult => ({
  type: patientLabResultsConstants.GET_PATIENT_LAB_RESULT_SUCCESS,
  labResult,
});

const getPatientLabResultFailure = error => ({
  type: patientLabResultsConstants.GET_PATIENT_LAB_RESULT_FAILURE,
  error,
});

const getSharedLabResultsRequest = () => ({
  type: patientLabResultsConstants.GET_SHARED_LAB_RESULTS_REQUEST,
});

const getSharedLabResultsSuccess = labResults => ({
  type: patientLabResultsConstants.GET_SHARED_LAB_RESULTS_SUCCESS,
  labResults,
});

const getSharedLabResultsFailure = error => ({
  type: patientLabResultsConstants.GET_SHARED_LAB_RESULTS_FAILURE,
  error,
});

const getSharedLabResultRequest = () => ({
  type: patientLabResultsConstants.GET_SHARED_LAB_RESULT_REQUEST,
});

const getSharedLabResultSuccess = labResult => ({
  type: patientLabResultsConstants.GET_SHARED_LAB_RESULT_SUCCESS,
  labResult,
});

const getSharedLabResultFailure = error => ({
  type: patientLabResultsConstants.GET_SHARED_LAB_RESULT_FAILURE,
  error,
});

const addLabResultsRequest = labResult => ({
  type: patientLabResultsConstants.ADD_LAB_RESULTS_REQUEST,
  labResult,
});

const addLabResultsSuccess = labResult => ({
  type: patientLabResultsConstants.ADD_LAB_RESULTS_SUCCESS,
  labResult,
});

const addLabResultsFailure = error => ({
  type: patientLabResultsConstants.ADD_LAB_RESULTS_FAILURE,
  error,
});

const updateLabResultsRequest = labResult => ({
  type: patientLabResultsConstants.UPDATE_LAB_RESULTS_REQUEST,
  labResult,
});

const updateLabResultsSuccess = labResult => ({
  type: patientLabResultsConstants.UPDATE_LAB_RESULTS_SUCCESS,
  labResult,
});

const updateLabResultsFailure = error => ({
  type: patientLabResultsConstants.UPDATE_LAB_RESULTS_FAILURE,
  error,
});

export const patientLabResultsActions = {
  getLabResults,
  getLabResult,
  getPatientLabResult,
  getSharedLabResults,
  getSharedLabResult,
  addLabResults,
  updateLabResults,
};
