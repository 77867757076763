import { combineReducers } from 'redux';
import { alert } from './alerts';
import { authentication } from './patient/user';
import { profile } from './patient/profile';
import { condition } from './patient/condition';
import { allergies } from './patient/allergies';
import { medicalReports } from './patient/medicalReports';
import { labResults } from './patient/labResults';
import { ecgs } from './patient/ecgs';
import { readings } from './patient/readings';
import { consultantAuth } from './consultant/auth';
import { consultation } from './consultant/consultation';
import { radiologist } from './patient/Radiologist';
import { consultations } from './patient/consultations';
import { schedules } from './consultant/schedules';
import { payment } from './patient/payment';

const appReducer = combineReducers({
  authentication,
  alert,
  profile,
  condition,
  allergies,
  medicalReports,
  labResults,
  ecgs,
  readings,
  consultantAuth,
  consultation,
  radiologist,
  consultations,
  schedules,
  payment,
});

export default appReducer;
