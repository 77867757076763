/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const QuestionIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '50'}px`}
      width={`${width || '50'}px`}
      viewBox="0 0 50 50"
      css={css`
        fill: ${color || '#5cb3db'};
        max-width: ${width || '50'}px;
        max-height: ${height || '50'}px;
        height: ${height || '50'}px;
        width: ${width || '50'}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
            fill: 'rgb(36.078431%,70.196078%,85.882353%)',
          }}
          d="M 25 0 C 11.214844 0 0 11.214844 0 25 C 0 38.785156 11.214844 50 25 50 C 38.785156 50 50 38.785156 50 25 C 50 11.214844 38.785156 0 25 0 Z M 25 39.582031 C 23.851562 39.582031 22.917969 38.648438 22.917969 37.5 C 22.917969 36.351562 23.851562 35.417969 25 35.417969 C 26.148438 35.417969 27.082031 36.351562 27.082031 37.5 C 27.082031 38.648438 26.148438 39.582031 25 39.582031 Z M 28.296875 26.335938 C 27.558594 26.675781 27.082031 27.421875 27.082031 28.230469 L 27.082031 29.167969 C 27.082031 30.316406 26.152344 31.25 25 31.25 C 23.847656 31.25 22.917969 30.316406 22.917969 29.167969 L 22.917969 28.230469 C 22.917969 25.800781 24.347656 23.570312 26.550781 22.550781 C 28.675781 21.574219 30.207031 18.980469 30.207031 17.707031 C 30.207031 14.835938 27.871094 12.5 25 12.5 C 22.128906 12.5 19.792969 14.835938 19.792969 17.707031 C 19.792969 18.859375 18.859375 19.792969 17.707031 19.792969 C 16.554688 19.792969 15.625 18.859375 15.625 17.707031 C 15.625 12.539062 19.832031 8.332031 25 8.332031 C 30.167969 8.332031 34.375 12.539062 34.375 17.707031 C 34.375 20.523438 31.933594 24.660156 28.296875 26.335938 Z M 28.296875 26.335938 "
        />
      </g>
    </svg>
  );
};

export default QuestionIcon;

QuestionIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
