import React, { Component } from 'react';
import styled from '@emotion/styled/macro';

import Action from '../Action';
import { More } from '@utils';

const PatientActivityHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 35px 0 5px;
  .patient-activity__title {
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
`;
export default class PatientActivity extends Component {
  render() {
    const { patientActivity } = this.props;

    const actions = patientActivity.slice(-3).map(action => {
      return (
        <Action
          text={action.activity_text}
          key={action.created_at}
          time={action.created_at}
        />
      );
    });
    return (
      <div>
        <PatientActivityHeader>
          <h1 className="patient-activity__title">Patient Activity</h1>
          <div>
            <More to="#!" />
          </div>
        </PatientActivityHeader>
        {actions}
      </div>
    );
  }
}
