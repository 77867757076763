import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import HomeNavigation from './HomeNavigation.js';
import { Container, MedicalRecordComponent } from '@components';
import Consultants from './Consultants.js';
import Activity from './Activity.js';
import { ReadingsComponent } from './Readings/index';

const HomePageWrapper = styled.div`
  .profile-border {
    box-shadow: 2px 3px 4px 2px rgba(1, 2, 2, 0.02);
    border-radius: 18px;
    border: solid 1px #e4e9f2;
    padding: 33px;
    &.ant-col {
      padding: 0;
      border: none;
      border-radius: 6px;
    }
  }
`;

class HomePage extends Component {
  state = {
    currentView: 'Home',
  };
  componentDidMount() {
    const {
      location: { hash },
    } = this.props;
    if (hash) {
      this.setState({ currentView: hash.split('#').join('') });
    }
  }

  setCurrentView = show => {
    this.setState({ currentView: show });
  };
  render() {
    const { currentView } = this.state;

    return (
      <Container>
        <HomePageWrapper>
          <Row>
            <Col className="home-border" span={6}>
              <HomeNavigation
                setCurrentView={this.setCurrentView}
                currentView={currentView}
              />
            </Col>
            {currentView === 'Home' && (
              <Col span={17} offset={1}>
                <>
                  <Consultants />
                  <Activity />
                </>
              </Col>
            )}
            {currentView === 'MedicalRecord' && (
              <Col span={17} offset={1}>
                <MedicalRecordComponent />
              </Col>
            )}
            {currentView === 'Readings' && (
              <Col span={17} offset={1}>
                <ReadingsComponent />
              </Col>
            )}
          </Row>
        </HomePageWrapper>
      </Container>
    );
  }
}

export default HomePage;

HomePage.propTypes = {
  location: PropTypes.object,
};
