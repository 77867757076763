/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core';
import { fontFace } from './FontGenerationHelper';
import { Colors } from './Theme';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        ${fontFace('DINNextLTW23', 'DINNextLTW23-Bold', 700)};
        ${fontFace('Heebo', 'Heebo-Medium', 400)};
        ${fontFace('AvenirNext', 'AvenirNextLTPro-MediumCn', 500)};
        ${fontFace('SFProText', 'SFProText-Regular', 500)};
        html {
          box-sizing: border-box;
          img {
            max-width: 100%;
          }
        }
        *,
        ::before,
        ::after {
          box-sizing: inherit;
        }
        html,
        body {
          scroll-behavior: smooth;
          padding: 0;
          margin: 0;
          .ant-layout-content {
            padding-left: 0 !important;
            padding-right: 0 !important;
            @media (max-width: 767px) {
              padding: 0 !important;
            }
          }
        }
        body {
          background: ${Colors.white};
          font-family: AvenirNext, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.6;
        }
        .ant-form-item-required::before {
          content: none;
        }
        button,
        a {
          cursor: pointer;
          outline: none;
          &:focus {
            outline: none;
          }
          &:visited {
            color: inherit;
          }
        }
        .visually-hidden,
        .ant-modal-footer {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        .ant-calendar-picker-container {
          width: 413px;
          @media (max-width: 767px) {
            width: 100%;
          }
          .ant-calendar,
          .ant-calendar-panel {
            width: 413px;
            @media (max-width: 767px) {
              margin: auto;
              width: 80%;
            }
          }
        }
        .ant-select-selection--single .ant-select-selection__rendered {
          margin-right: 10px;
        }
        .ant-modal-body,
        .ant-modal,
        .ant-modal-content {
          padding: 0;
          width: 460px !important;
          border: none;
          border-radius: 24px !important;
          @media (max-width: 767px) {
            width: 100% !important;
          }
          .Form-wrapper {
            border-radius: 24px !important;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            padding: 30px;
            @media (max-width: 767px) {
              padding: 20px;
            }
            .ant-input-group-addon {
              width: 90px;
            }
            legend {
              font-family: AvenirNext, Arial, sans-serif;
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 0;
              span {
                font-family: AvenirNext, Arial, sans-serif;
                font-size: 14px;
                font-weight: normal;
                color: #9b9b9b;
              }
            }
            .ant-form-item-control {
              margin-bottom: 25px;
              @media (max-width: 767px) {
                margin-bottom: 10px;
              }
            }
            .ant-input-group-addon {
              width: 100px;
            }
            .ant-input-group-addon {
              width: 100px;
            }
          }
          .ant-select-selection__rendered {
            margin: 0;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        svg:not(:root) {
          overflow: initial;
        }
      `}
    />
  );
};

export default GlobalStyles;
