import React from 'react';
import styled from '@emotion/styled/macro';
import { Colors } from '@utils';

const WaitingWrapper = styled.div`
  p {
    font-size: 14px;
    font-weight: 500;
    color: ${Colors.secondaryColor};
    display: inline-block;
    font-family: AvenirNext, Arial, sans-serif;
    margin: 0;
    &:before {
      content: '';
      width: 14px;
      height: 14px;
      background: ${Colors.secondaryColor};
      display: inline-block;
      border-radius: 50%;
      margin-right: 6px;
      position: relative;
      top: 1px;
    }
  }
`;

export default function WaitingToBeAssigned() {
  return (
    <WaitingWrapper>
      <p>Waiting to be assigned</p>
    </WaitingWrapper>
  );
}
